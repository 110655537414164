import * as React from 'react';
import {Dialog, DialogContent} from '@material-ui/core';
import { UsersResponse } from '../../../API/types';
import CreateForm from './CreateForm';
import DialogTitle from "../../components/DialogTitle";

export interface CreateEditUserDialogProps {
  onClose: () => void;
  onUpdate: () => void;
  open: boolean;
  user: UsersResponse | null;
}

export default function CreateEditUserDialog(props: CreateEditUserDialogProps) {
  const { open, onClose, onUpdate, user } = props;

  const handleSubmit = () => {
    onClose();
    onUpdate();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      {user && (
        <React.Fragment>
          <DialogTitle onClose={onClose}>
            {user.id !== undefined && user.id.length > 0 ? 'Edit user' : 'Create user'}
          </DialogTitle>
          <DialogContent>
            <CreateForm
              user={user}
              actionLabel={user.id !== undefined && user.id.length > 0 ? 'Update' : 'Submit'}
              onSubmitClick={() => handleSubmit()}
              onCloseClick={onClose}
            />
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
}

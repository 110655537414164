import {makeStyles} from "@material-ui/core/styles";
import React, {useContext} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ExpandLess, ExpandMore, FormatListBulleted, People, RecentActors, SupervisorAccount} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import {Collapse} from "@material-ui/core";
import List from "@material-ui/core/List";
import {Link, useHistory} from "react-router-dom";
import routes from "../../routes";
import Visible from "../../../scenes/components/Visible";
import {Permission} from "../../../API/types";
import {drawerStyles} from "../../../styles/drawerStyles";
import {DrawerContext} from "../../../contexts/DrawerContext";


const useStyles = makeStyles(() => ({
  adminMenuItemsDrawerOpen: {
    marginLeft: 24
  },
  adminMenuItemsDrawerClosed: {
    backgroundColor: "#e0e0e0"
  },
  adminMenuExpandIcon: {
    color:"#0000008a",
    marginTop: 3
  },
}));

export default function AdminMenuItems() {
  const classes = useStyles();
  const drawerClasses = drawerStyles();
  const { isDrawerOpen, isAdminMenuOpen, handleAdminMenuClick } = useContext(DrawerContext);
  const history = useHistory();

  return (
    <div>
      <ListItem button onClick={handleAdminMenuClick} className={!isAdminMenuOpen &&
      [routes.CONTACTS, routes.USERS, routes.VARIABLES].includes(history.location.pathname) ?
        drawerClasses.listItemActive : drawerClasses.listItemInactive
      }>
        <ListItemIcon className={drawerClasses.listIcon}>
          <SupervisorAccount />
        </ListItemIcon>
        <ListItemText primary="Admin" />
        <div className={classes.adminMenuExpandIcon}>
          {isAdminMenuOpen ? <ExpandLess/> : <ExpandMore/>}
        </div>
      </ListItem>
      <Collapse in={isAdminMenuOpen} timeout="auto" unmountOnExit>
        <List className={isDrawerOpen ? classes.adminMenuItemsDrawerOpen : classes.adminMenuItemsDrawerClosed}
              disablePadding>
          <Visible permission={Permission.ReadContacts}>
            <ListItem button component={Link} to={routes.CONTACTS} className={
              history.location.pathname === routes.CONTACTS ? drawerClasses.listItemActive : drawerClasses.listItemInactive
            }>
              <ListItemIcon className={drawerClasses.listIcon}>
                <RecentActors />
              </ListItemIcon>
              <ListItemText primary="Contacts" />
            </ListItem>
          </Visible>
          <Visible permission={Permission.ReadUsers}>
            <ListItem button component={Link} to={routes.USERS} className={
              history.location.pathname === routes.USERS ? drawerClasses.listItemActive : drawerClasses.listItemInactive
            }>
              <ListItemIcon className={drawerClasses.listIcon}>
                <People />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItem>
          </Visible>
          <Visible permission={Permission.UpdateVariables}>
            <ListItem button component={Link} to={routes.VARIABLES} className={
              history.location.pathname === routes.VARIABLES ? drawerClasses.listItemActive : drawerClasses.listItemInactive
            }>
              <ListItemIcon className={drawerClasses.listIcon}>
                <FormatListBulleted />
              </ListItemIcon>
              <ListItemText primary="Variables" />
            </ListItem>
          </Visible>
        </List>
      </Collapse>
    </div>
  )
}
import * as yup from "yup";
import {DEFAULT_CURRENCY} from "./formUtils";

export const validationSchema = yup.object({
  number: yup.string()
    .max(15, 'Value should not exceed 15 digits')
    .nullable(),
  integer: yup.string()
    .max(10, 'Value should not exceed 10 digits')
    .nullable(),
  shortTextField: yup.string()
    .max(100, 'Text should not exceed 100 characters')
    .nullable()
    .notRequired(),
  mediumTextField: yup.string()
    .max(255, 'Text should not exceed 255 characters')
    .nullable()
    .notRequired(),
  longTextField: yup.string()
    .max(1000, 'Text should not exceed 1000 characters')
    .nullable()
    .notRequired(),
  dateField: yup.date()
    .typeError('Invalid date')
    .nullable(),
});

export const priceValidationSchema = yup.object({
  amount: yup.string().max(14, 'Value should not exceed 14 digits').nullable()
    .when(['currency'], {
      is: (currency: string) => currency !== DEFAULT_CURRENCY,
      then: (schema) => schema.required('Amount is required')
    }),
  exchangeRate: yup.string().nullable()
    .when(['currency'], {
      is: (currency: string) => currency !== DEFAULT_CURRENCY,
      then: (schema) => schema.required('Exchange rate is required')
    })
})

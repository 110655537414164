import dayjs from "dayjs";

export function formatDateTimeStr(dateStr: string): string {
  const date = Date.parse(dateStr);
  return dayjs(date).format('DD.MM.YYYY HH:mm');
}

export function compareDates(a: string, b: string): number {
  const dateA = new Date(a).getTime();
  const dateB = new Date(b).getTime();
  if (dateA > dateB) {
    return 1;
  } else if (dateA < dateB) {
    return -1;
  } else {
    return 0;
  }
}

export function compareDatesDesc(a: string, b: string): number {
  return -compareDates(a, b);
}

export function dateTime2Str(date: Date | null): string {
  return date ? dayjs(date).format('DD.MM.YYYY HH:mm:ss') : '';
}
import React, {useContext, useEffect, useState} from "react";
import LoadingSpinner from "../components/LoadingSpinner";
import API from "../../API";
import {UserContext} from "../../contexts/UserContext";
import UpdateBasicInfoForm from "./components/UpdateBasicInfoForm";
import ChangePasswordForm from "./components/ChangePasswordForm";


export default function Profile() {
  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    reloadUser();
  }, []);

  const reloadUser = () => {
    API.getMyUser().then((response) => {
      if (response.isSuccessful) {
        user.firstName = response.data.firstName;
        user.lastName = response.data.lastName;
      }
    });
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <UpdateBasicInfoForm user={user} onSubmit={reloadUser} />
          <ChangePasswordForm onSubmit={reloadUser} />
        </div>
      )}
    </>
  );
}
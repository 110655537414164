import {default as MuiTextField, TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField/TextField';
import React from 'react';
import {DEFAULT_CURRENCY, dotToComma, getAmountInBaseCurrency, resolvePath, strToNr} from "../../../utils/formUtils";
import {useFormStyles} from "../../../styles/formStyles";
import {FormLoading} from "../../ProjectDetails/types";


export type NumberTextFieldProps = MuiTextFieldProps & {
  formik: any;
  name: string;
  type: 'integer' | 'size' | 'priceAmount' | 'priceExchangeRate';
  extras?: 'constantShrink';
};

export default function NumberTextField(props: NumberTextFieldProps) {
  const formClasses = useFormStyles();
  const { formik, name, type, extras } = props;

  const integerRegex = /^\d+$/g;
  const twoDecimalRegex = /^\d+(?:[,.]\d{0,2})?$/g;
  const exchangeRateRegex = /^[0-9]?(?:[,.](\d{0,4}))?$/g;

  const getNumberFieldName = () => {
    switch (type) {
      case 'priceAmount':
        return `${name}.amount`
      case 'priceExchangeRate':
        return `${name}.exchangeRate`
      default:
        return name
    }
  };

  const numberFieldName = getNumberFieldName();

  const setPriceAmountInBaseCurrency = (amount: string | null, exchangeRate: string | null) => {
    if (amount) {
      formik.setFieldValue(`${name}.amountInBaseCurrency`, getAmountInBaseCurrency(amount, exchangeRate));
    } else {
      formik.setFieldValue(`${name}.amountInBaseCurrency`, null);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value === '') {
      if (type === 'priceAmount' || type === 'priceExchangeRate') formik.setFieldValue(`${name}.amountInBaseCurrency`, null);
      formik.setFieldValue(numberFieldName, null);
    } else {
      switch (type) {
        case 'integer':
          if (integerRegex.test(event.target.value)) formik.setFieldValue(numberFieldName, event.target.value);
          break;
        case 'size':
          if (twoDecimalRegex.test(event.target.value)) formik.setFieldValue(numberFieldName, dotToComma(event.target.value));
          break;
        case 'priceAmount':
          if (twoDecimalRegex.test(event.target.value)) {
            setPriceAmountInBaseCurrency(event.target.value, resolvePath(formik.values, `${name}.exchangeRate`));
            formik.setFieldValue(numberFieldName, dotToComma(event.target.value));
          }
          break;
        case 'priceExchangeRate':
          if (exchangeRateRegex.test(event.target.value)) {
            setPriceAmountInBaseCurrency(resolvePath(formik.values, `${name}.amount`), event.target.value);
            formik.setFieldValue(numberFieldName, dotToComma(event.target.value));
          }
          break;
      }
    }
  };

  const getHelperText = () => {
    const errors = resolvePath(formik.errors, numberFieldName);
    if (resolvePath(formik.touched, numberFieldName) && errors) return errors;

    if (type === 'priceAmount') {
      const amountInBaseCurrency = strToNr(resolvePath(formik.values, `${name}.amountInBaseCurrency`));
      if (resolvePath(formik.values, `${name}.currency`) !== DEFAULT_CURRENCY && amountInBaseCurrency && amountInBaseCurrency > 0) {
        return `EUR: ${resolvePath(formik.values, `${name}.amountInBaseCurrency`)}`
      }
    }

    if (type === 'integer' && name === 'qty') {
      const totalQty = Number(resolvePath(formik.values, `qty`));
      if (totalQty) {
        const loadings: FormLoading[] = resolvePath(formik.values, `loadings`);
        const loadingsTotalQty = loadings.map(loading => Number(loading.qty)).reduce(
          (sum, current) => sum + current, 0);
        return `Loadings cover ${loadingsTotalQty} of ${totalQty} units`;
      }
    }

    return null;
  };

  const inputLabelClasses = extras === 'constantShrink' ?
    {classes: {root: formClasses.labelOverflow}, shrink: true} : {classes: {root: formClasses.labelOverflow}}

  return (
    <MuiTextField
      {...props}
      name={numberFieldName}
      className={formClasses.formField}
      type="textfield"
      value={resolvePath(formik.values, numberFieldName) || ''}
      InputLabelProps={inputLabelClasses}
      onChange={(e) => handleChange(e)}
      onBlur={() => formik.setFieldTouched(numberFieldName, true)}
      error={!!resolvePath(formik.touched, numberFieldName) && !!resolvePath(formik.errors, numberFieldName)}
      helperText={getHelperText()}
    />
  );
}

import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {FormikHelpers, useFormik} from "formik";
import {EditPasswordForm} from "../../../API/types";
import API from "../../../API";
import {ProfileEditErrors} from "../types";
import React, {Fragment} from "react";
import TextField from "../../components/Formik/TextField";

const useStyles = makeStyles(() => ({
  title: {
    marginTop: '30px',
    marginLeft: '5px'
  },
  form: {
    padding: '5px',
  },
  input: {
    minWidth: '240px',
  },
}));
export default function ChangePasswordForm(props: { onSubmit: () => void }) {
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const validationSchema = yup.object({
    oldPassword: yup.string().required('Old password is required'),
    newPassword: yup.string()
      .min(6, 'Password should be of minimum 6 characters length.')
      .required('New password is required'),
    confirmNewPassword: yup.string()
      .min(6, 'Password should be of minimum 6 characters length.')
      .required('Confirm new password.')
      .oneOf([yup.ref('newPassword')], "Passwords don't match."),
  });

  const formik = useFormik<EditPasswordForm>({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: EditPasswordForm,
      formikHelpers: FormikHelpers<EditPasswordForm>
    ) => {
      const response = await API.changePassword(form);

      if (response.isSuccessful) {
        enqueueSnackbar('Password successfully updated', {
          variant: 'success',
        });

        formikHelpers.resetForm();
      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';
        if (response.code === ProfileEditErrors.USER_NOT_FOUND) {
          errorMessage = 'User not found.';
        }
        if (response.code === ProfileEditErrors.INSUFFICIENT_PASSWORD_COMPLEXITY) {
          errorMessage = 'Password should be of minimum 6 characters length.';
        }
        if (response.code === ProfileEditErrors.INVALID_CREDENTIALS) {
          errorMessage = 'Invalid current password.';
        }
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }

      props.onSubmit();
    },
  });

  return (
    <Fragment>
      <Typography variant="h5" className={classes.title}>Change password</Typography>
      <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              className={classes.input}
              formik={formik}
              name="oldPassword"
              label="Old password"
              type="password"
            />
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item>
              <TextField
                className={classes.input}
                formik={formik}
                name="newPassword"
                label="New password"
                type="password"
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                formik={formik}
                name="confirmNewPassword"
                label="Confirm password"
                type="password"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button type="submit" variant="outlined" color="primary">
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}
import {TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField/TextField';
import React from 'react';
import {Box} from "@material-ui/core";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import { theme } from '../../../theme';


export type DateAndTimePickerProps = MuiTextFieldProps & {
  formik: any;
  name: string;
  label: string;
};

export default function DateAndTimePicker({ formik, name, label }: DateAndTimePickerProps) {
  return (
    <>
      <DatePicker formik={formik} name={name + '.date'} label={label + ' date'} />
      <Box style={{minWidth: '100px', paddingLeft: theme.spacing(1), justifyContent: 'flex-end'}}>
        <TimePicker formik={formik} name={name + '.time'} />
      </Box>
    </>
  );
}

import * as React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { ContactsResponse, ProjectWeekResponse } from '../../../API/types';
import CreateProjectForm from './CreateProjectForm';
import {ProjectForm} from '../types';
import DialogTitle from "../../components/DialogTitle";

export interface EditProjectDialogProps {
  onClose: () => void;
  onSubmit: (form: ProjectForm) => Promise<void>;
  open: boolean;
  clients: ContactsResponse[];
  project: ProjectWeekResponse | null;
  newOurRefValue: number
}

export default function CreateEditProjectDialog(props: EditProjectDialogProps) {
  const { open, onClose, onSubmit, clients, project, newOurRefValue } = props;

  return (
    <Dialog onClose={onClose} open={open} maxWidth="md">
      {project && (
        <React.Fragment>
          <DialogTitle onClose={onClose}>
            {project.id !== undefined && project.id.length > 0 ? `Edit order ${project.ourRef}` : 'Create order'}
          </DialogTitle>
          <DialogContent>
            <CreateProjectForm
              project={project}
              actionLabel={project.id !== undefined && project.id.length > 0 ? ('Update') : ('Submit')}
              clients={clients}
              onSubmit={onSubmit}
              onCloseClick={onClose}
              newOurRefValue={newOurRefValue}
            />
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
}

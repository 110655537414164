import {ContactPerson, ContactsResponse, CreateProjectFormInitial, ProjectViewResponse} from "../../../API/types";
import React, {Fragment, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Button, InputAdornment, makeStyles, TextField as MuiTextField, Typography} from "@material-ui/core";
import TextField from "../../components/Formik/TextField";
import * as yup from "yup";
import {FormikHelpers, useFormik} from "formik";
import {resolvePath} from "../../../utils/formUtils";
import {ArrowBack} from "@material-ui/icons";
import API from "../../../API";
import {useSnackbar} from "notistack";
import {getCurrentPeriod, getWeekString} from "../../../utils/weekUtils";

export interface CreateFormProps {
  project?: ProjectViewResponse;
  actionLabel?: string;
  onSubmitClick: () => void;
  onCloseClick: () => void;
  newOurRefValue: number
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '5px',
  },
  input: {
    minWidth: '240px',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: '5px',
  },
  submit: {
    marginLeft: '20px'
  },
}));
export default function CreateForm(props: CreateFormProps) {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {project, actionLabel, newOurRefValue, onCloseClick, onSubmitClick} = props;
  const types = ['Order']; // should be replaced by object with all possible types of projects, temp solution
  const [clients, setClients] = useState<ContactsResponse[]>([]);
  const [clientsContactPersons, setClientsContactPersons] = useState<Map<string, ContactPerson[]>>();

  useEffect(() => {
    API.getCustomers().then((response) => {
      if (response.isSuccessful) {
        setClients(response.data);
        setClientsContactPersons(new Map(response.data.map((contact):
        [string, ContactPerson[]] => [contact.id, contact.persons])));
      }
    });
  }, []);

  const validationSchema = yup.object({
    id: yup.string().nullable(),
    type: yup.string().required('Type is required').uppercase().oneOf(['ORDER']),
    ourRef: yup.number().required('Reference number is required'),
    ourRefPart: yup.number()
      .required('Reference number is required')
      .typeError('Reference must be a number')
      .test('len', 'Must be no more than 6 characters',
          val => (val ? val.toString() : '').toString().length <= 6),
    customerId: yup.string().nullable(),
    customerName: yup.string().max(255).nullable(),
    customerRef: yup.string().max(255).nullable(),
    customerPersonId: yup.string().nullable(),
    customerPersonName: yup.string().max(255).nullable(),
  });

  const formik = useFormik<CreateProjectFormInitial>({
    initialValues: {
      type: project?.type ?? '',
      ourRef: newOurRefValue,
      ourRefPart: '00',
      customerId: project?.customerId ?? null,
      customerName: project?.customerName ?? null,
      customerRef: null,
      customerPersonId: project?.customerPersonId ?? null,
      customerPersonName: project?.customerPersonName ?? null,
      week: getWeekString(getCurrentPeriod().isoWeek()),
      year: getCurrentPeriod().year().toString(),
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: CreateProjectFormInitial,
      formikHelpers: FormikHelpers<CreateProjectFormInitial>
    ) => {
      let response;

      if (project && project.id.length > 0) {
        response = await API.editInitialProject(project.id, form);
      } else {
        response = await API.createInitialProject(form);
      }

      if (response.isSuccessful) {
        const action = project && project.id.length > 0 ? 'updated' : 'created';
        enqueueSnackbar(`Project successfully ${action}`, {
          variant: 'success',
        });
        formikHelpers.resetForm();
        onSubmitClick();
      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';

        if (response.message) {
          errorMessage = response.message
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
        <Grid container spacing={3}>
          {project?.id === '' && (
            <Grid item container>
              <Grid item xs={11}>
                <Autocomplete
                  fullWidth
                  autoComplete
                  autoSelect
                  selectOnFocus
                  handleHomeEndKeys
                  disableClearable
                  disablePortal
                  options={types}
                  onChange={(event, value) => {
                    formik.setFieldValue("type", value?.toUpperCase());
                  }}
                  renderInput={(params) =>
                    <MuiTextField
                      error={!!resolvePath(formik.touched, 'type') && !!resolvePath(formik.errors, 'type')}
                      helperText={resolvePath(formik.touched, 'type') && resolvePath(formik.errors, 'type')}
                      name="type"
                      label="Type"
                      {...params}
                    />}
                />
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={3}>
            <Grid item container>
              {!project?.id && (
                <>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      formik={formik}
                      type="number"
                      name="ourRef"
                      label="OUR ref"
                      InputProps={{
                        readOnly: !!project?.id,
                      }}
                    />
                  </Grid>
                  <Grid item xs={9} container>
                    <TextField
                      fullWidth
                      formik={formik}
                      label=" "
                      name="ourRefPart"
                      InputProps={{
                        readOnly: !!project?.id,
                        startAdornment:
                          <InputAdornment disableTypography position="start">
                            <Typography variant='h5'>.</Typography>
                          </InputAdornment>,
                      }}
                    />
                  </Grid>
                </>
              )
              }
            </Grid>
            <Grid item container>
              <Grid item xs={11}>
                <Autocomplete
                  clearOnEscape
                  disablePortal
                  autoComplete
                  autoSelect
                  handleHomeEndKeys
                  options={clients.map((client) => client.name)}
                  onChange={(event, value: string | null) => {
                    formik.setFieldValue("customerId",
                      value !== null ? clients.find(client => client.name === value)?.id : null);
                    formik.setFieldValue("customerName",
                      value !== null ? clients.find(client => client.name === value)?.name : null);
                    formik.setFieldValue("customerPersonId", null);
                    formik.setFieldValue("customerPersonName", null);
                  }}
                  value={formik.values.customerId ? formik.values.customerName : null}
                  renderInput={(params) =>
                    <MuiTextField
                      name="customerName"
                      label="Customer"
                      {...params}
                    />}
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  formik={formik}
                  name="customerRef"
                  label="Customer reference"
                />
              </Grid>
            </Grid>
            <Grid item container>
              {formik.values.customerId && (
                <Grid item xs={11}>
                  <Autocomplete
                    clearOnEscape
                    disablePortal
                    autoComplete
                    autoSelect
                    handleHomeEndKeys
                    options={
                      clientsContactPersons?.get(formik.values.customerId)?.map(person => person.name) || []
                  }
                    onChange={(event, value: string | null) => {
                      formik.setFieldValue("customerPersonId",
                        value !== null
                          ? clientsContactPersons?.get(formik.values.customerId!)?.find(
                            person => person.name === value)?.id
                          : null);
                      formik.setFieldValue("customerPersonName",
                        value !== null
                          ? clientsContactPersons?.get(formik.values.customerId!)?.find(
                            person => person.name === value)?.name
                          : null);
                    }}
                    value={formik.values.customerPersonId ? formik.values.customerPersonName : null}
                    renderInput={(params) =>
                      <MuiTextField
                        name="customerPersonName"
                        label="Customer contact person"
                        {...params}
                      />}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.buttonGroup}>
          <Button variant="text" color="primary" onClick={onCloseClick} startIcon={<ArrowBack/>}>
            Cancel
          </Button>
          <Button className={classes.submit} type="submit" variant="contained" color="primary">
            {actionLabel}
          </Button>
        </div>
      </form>
    </Fragment>
  );
}
import {Filters, FilterValue} from "react-table";
import {ContactsResponse} from "../../../API/types";
import {Box, TextField} from "@material-ui/core";
import React, {Fragment} from "react";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {useButtonGroupStyles} from "../../../styles/buttonGroupStyles";


export interface FilterProps {
  filters: Filters<ContactsResponse>;
  setFilter: (
    columnId: string,
    updater: ((filterValue: FilterValue) => FilterValue) | FilterValue
  ) => void;
}

export default function SearchFilter(props: FilterProps) {
  const classes = useButtonGroupStyles();
  const {filters, setFilter} = props;

  const getFilterValueForField = (field: string) => {
    const activeFilter = filters.find((filter) => filter.id === field);

    return activeFilter ? activeFilter.value : '';
  };

  return (
    <Fragment>
      <Box style={{width: '300px'}}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          name="name"
          label="Search"
          value={getFilterValueForField('name')}
          onChange={(e) => setFilter('name', e.target.value)}
        />
      </Box>
      <ToggleButtonGroup
        className={classes.toggle}
        color="primary"
        exclusive
        size="small"
        value={getFilterValueForField('type')}
        onChange={(e, value: string) => setFilter('type', value)}
      >
        <ToggleButton value={''} className={classes.button}>
          All
        </ToggleButton>
        <ToggleButton value={'Customer'} className={classes.button}>
          Customers
        </ToggleButton>
      </ToggleButtonGroup>
    </Fragment>
  );
}
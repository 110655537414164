import React, {ChangeEvent, useState} from 'react';
import {
  ContactsResponse, Permission,
  ProjectCellColors,
  ProjectComment,
  Loading,
  ProjectWeekResponse
} from '../../../API/types';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Popover,
  Select,
  TableContainer,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import HomeIcon from '@material-ui/icons/Home';
import {Cell, useExpanded, useTable, UseTableRowProps,} from 'react-table';
import dayjs from 'dayjs';
import {
  getCurrentPeriod,
  getSelectableWeeks,
  getWeekRange,
  getWeekString,
  isWeekNumber
} from "../../../utils/weekUtils";
import ProjectTableBody from './ProjectTableBody';
import {EditProjectForm, ProjectForm} from '../types';
import {GithubPicker} from 'react-color';
import LoadingSpinner from "../../components/LoadingSpinner";
import ExportProjects from "./ExportProjects";
import {Add} from "@material-ui/icons";
import Visible from "../../components/Visible";
import {toolbarStyles} from "../../../styles/toolbarStyles";
import {theme} from "../../../theme";
import {getDisplayLoadingName} from "../../ProjectDetails/components/LogisticsTab";

export interface ProjectTableProps {
  clients: ContactsResponse[];
  period: dayjs.Dayjs;
  onSubmit: (form: ProjectForm | EditProjectForm) => Promise<void>;
  onNextWeekClick: () => void;
  onPrevWeekClick: () => void;
  onWeekSelect: (period: dayjs.Dayjs) => void;
  onCreateProjectClick: () => void;
  onEditProjectClick: (project: ProjectWeekResponse) => void;
  onProjectUpClick: (id: string, steps: number) => void;
  onProjectDownClick: (id: string, steps: number) => void;

  projects: ProjectWeekResponse[];
  onCommentSubmit: () => void;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
}

const columns: {
  Header: string;
  Cell?: ({
            row,
          }: {
    row: UseTableRowProps<ProjectWeekResponse>;
  }) => string | number | null;
  accessor: keyof ProjectWeekResponse;
}[] = [
  {accessor: 'ourRef', Header: 'Our ref'},
  {accessor: 'customerRef', Header: 'Customer ref'},
  {accessor: 'origWeek', Header: 'Week'},
  {accessor: 'qty', Header: 'Units'},
  {accessor: 'pod', Header: 'POD'},
  {accessor: 'loadings', Header: 'Loadings'},
  {accessor: 'vessel', Header: 'Vessel/Booking'},
  {accessor: 'gate', Header: 'Gate'},
  {
    accessor: 'closingDoc',
    Header: 'Closing doc',
    Cell: ({row}) => (renderDateCell(row.original.closingDoc))
  },
  {accessor: 'etd', Header: 'ETD', Cell: ({row}) => (renderDateCell(row.original.etd))},
  {accessor: 'eta', Header: 'ETA', Cell: ({row}) => (renderDateCell(row.original.eta))},
  {accessor: 'vgm', Header: 'VGM', Cell: ({row}) => (renderVGMCell(row.original.vgm))},
  {accessor: 'm3', Header: 'M3'},
  {accessor: 'comments', Header: 'Comment', Cell: ({row}) => (renderCommentCell(row.original.comments))}
];

const renderDateCell = (date: Date | null) => {
  return date ? dayjs(date).format('DD.MM') : '';
}

const renderVGMCell = (vgm: boolean) => {
  return vgm ? 'ok' : '';
}

export const getLoadingTimeDisplayStr = (loadings: Loading[]) => loadings
  .sort((a, b) => a.sequence > b.sequence ? 1 : -1)
  .map((loading) => (
    getDisplayLoadingName(loading.sequence) + ': ' + dayjs(loading.time).format('DD.MM')
    + (loading.qty !== null && loading.qty !== 0 ? ` - ${loading.qty}` : ''
  )
));

const renderLoadingTimeCell = (loadings: Loading[]) => {
  return loadings.length ? getLoadingTimeDisplayStr(loadings).join(', ') : '';
}

const renderCommentCell = (comments: ProjectComment[]) => {
  return comments.length > 0 ? comments.length : '+';
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2, 2, 5),
    marginBottom: theme.spacing(2),
  },
  tableHeader: {
    padding: theme.spacing(1, 2, 0),
  },
  popover: {
    width: '300px',
    height: '300px',
  },
}));

export default function ProjectTable(props: ProjectTableProps) {
  const classes = useStyles();
  const toolbarClasses = toolbarStyles();

  const [colorPicker, setColorPicker] = useState<{
    row: { id: string; field: string };
    position: {
      top: number;
      left: number;
    };
  } | null>(null);

  const handleConfirmColor = (color: string) => {
    const id = colorPicker?.row.id as string;
    const field = colorPicker?.row.field as string;

    const project = props.projects.find((s) => s.id === id);
    if (project) {
      const updatedCell: ProjectCellColors = {
        cellName: field,
        hex: color
      };

      const filteredCellColors = project.colors ? project.colors.filter((c) => c.cellName !== field) : [];
      const colors: ProjectCellColors[] = [...filteredCellColors, updatedCell];
      const updatedProject = {...project, colors};
      props.onSubmit(updatedProject as EditProjectForm);
    }

    setColorPicker(null);
  };

  const handleClearColor = () => {
    const id = colorPicker?.row.id as string;
    const field = colorPicker?.row.field as string;
    const project = props.projects.find((s) => s.id === id);

    if (project) {
      const updatedCellColors = project.colors.filter((c) => c.cellName !== field);
      const updatedProject = {...project, colors: updatedCellColors}
      props.onSubmit(updatedProject as EditProjectForm)
    }

    setColorPicker(null);
  };

  const getStylesForCell = (cell: Cell<ProjectWeekResponse>) => {
    const field = cell.column.id;
    if (cell.row.original.colors) {
      const coloredCell = cell.row.original.colors.find((color) => color.cellName === field);

      if (coloredCell) {
        return { backgroundColor: coloredCell.hex }
      }
    }

    return {};
  };

  const handleCellClick =
    (cell: Cell<ProjectWeekResponse>) => (event: React.MouseEvent) => {
      if (window!!.getSelection()!!.toString()) {
        return;
      }

      const id = cell.row.original.id;
      const field = cell.column.id;
      const { clientX, clientY } = event;

      setColorPicker({
        row: { id, field },
        position: {
          top: clientY,
          left: clientX,
        },
      });
    };

  const { toggleAllRowsExpanded } = useTable(
    {
      columns,
      data: props.projects,
      autoResetExpanded: false,
    },
    useExpanded
  );

  const handleNextWeekClick = () => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onNextWeekClick();
  };

  const handleWeekSelect = (event: ChangeEvent<any>) => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onWeekSelect(dayjs(event.target.value));
  };

  const handleTodaySelect = () => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onWeekSelect(getCurrentPeriod());
  };

  const handlePrevWeekClick = () => {
    props.setLoading(true);
    toggleAllRowsExpanded(false);
    props.onPrevWeekClick();
  };

  const handleProjectUpClick = (project: ProjectWeekResponse, steps: number) => {
    props.onProjectUpClick(project.id, steps);
  };

  const handleProjectDownClick = (project: ProjectWeekResponse, steps: number) => {
    props.onProjectDownClick(project.id, steps);
  };

  const ProjectTableTitle = (weekNumber: number, isCurrentWeek: boolean) => {
    return (
        <div className={classes.tableHeader}>
          <h2 style={{"opacity": isCurrentWeek ? '1' : '0.9'}}>Week {weekNumber}</h2>
        </div>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Popover
        className={classes.popover}
        open={colorPicker !== null}
        anchorReference="anchorPosition"
        anchorPosition={colorPicker?.position as any}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setColorPicker(null)}
      >
        <GithubPicker
          triangle={'hide'}
          colors={['#EEEBD0', '#AAEFDF', '#74A4BC', '#44BBA4', '#DE9151', '#E5D352', '#DA9F93']}
          onChangeComplete={(c) => handleConfirmColor(c.hex)}
        />
        <Button variant="contained" onClick={handleClearColor} size={'small'} fullWidth>
          Clear
        </Button>
      </Popover>
      <Box className={toolbarClasses.container}>
        <Box className={toolbarClasses.subContainer}>
          <Typography variant="h5" className={toolbarClasses.title}>
            Dashboard
          </Typography>
          <Box whiteSpace="nowrap" style={{paddingRight: theme.spacing(2)}}>
            <IconButton size="small" onClick={handlePrevWeekClick}>
              <ChevronLeftIcon />
            </IconButton>
            <FormControl>
              <Select
                value={props.period.format('YYYY-MM-DD')}
                onChange={handleWeekSelect}
                disableUnderline
                style={{fontWeight: "bold"}}
              >
                {getSelectableWeeks(props.period).map((option) =>
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <Tooltip title="Today">
              <IconButton size="small" onClick={handleTodaySelect}>
                <HomeIcon />
              </IconButton>
            </Tooltip>
            <IconButton size="small" onClick={handleNextWeekClick}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </Box>
        <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Visible permission={Permission.UpdateProjects}>
            <ExportProjects
              projects={props.projects}
              period={props.period}
              renderDateCell={renderDateCell}
              renderVGMCell={renderVGMCell}
              renderLoadingTimeCell={renderLoadingTimeCell}
              columns={columns}/>
            <Button
              variant="contained"
              color="primary"
              disabled={props.isLoading}
              onClick={props.onCreateProjectClick}
              startIcon={<Add/>}
            >
              New order
            </Button>
          </Visible>
        </Box>
      </Box>
      {getWeekRange(props.period).map((week) => (
        <div key={week}>
          {ProjectTableTitle(week, isWeekNumber(week, props.period))}
          {props.isLoading ?
            <LoadingSpinner />
            :
            <TableContainer key={week}>
              <ProjectTableBody
                key={week}
                projects={props.projects.filter((project) => project.week === getWeekString(week))}
                handleProjectUpClick={handleProjectUpClick}
                handleProjectDownClick={handleProjectDownClick}
                getStylesForCell={getStylesForCell}
                columns={columns}
                handleCellClick={handleCellClick}
                onCommentSubmit={props.onCommentSubmit}
                isCurrentWeek={isWeekNumber(week, props.period)}
              />
           </TableContainer>
          }
        </div>
      ))}
    </Paper>
  );
}

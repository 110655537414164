import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {ProjectDetailsForm} from "../types";
import {useFormikContext} from "formik";
import DatePicker from "../../components/Formik/DatePicker";
import {Attachment, Permission, ProjectDetailsResponse, Variable} from "../../../API/types";
import LoadingSpinner from "../../components/LoadingSpinner";
import {useSnackbar} from "notistack";
import TextField from "../../components/Formik/TextField";
import CircularCheckbox from "../../components/Formik/CircularCheckbox";
import UpdatedFieldTooltip from "../../components/Formik/UpdatedFieldTooltip";
import Price from "../../components/Formik/Price";
import {useFormStyles} from "../../../styles/formStyles";
import {hasDateFieldChanged, hasTextFieldChanged} from "../../../utils/formUtils";
import Attachments from "../../components/Attachments/Attachments";
import Comments from "../../components/Comments";
import API from "../../../API";
import {DeleteForever, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import Visible from "../../components/Visible";
import {useHistory} from "react-router-dom";
import routes from "../../../router/routes";


const useStyles = makeStyles((theme) => ({
  formColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  advancedOptions: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    width: 'fit-content',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    marginTop: theme.spacing(1),
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
}))

interface OrderDetailsProps {
  variables: Variable[];
  project: ProjectDetailsResponse;
  onCommentAdded: () => void;
}

function OrderDetailsTab(props: OrderDetailsProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { variables, project, onCommentAdded } = props;
  const form = useFormikContext<ProjectDetailsForm>();
  const currencyVariable = variables.find(variable => variable.name === 'Currency');
  const attachmentTypeVariable = variables.find(variable => variable.id === '8c764aac-57cd-4329-96e3-122f22ddcfcc');

  const [attachments, setAttachments] = useState<Attachment[] | undefined>()
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const isComponentMounted = useRef<boolean>(false);

  useEffect(() => {
    isComponentMounted.current = true
    return function() {
      isComponentMounted.current = false;
    }
  }, [])

  useEffect(() => {
    loadAttachments();
  },[project])

  function loadAttachments() {
    if (project) {
      API.getAttachments(project.id).then((response) => {
        if (response.isSuccessful) {
          if (isComponentMounted.current) {
            setAttachments(response.data);
          }
        } else {
          enqueueSnackbar("Could not load attachments, please contact your administrator if the problem persists", {variant: 'error'});
          setAttachments(undefined);
        }
      }).catch(() => {
        enqueueSnackbar("Could not load attachments, please contact your administrator if the problem persists", {variant: 'error'});
        setAttachments(undefined);
      })
    }
  }

  if (currencyVariable === undefined) {
    enqueueSnackbar(
      'There has been a problem loading the Currency variable. Contact your admin if the issue persists.',
      {variant: 'error'}
    );
  }

  if (!attachmentTypeVariable) {
    enqueueSnackbar(
      'There has been a problem loading the Attachment Type variable. Contact your admin if the issue persists.',
      {variant: 'error'}
    );
  }

  const handleProjectDelete = () => {
    if (window.confirm('Are you sure you want to delete order ' + project.ourRef + '?')) {
      API.deleteProject(project.id).then(() => {
        history.push(routes.DASHBOARD);
        enqueueSnackbar('Project successfully deleted.', {variant: 'success'});
      });
    }
  };

  const AdvancedOptions = () => {
    const numberOfLoadings = form.values.loadings.length;

    return (
      <Visible permission={Permission.DeleteProjects}>
        <Typography
          variant="body2"
          className={classes.advancedOptions}
          onClick={() => setShowAdvancedOptions((value) => !value)}
        >
          Advanced options
          {showAdvancedOptions ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small"/>}
        </Typography>
        { showAdvancedOptions &&
          <Box>
            <Typography>
              Delete order {project.ourRef} and its {numberOfLoadings > 1 ? `${numberOfLoadings} loadings` : 'loading'}
            </Typography>
            <Button
              variant="contained"
              onClick={() => handleProjectDelete()}
              className={classes.deleteButton}
              startIcon={<DeleteForever />}
            >
              Delete order
            </Button>
          </Box>
        }
      </Visible>
    );
  };

  return (currencyVariable ?
    <Box className={classes.formColumn}>
      <Grid container>
        <Grid item xs={8}>
          <Box className={formClasses.formBlockContainer}>
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <TextField formik={form} name="clientRefId" label="Client Ref ID" className={formClasses.formField} />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(form.initialValues.clientRefId, form.values.clientRefId) && <UpdatedFieldTooltip />}
              </Box>
            </Box>
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <DatePicker formik={form} name="clientPoDate" label="Client PO date" />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasDateFieldChanged(form.initialValues.clientPoDate, form.values.clientPoDate) && <UpdatedFieldTooltip />}
              </Box>
            </Box>
            <Price formik={form} name="poValue" label="PO value" currencyVariable={currencyVariable} />
            <Price formik={form} name="soldValue" label="SOLD value" currencyVariable={currencyVariable} />
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <DatePicker formik={form} name="invoiceDate" label="Invoice date" />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasDateFieldChanged(form.initialValues.invoiceDate, form.values.invoiceDate) && <UpdatedFieldTooltip />}
              </Box>
            </Box>
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <TextField formik={form} name="invoiceRef" label="Invoice Ref" className={formClasses.formField} />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(form.initialValues.invoiceRef, form.values.invoiceRef) && <UpdatedFieldTooltip />}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5">Status</Typography>
          <CircularCheckbox formik={form} booleanName="suppliersPoSent" dateName="suppliersPoSentDate" label="Supplier PO sent" />
          <CircularCheckbox formik={form} booleanName="tpoSent" dateName="tpoSentDate" label="TPO sent" />
          <CircularCheckbox formik={form} booleanName="bookingReceived" dateName="bookingReceivedDate" label="Booking received" />
          <CircularCheckbox formik={form} booleanName="loaded" dateName="loadedDate" label="Loaded" />
          <CircularCheckbox formik={form} booleanName="delivered" dateName="deliveredDate" label="Delivered" />
          <CircularCheckbox formik={form} booleanName="cancelled" dateName="cancelledDate" label="Cancelled" />
        </Grid>
      </Grid>
      <Box className={formClasses.formRowWideContainer}>
        <Box className={formClasses.formFieldContainer}>
          <TextField formik={form} name="specialRequest" label="Special request" className={formClasses.formField} multiline minRows={1} maxRows={4} />
        </Box>
        {hasTextFieldChanged(form.initialValues.specialRequest, form.values.specialRequest) &&
            <Box className={formClasses.formFieldStatusContainer}><UpdatedFieldTooltip /></Box>
        }
      </Box>
      <Typography variant="h5" className={classes.header} component="p">Attachments</Typography>
      { project && attachmentTypeVariable && attachments && <Attachments
          ownerId={project.id}
          ownerType={"Project"}
          attachmentTypeVariable={attachmentTypeVariable}
          attachments={attachments}
          onAttachmentsModified={loadAttachments}
      /> }
      <Typography variant="h5" className={classes.header} component="p">Comments</Typography>
      { project && <Comments ownerId={project.id} comments={project.comments} onSubmit={onCommentAdded} />}
      <AdvancedOptions />
    </Box>
    :
    <LoadingSpinner />
  );
}

export default OrderDetailsTab;
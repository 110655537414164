import React, {useContext, useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import RestoreIcon from '@material-ui/icons/Restore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useTable, UseTableRowProps} from 'react-table';
import {Permission, UsersResponse} from '../../../API/types';
import Visible from "../../components/Visible";
import {UserContext} from "../../../contexts/UserContext";
import {Add} from "@material-ui/icons";
import {MenuAnchor} from "../../types";
import {useTableStyles} from "../../../styles/tableStyles";
import {toolbarStyles} from "../../../styles/toolbarStyles";


export interface UsersTableProps {
  users: UsersResponse[];
  onEditClick: (user: UsersResponse) => void;
  onDeleteClick: (id: string) => void;
  onRestoreClick: (user: UsersResponse) => void;
  onCreateClick: () => void;
}

const columns: {
  Header: string;
  Cell?: ({
    row,
  }: {
    row: UseTableRowProps<UsersResponse>;
  }) => string | number | null;
  accessor: keyof UsersResponse;
}[] = [
  { accessor: 'firstName', Header: 'First name' },
  { accessor: 'lastName', Header: 'Last name' },
  { accessor: 'email', Header: 'E-mail' },
  {
    accessor: 'isDeleted',
    Header: 'Status',
    Cell: ({ row }) => (row.original.isDeleted ? 'Inactive' : 'Active'),
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 5),
  },
  createButton: {
    float: 'right',
  },
}));

export default function UsersTable(props: UsersTableProps) {
  const classes = useStyles();
  const toolbarClasses = toolbarStyles();
  const tableClasses = useTableStyles();
  const { user } = useContext(UserContext);

  const [menuAnchor, setMenuAnchor] = useState<MenuAnchor>({ id: null, anchorEl: null });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: props.users,
    });

  const handleOpenMenu =
    (user: UsersResponse) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor({ id: user.id, anchorEl: event.currentTarget });
    };

  const handleCloseMenu = () => {
    setMenuAnchor({ id: null, anchorEl: null });
  };

  const handleEditClick = (user: UsersResponse) => {
    handleCloseMenu();
    props.onEditClick(user);
  };

  const handleDeleteClick = (user: UsersResponse) => {
    handleCloseMenu();
    props.onDeleteClick(user.id);
  };

  const handleRestoreClick = (user: UsersResponse) => {
    handleCloseMenu();
    props.onRestoreClick(user);
  };

  const actionMenuItems = [
    { name: 'Edit', action: handleEditClick, Icon: EditIcon, permission: Permission.UpdateUsers },
    { name: 'Delete', action: handleDeleteClick, Icon: DeleteIcon, permission: Permission.DeleteUsers },
  ];

  return (
    <Paper className={classes.paper}>
      <Box className={toolbarClasses.container}>
        <Typography variant="h5" className={toolbarClasses.title}>
          Users
        </Typography>
        <Visible permission={Permission.UpdateUsers}>
          <Button variant="contained"
                  color="primary"
                  onClick={props.onCreateClick}
                  className={classes.createButton}
                  startIcon={<Add/>}
          >
            New user
          </Button>
        </Visible>
      </Box>
      <TableContainer>
        <Table stickyHeader size="small" padding="none" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index+headerGroup.id}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <TableCell className={tableClasses.headerCell}
                             {...column.getHeaderProps()}
                             key={index+column.id}
                  >
                    <Typography
                      variant="body1"
                      noWrap
                      className={columnIndex === 0 ? tableClasses.headerWithoutDivider : tableClasses.header}
                    >
                      {column.render('Header')}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell className={tableClasses.headerCell} width={52}/>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                  <React.Fragment key={row.original.id}>
                    <TableRow{...row.getRowProps()} className={tableClasses.row}>
                      {row.cells.map((cell) => (
                        <TableCell {...cell.getCellProps()} key={index+cell.value} className={tableClasses.cell}>
                          <Typography variant="body1">{cell.render('Cell')}</Typography>
                        </TableCell>
                      ))}
                      <TableCell>
                        <Visible anyPermissions={[Permission.UpdateUsers, Permission.DeleteUsers]}>
                          <IconButton
                            size="small"
                            onClick={handleOpenMenu(row.original)}
                          >
                            <MoreVertIcon/>
                          </IconButton>
                          <Menu
                            anchorEl={
                              menuAnchor.id === row.original.id
                                ? menuAnchor.anchorEl
                                : null
                            }
                            getContentAnchorEl={null}
                            keepMounted={false}
                            open={menuAnchor.id === row.original.id}
                            onClose={handleCloseMenu}
                          >
                            {!row.original.isDeleted &&
                              actionMenuItems.map((item) => {
                                const Icon = item.Icon;

                                if (!user.permissions.includes(item.permission)) {
                                  return null;
                                }

                                return (
                                  <MenuItem
                                    onClick={() => item.action(row.original)}
                                    key={item.name}
                                  >
                                    <ListItemIcon>
                                      <Icon fontSize="small"/>
                                    </ListItemIcon>
                                    <ListItemText primary={item.name}/>
                                  </MenuItem>
                                );
                              })}
                            {row.original.isDeleted && (
                              <MenuItem
                                onClick={() => handleRestoreClick(row.original)}
                              >
                                <ListItemIcon>
                                  <RestoreIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText primary="Restore"/>
                              </MenuItem>
                            )}
                          </Menu>
                        </Visible>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

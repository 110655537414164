import React from 'react';
import {resolvePath} from "../../../utils/formUtils";
import {TextField, makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  icon: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      transform: 'scale(1.4)',
      opacity: 0.7,
      cursor: 'pointer',
      padding: '2px',
      marginRight: '3px',
      borderRadius: '10px',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
  },
}));

export type TimePickerProps = {
  formik: any;
  name: string;
};

export default function TimePicker({ formik, name }: TimePickerProps) {
  const classes = useStyles();

  return (
    <TextField
      className={classes.icon}
      color="primary"
      type="time"
      label="Time"
      name={name}
      value={resolvePath(formik.values, name) || ''}
      onChange={(event) => formik.setFieldValue(name, event.target.value)}
      InputLabelProps={{ shrink: true}}
      style={{width: '100%'}}
    />
  );
}

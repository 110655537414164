import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import {default as Dashboard} from '../scenes/Dashboard';
import {default as Users} from '../scenes/Users';
import routes from './routes';
import AuthenticatedPage from './Page/AuthenticatedPage';
import {Permission} from "../API/types";
import Protected from "./Protected";
import Contacts from "../scenes/Contacts";
import Contact from "../scenes/Contact";
import Profile from "../scenes/Profile";
import Projects from "../scenes/Projects";
import Variables from "../scenes/Variables";
import Project from "../scenes/ProjectDetails";
import DrawerProvider from "../contexts/DrawerContext";


const renderPermissionGuardedPage = (title: string, component: React.ComponentType, permission: Permission) => {
  return function renderPageInternal(props: any) {
    return <Protected permission={permission}>
      <AuthenticatedPage {...props} title={title} component={component}/>
    </Protected>
  };
};

/*
const renderRoleGuardedPage = (title: string, component: React.ComponentType, role: Role) => {
  return function renderPageInternal(props: any) {
    return <Protected role={role}>
      <AuthenticatedPage {...props} title={title} component={component}/>
    </Protected>
  };
}
*/

const renderPage = (title: string, component: React.ComponentType) => {
  return function renderPageInternal(props: any) {
    return <AuthenticatedPage {...props} title={title} component={component} />;
  };
};

export default function AuthenticatedRouter() {
  return (
    <Router>
      <DrawerProvider>
        <Switch>
          <Route exact path={routes.DASHBOARD} render={renderPage('Dashboard', Dashboard)} />
          <Route exact path={routes.PROJECTS.ROOT} render={renderPermissionGuardedPage('Projects', Projects, Permission.ReadProjects)} />
          <Route exact path={routes.PROJECTS.PROJECT} render={renderPermissionGuardedPage('Project', Project, Permission.ReadProjects)} />
          <Route exact path={routes.USERS} render={renderPermissionGuardedPage('Users', Users, Permission.ReadUsers)} />
          <Route exact path={routes.CONTACTS} render={renderPermissionGuardedPage('Contacts', Contacts, Permission.ReadContacts)} />
          <Route exact path={routes.CONTACT} render={renderPermissionGuardedPage('Contact', Contact, Permission.ReadContacts)} />
          <Route exact path={routes.VARIABLES} render={renderPermissionGuardedPage('Variables', Variables, Permission.UpdateVariables)} />
          <Route exact path={routes.PROFILE} render={renderPage('Profile', Profile)} />
          <Redirect to={routes.DASHBOARD} />
        </Switch>
      </DrawerProvider>
    </Router>
  );
}

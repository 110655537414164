import {Permission, Role} from "../API/types";
import React, {useContext} from "react";
import {UserContext} from "../contexts/UserContext";
import {Redirect, useLocation} from "react-router-dom";

type ProtectedProps = {
    permission: Permission,
    role?: never,
    children: React.ReactNode
} | {
    permission?: never,
    role: Role
    children: React.ReactNode,
}

export default function Protected({ permission, role, children }: ProtectedProps) {
    const { user } = useContext(UserContext)
    const location = useLocation()

    const hasPermission = permission && user.permissions.includes(permission);
    const hasRole = role && user.roles.includes(role)

    if (hasPermission || hasRole) {
        return <>{children}</>
    } else {
        return <Redirect to={{pathname: "/", state: {from: location}}}/>
    }
}
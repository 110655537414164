import {IconButton, makeStyles} from "@material-ui/core";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5)
  },
}))

export default function TablePaginationActions(props: any) {
  const classes = useStyles()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleBackButtonClick = () => onPageChange(page - 1)
  const handleNextButtonClick = () => onPageChange(page + 1)

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={(count !== -1) && (page >= Math.ceil(count / rowsPerPage) - 1)}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}

const routes = {
  DASHBOARD: '/dashboard',
  PROJECTS: {
    ROOT: '/projects',
    PROJECT: '/projects/:projectId'
  },
  USERS: '/users',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  CONTACTS: '/contacts',
  CONTACT: '/contacts/:id',
  VARIABLES: '/variables',
  PROFILE: '/profile',
};

export default routes;

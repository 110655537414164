
function round(value: number, nDecimals: number = 2) {
    const multiplier = Math.pow(10, nDecimals)
    return Math.round(value * multiplier) / multiplier;
}

export function formatFileSize(size: number): string {
    let result = size + "B";

    const oneGigabyte = 1073741824;
    const oneMegabyte = 1048576;
    const oneKilobyte = 1024;

    if (size > oneGigabyte) {
        const value = size / oneGigabyte;
        result = round(value) + "GB";
    } else if (size > oneMegabyte) {
        const value = size / oneMegabyte;
        result = round(value) + "MB";
    } else if (size > oneKilobyte) {
        const value = size / oneKilobyte;
        result = Math.round(value) + "kB";
    }

    return result;
}
import {ProjectViewResponse} from "../../../API/types";
import {Dialog, DialogContent} from "@material-ui/core";
import * as React from "react";
import DialogTitle from "../../components/DialogTitle";
import CreateForm from "./CreateForm";
export interface CreateEditProjectDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  project: ProjectViewResponse | null;
  newOurRefValue: number
}
export default function CreateEditProjectDialog(props: CreateEditProjectDialogProps) {
  const { open, onClose, onSubmit, project, newOurRefValue } = props;

  const handleSubmit = () => {
    onClose();
    onSubmit();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      {project && (
        <React.Fragment>
          <DialogTitle onClose={onClose}>
            {project.id !== undefined && project.id.length > 0 ? `Edit project ${project.ourRef}` : 'Create project'}
          </DialogTitle>
          <DialogContent>
            <CreateForm
              project={project}
              actionLabel={project.id !== undefined && project.id.length > 0 ? 'Update' : 'Submit'}
              onSubmitClick={() => handleSubmit()}
              onCloseClick={onClose}
              newOurRefValue={newOurRefValue}
            />
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );

}

import React from 'react';
import {default as MuiTextField, TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField/TextField';
import {resolvePath} from "../../../utils/formUtils";
import {makeStyles, MenuItem} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  currencyContainer: {
    minWidth: '70px',
    maxWidth: '70px',
    paddingRight: theme.spacing(1)
  },
}));

export type CurrencySelectProps = MuiTextFieldProps & {
  formik: any;
  name: string;
  currencies: string[];
};

export default function CurrencySelect(props: CurrencySelectProps) {
  const classes = useStyles();
  const { formik, name, currencies } = props;
  const fieldName = `${name}.currency`

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldValue(`${name}.amountInBaseCurrency`, null);
    formik.setFieldValue(`${name}.exchangeRate`, null);
    formik.setFieldValue(fieldName, event.target.value);
  }

  return (
    <MuiTextField
      {...props}
      select
      name={fieldName}
      label="Currency"
      value={resolvePath(formik.values, fieldName) || ''}
      onChange={handleChange}
      error={!!resolvePath(formik.touched, fieldName) && !!resolvePath(formik.errors, fieldName)}
      helperText={resolvePath(formik.touched, fieldName) && resolvePath(formik.errors, fieldName)}
      className={classes.currencyContainer}
    >
      {currencies.map((option) => (
        <MenuItem key={option} value={option}>{option}</MenuItem>
      ))}
    </MuiTextField>
  );
}

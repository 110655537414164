import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  makeStyles,
  Checkbox as MuiCheckbox,
  IconButton,
} from '@material-ui/core';
import {useFormikContext} from "formik";
import {FormUnit, ProjectDetailsForm} from "../types";
import {Permission} from "../../../API/types";
import {Sync, KeyboardArrowDown, KeyboardArrowUp, DeleteForever} from "@material-ui/icons";
import Visible from "../../components/Visible";
import {resolvePath} from "../../../utils/formUtils";
import {useTableStyles} from "../../../styles/tableStyles";
import TextField from "../../components/Formik/TextField";
import NumberTextField from "../../components/Formik/NumberTextField";
import {theme} from '../../../theme';
import {getDisplayLoadingName} from "./LogisticsTab";


const useStyles = makeStyles((theme) => ({
  showSpecifications: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    width: 'fit-content',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    width: '200px',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
}));

interface UnitsTableProps {
  generateUnitRows: () => void;
}

const UnitsTable = ({generateUnitRows}: UnitsTableProps) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const form = useFormikContext<ProjectDetailsForm>();

  const [showUnitDetails, setShowUnitDetails] = useState(true);

  const headers = ['Loading', 'Container number', 'Vehicle number', 'Seal Number', 'CBM', 'VGM'];

  const renderTableHeader = (title: string) => {
    return (
      <TableCell key={title} className={tableClasses.cell}>
        {title}
      </TableCell>
    );
  };

  const renderTableRow = (row: FormUnit, loadingIndex: number, unitIndex: number) => {
    return (
      <TableRow key={`${row.loadingSequence}-${unitIndex}`}>
        <TableCell className={tableClasses.cell}>
          <Typography variant="body2" style={{paddingTop: theme.spacing(1)}}>
            {getDisplayLoadingName(row.loadingSequence)}
          </Typography>
        </TableCell>
        <TableCell className={tableClasses.cell}>
          <TextField
            formik={form}
            name={`loadings[${loadingIndex}].units[${unitIndex}].containerNumber`}
          />
        </TableCell>
        <TableCell className={tableClasses.cell}>
          <TextField
            formik={form}
            name={`loadings[${loadingIndex}].units[${unitIndex}].vehicleNumber`}
          />
        </TableCell>
        <TableCell className={tableClasses.cell}>
          <TextField
            formik={form}
            name={`loadings[${loadingIndex}].units[${unitIndex}].sealNumber`}
          />
        </TableCell>
        <TableCell className={tableClasses.cell}>
            <NumberTextField
            formik={form}
            name={`loadings[${loadingIndex}].units[${unitIndex}].cbm`}
            type="size"
          />
        </TableCell>
        <TableCell className={tableClasses.cell} style={{paddingRight: theme.spacing(3)}}>
          <MuiCheckbox
            color="primary"
            name={`loadings[${loadingIndex}].units[${unitIndex}].vgm`}
            value={resolvePath(form.values, `loadings[${loadingIndex}].units[${unitIndex}].vgm`)}
            checked={form.values.loadings[loadingIndex].units[unitIndex].vgm}
            onChange={form.handleChange}
            size="small"
            style={{padding: 0, margin: 0}}
          />
        </TableCell>
        <TableCell className={tableClasses.cell}>
          <IconButton size="small">
            <DeleteForever style={{color: theme.palette.text.secondary}} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Visible permission={Permission.UpdateProjects}>
      <Typography
        variant="body2"
        className={classes.showSpecifications}
        onClick={() => setShowUnitDetails(!showUnitDetails)}
      >
        {showUnitDetails ? 'Hide' : 'Show'} unit details table
        {showUnitDetails ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small"/>}
      </Typography>
      {showUnitDetails && (
        form.values.loadings.reduce((count, loading) => count + loading.units.length, 0) === 0 ?
          <Button
            variant="contained"
            color="primary"
            onClick={() => generateUnitRows()}
            startIcon={<Sync />}
          >
            Generate units table
          </Button>
          :
          <Table padding="none">
            <TableHead>
              <TableRow>
                {headers.map(title => renderTableHeader(title))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {form.values.loadings.map((loading, loadingIndex) =>
                loading.units.map((unit, unitIndex) =>
                  renderTableRow(unit, loadingIndex, unitIndex)
                )
              )}
            </TableBody>
          </Table>
        )
      }
    </Visible>
  );
};

export default UnitsTable;

import React from 'react';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';
import {resolvePath} from "../../../utils/formUtils";

export type CheckboxProps = MuiCheckboxProps & {
  formik: any;
  name: string;
  label: string;
};

export default function Checkbox(props: CheckboxProps) {
  const { formik, name } = props;
  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          color="primary"
          name={name}
          value={resolvePath(formik.values, name)}
          checked={formik.values[name]}
          onChange={formik.handleChange}
        />
      }
      label={props.label}
    />
  );
}

import React, {useContext} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MainMenu from "./MainMenu";
import {UserContext} from '../../../contexts/UserContext';
import {Box, Fade} from '@material-ui/core';
import AdminMenuItems from "./AdminMenu";
import {User} from '../../../API/types';
import {DrawerContext} from '../../../contexts/DrawerContext';
import ProfileMenu from "./ProfileMenu";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hideDrawerIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '4px 10px',
  },
  showDrawerIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 1, 1),
    margin: theme.spacing(1, 0, 1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  profileMenu: {
    marginTop: 'auto',
  },
}));

export interface AuthenticatedPageProps {
  title: string;
  component: React.ComponentType;
}

export default function AuthenticatedPage(props: AuthenticatedPageProps) {
  const classes = useStyles();
  const { setUser } = useContext(UserContext);
  const { isDrawerOpen, handleDrawerClick } = useContext(DrawerContext);

  const handleLogoutClick = () => {
    setUser({} as User);
    localStorage.removeItem('token');
  };

  const PageContent = props.component;

  return (
    <Fade in={true}>
      <Box className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose)}}
          open={isDrawerOpen}
        >
          {isDrawerOpen ?
            <Box className={classes.hideDrawerIconContainer}>
              <IconButton onClick={handleDrawerClick}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
            :
            <Box className={classes.showDrawerIconContainer} onClick={handleDrawerClick}>
              <MenuIcon />
            </Box>
          }
          <Divider />
          <List><MainMenu /></List>
          <Divider />
          <List><AdminMenuItems /></List>
          <div className={classes.profileMenu}><ProfileMenu handleLogoutClick={handleLogoutClick} /></div>
        </Drawer>
        <main className={classes.content}>
          <Container maxWidth={false} className={classes.container}>
            <PageContent />
          </Container>
        </main>
      </Box>
    </Fade>
  );
}

import React from 'react';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {dateToStr, resolvePath} from "../../../utils/formUtils";
import {RadioButtonUncheckedOutlined, CheckCircle} from "@material-ui/icons";
import UpdatedFieldTooltip from "../../components/Formik/UpdatedFieldTooltip";


const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '200px',
    marginTop: theme.spacing(1)
  },
  labelHeader: {
    fontWeight: 500
  },
  checkboxIcon: {
    fontSize: '30px'
  },
  formFieldStatusContainer: {
    width: '30px'
  },
}));

export type CheckboxProps = MuiCheckboxProps & {
  formik: any;
  booleanName: string;
  dateName: string;
  label: string;
};

export default function CircularCheckbox(props: CheckboxProps) {
  const classes = useStyles();
  const { formik, booleanName, dateName, label } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.handleChange(event);
    if (!formik.values[booleanName]) {
      const currentDate = new Date();
      formik.setFieldValue(dateName, dateToStr(currentDate));
    } else if (formik.values[booleanName]) {
      formik.setFieldValue(dateName, null);
    }
  };

  const Label = () => {
    return (
      <Box>
        <Typography variant="body2" className={classes.labelHeader}>
          {label}
        </Typography>
        <Typography variant="body2">
          {formik.values[dateName] !== null ? formik.values[dateName].split('-').reverse().join('.') : 'Uncompleted'}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes.box}>
      <FormControlLabel
        control={
          <MuiCheckbox
            color="primary"
            name={booleanName}
            icon={<RadioButtonUncheckedOutlined className={classes.checkboxIcon} />}
            checkedIcon={<CheckCircle className={classes.checkboxIcon} />}
            value={resolvePath(formik.values, booleanName)}
            checked={formik.values[booleanName]}
            onChange={(e) => handleChange(e)}
          />
        }
        label={<Label />}
      />
      {(formik.values[booleanName] !== formik.initialValues[booleanName] || formik.values[dateName] !== formik.initialValues[dateName]) &&
        <Box className={classes.formFieldStatusContainer}>
          <UpdatedFieldTooltip/>
        </Box>
      }
    </Box>

  );
}

import * as React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import {ContactsResponse} from '../../../API/types';
import CreateForm from "./CreateForm";
import DialogTitle from "../../components/DialogTitle";

export interface CreateEditContactDialogProps {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  contact: ContactsResponse | null;
}

export default function CreateEditContactDialog(props: CreateEditContactDialogProps) {
  const { open, onClose, onSubmit, contact } = props;

  const handleSubmit = () => {
    onClose();
    onSubmit();
  };

  return (
    <Dialog maxWidth={'md'} onClose={onClose} open={open}>
      {contact && (
        <React.Fragment>
          <DialogTitle onClose={onClose}>
            {contact.id !== undefined && contact.id.length > 0 ? 'Edit contact' : 'Create contact'}
          </DialogTitle>
          <DialogContent>
            <CreateForm
              contact={contact}
              actionLabel={contact.id !== undefined && contact.id.length > 0 ? 'Update' : 'Submit'}
              onCloseClick={onClose}
              onSubmitClick={() => handleSubmit()}
            />
          </DialogContent>
        </React.Fragment>
      )}
    </Dialog>
  );
}
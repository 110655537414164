import React, {useEffect, useState} from "react";
import {useFormikContext} from "formik";
import {ProjectDetailsForm} from "../types";
import {Prompt} from "react-router-dom";
import {isEqual} from "lodash";

export default function FormObserver() {
  const formik = useFormikContext<ProjectDetailsForm>()
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);

  useEffect(() => {
    setShouldBlockNavigation(!isEqual(formik.initialValues, formik.values));
  }, [formik.values])

  return <Prompt
    when={shouldBlockNavigation}
    message='You have unsaved changes, are you sure you want to leave?'
  />;
}
import React from "react";
import {getCode, getName, getNames} from "country-list";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";

export type SelectCountryProps = {
  formik: any;
  priorityOptions?: string[]
};
export default function CountrySelect(props: SelectCountryProps) {
  const {formik, priorityOptions} = props;

  const countryNames = priorityOptions ? getNames().sort((x, y) =>
      priorityOptions!.includes(x) ? -1 : !priorityOptions!.includes(y) ? 1 : 0) : getNames()

  return (
    <Autocomplete
      fullWidth
      clearOnEscape
      disablePortal
      autoComplete
      autoSelect
      selectOnFocus
      handleHomeEndKeys
      options={countryNames}
      onChange={(event: any, value: string | null) => {
        formik.setFieldValue("country", value !== null ? getCode(value) : null);
      }}
      value={formik.values.country ? getName(formik.values.country) : null}
      renderInput={(params) => (
        <TextField
          name="country"
          label="Country"
          {...params}
        />
      )}
    />
  );
}
import React, {Fragment, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {DropzoneArea} from "material-ui-dropzone";
import {
  Box,
  Button, CircularProgress,
  Dialog, DialogContent,
  DialogTitle, List,
  makeStyles,
  TextField as MuiTextField, Typography
} from "@material-ui/core";
import {Permission, Variable} from "../../../API/types";
import {Autocomplete} from "@material-ui/lab";
import {Field, FieldArray, Formik, FormikHelpers, FormikProps} from "formik";
import {AttachFileForm} from "../../ProjectDetails/types";
import Visible from "../Visible";
import * as yup from "yup";
import {ArrowBack} from "@material-ui/icons";
import API from "../../../API";
import {formatFileSize} from "./utils";
import {resolvePath} from "../../../utils/formUtils";

interface FileUploadProps {
  ownerId: string;
  ownerType: string;
  onSubmitSuccess: () => void;
  attachmentTypeVariable: Variable;
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '5px',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  box: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: '5px',
    display: 'flex',
  },
  submit: {
    marginLeft: theme.spacing(2),
    height: '100%',
    width: '110px',
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fileSize: {
    boxSizing: 'content-box',
    paddingTop: '5px',
    paddingBottom: '10px',
    color: 'gray',
    fontSize: 'small'
  },
  file: {
    display: 'flex', flexDirection: 'column'
  }
}));

const fileUploadValidationSchema = yup.object().shape({
  files: yup.array().of(yup.object().shape({
    filename: yup.string()
      .max(255, 'File name length should not exceed 255 characters')
      .required('File name is required')
  }))
})

export default function FileUpload({ownerId, ownerType, onSubmitSuccess, attachmentTypeVariable}: FileUploadProps) {
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();

  const [selectedFiles, setSelectedFiles] = useState<any[]>();
  const [initialFormValues, setInitialFormValues] = useState<AttachFileForm | undefined>(undefined);

  useEffect(() => {
    if (selectedFiles) {
      setInitialFormValues({
        attachmentType: '',
        files: selectedFiles.map((value) => {
          return {
            filename: value.name,
            file: value,
          };
        })
      });
    }
  }, [selectedFiles]);

  function handleSubmit(values: AttachFileForm, formikHelpers: FormikHelpers<AttachFileForm>) {
    if (values.files && values.files.length > 0) {
      API.attachFiles({
        attachmentType: values.attachmentType,
        ownerId: ownerId,
        ownerType: ownerType,
        files: values.files,
      })
        .then((result) => {
          if (result.isSuccessful) {
            onSubmitSuccess();
            setSelectedFiles(undefined);
          } else {
            enqueueSnackbar("Could not upload attachment, please contact your administrator", {variant: 'error'});
          }
        })
        .catch(() => {
          enqueueSnackbar("Could not upload attachment, please contact your administrator", {variant: 'error'});
        }).finally(() => {
          formikHelpers.setSubmitting(false);
        })
    }
  }

  function onDrop(files: File[]) {
    if (files && files.length > 0) {
      setSelectedFiles(files);
    } else {
      setSelectedFiles(undefined);
    }
  }

  const handleClose = () => {
    if (selectedFiles) {
      setSelectedFiles(undefined);
    }
  };

  return (
    <Visible permission={Permission.UpdateAttachments}>
      <div style={{opacity: '0.65'}}>
        <DropzoneArea
          filesLimit={30}
          showPreviewsInDropzone={false}
          onDrop={onDrop}
          showAlerts={['error']}
          maxFileSize={10485760} // 10MB
          clearOnUnmount={true}
        />
      </div>
      <Dialog
        open={!!selectedFiles}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        {selectedFiles && initialFormValues && <React.Fragment>
          <DialogTitle>Upload files</DialogTitle>
          <DialogContent>
            <Fragment>
              <Formik
                initialValues={initialFormValues}
                validationSchema={fileUploadValidationSchema}
                onSubmit={handleSubmit}
                className={classes.form}
              >
                {(formikProps: FormikProps<AttachFileForm>) =>
                  <>
                    <Autocomplete
                      clearOnEscape
                      autoComplete
                      autoSelect
                      handleHomeEndKeys
                      options={['', ...attachmentTypeVariable.options]}
                      value={formikProps.values.attachmentType}
                      onChange={(event: any, value: string | null) => {
                        formikProps.setFieldValue('attachmentType', value);
                      }}
                      renderInput={(params) =>
                        <MuiTextField
                          name="attachmentType"
                          label="Attachment type"
                          autoFocus
                          {...params}
                        />
                      }
                      className={classes.marginBottom}
                    />
                    <FieldArray
                      name={"files"}
                      render={() => (
                        <>
                          <List>
                            {formikProps.values.files.map((value, index) => {
                              const fieldName = `files.${index}.filename`;
                              const fieldError = resolvePath(formikProps.errors, fieldName);
                              return (
                                <Box key={index} className={classes.file}>
                                  <Field
                                    error={!!fieldError}
                                    helperText={fieldError}
                                    name={fieldName}
                                    as={MuiTextField}
                                  />
                                  <Typography className={classes.fileSize}>
                                    File size: {formatFileSize(value.file.size)}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </List>
                        </>
                      )}
                    />
                    <Box className={classes.buttonGroup}>
                      <Box>
                        <Button variant="text" color="primary" onClick={handleClose} startIcon={<ArrowBack/>}>
                          Cancel
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          className={classes.submit}
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={formikProps.submitForm}
                          disabled={!formikProps.isValid || formikProps.isSubmitting}
                        >
                          { formikProps.isSubmitting ? <CircularProgress size={20} /> : 'Upload' }
                        </Button>
                      </Box>
                    </Box>
                  </>
                }
              </Formik>
            </Fragment>
          </DialogContent>
        </React.Fragment>}
      </Dialog>
    </Visible>
  );
};
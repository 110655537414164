import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import {theme} from './theme';
import { UserContext } from './contexts/UserContext';
import {User} from './API/types';
import AuthenticatedRouter from './router/AuthenticatedRouter';
import UnAuthenticatedRouter from './router/UnAuthenticatedRouter';
import LoadingSpinner from './scenes/components/LoadingSpinner';
import DayjsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/et';
import dayjs from 'dayjs';
import jwtDecode from "jwt-decode";
import {UserPrincipal} from "./scenes/Dashboard/types";
import API from "./API";
import {stringToEnumPermissionMap, stringToEnumRoleMap} from "./utils/Authorization";
dayjs.locale('et');

dayjs.extend(isoWeek);

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User|undefined>(undefined);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      setIsLoading(false);
    }

    if (token && !user?.id) {
      const principal = jwtDecode(token) as UserPrincipal
      const user = {
        id: principal.id,
        firstName: principal.firstName,
        lastName: principal.lastName,
        email: principal.email,
        roles: principal.roles.map(role => stringToEnumRoleMap[role]),
        permissions: principal.permissions.map(permission => stringToEnumPermissionMap[permission])
      } as User
      setUser(user);
      setIsLoading(false);

       API.getMyUser().then((response) => {
        if (!response.isSuccessful) {
          localStorage.removeItem('token');
          setIsLoading(false);
        }
      });
    }
  }, [user]);

  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <CssBaseline />
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <UserContext.Provider value={{ user: user || {} as User, setUser }}>
              {user?.id ? <AuthenticatedRouter /> : <UnAuthenticatedRouter />}
            </UserContext.Provider>
          )}
        </SnackbarProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;

import React, {useState} from 'react';
import {Accordion, AccordionSummary, AccordionDetails, Box, Typography, Button, makeStyles} from "@material-ui/core";
import {FormLoading, FormUnit, ProjectDetailsForm} from "../types";
import {FieldArray, FieldArrayRenderProps, useFormikContext} from "formik";
import {Add, DeleteForever, KeyboardArrowDown, KeyboardArrowUp} from "@material-ui/icons";
import DatePicker from "../../components/Formik/DatePicker";
import {theme} from "../../../theme";
import {useFormStyles} from "../../../styles/formStyles";
import {hasDateFieldChanged, hasDateTimeFieldChanged, hasTextFieldChanged, strToNr} from "../../../utils/formUtils";
import UpdatedFieldTooltip from "../../components/Formik/UpdatedFieldTooltip";
import NumberTextField from "../../components/Formik/NumberTextField";
import Visible from "../../components/Visible";
import {Permission, Variable} from "../../../API/types";
import DateAndTimePicker from "../../components/Formik/DateAndTimePicker";
import VariableSelect from "../../components/Formik/VariableSelect";
import Price from "../../components/Formik/Price";
import TextField from "../../components/Formik/TextField";
import UnitsTable from "./UnitsTable";


export const getDisplayLoadingName = (sequence: number): string => {
  return sequence < 10 ? `0${sequence}` : sequence.toString();
}

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    margin: 0,
    height: '50px',
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: `1px dashed ${theme.palette.divider}`,
      minHeight: '50px',
      maxHeight: '50px',
    },
  },
  accordionDetails: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'scroll'
  },
  showDelete: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    width: 'fit-content',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3)
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    width: '200px',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    }
  },
}));

interface LogisticsProps {
  variables: Variable[];
}

export default function LogisticsTab({variables}: LogisticsProps) {
  const form = useFormikContext<ProjectDetailsForm>();
  const classes = useStyles();
  const formClasses = useFormStyles();

  const currencyVariable = variables.find(variable => variable.id === '86ad3022-9a88-43ab-bde1-dac8f7cd5f5f');
  const transportTypeVariable = variables.find(variable => variable.id === '603d7d09-bca1-489d-a84e-b0939cb3188c');
  const portVariable = variables.find(variable => variable.id === '90ac135c-253b-43ef-8e55-ee488c7d5718');
  const haulageVariable = variables.find(variable => variable.id === 'ba773a6a-612b-4f86-8a6e-321efb454020');
  const forwarderVariable = variables.find(variable => variable.id === '2320250d-406f-410d-bd25-321a753b63e1');
  const contlineVariable = variables.find(variable => variable.id === '5bea69de-15c9-4949-bd4d-e54514607f38');

  const [expandedAccordions, setExpandedAccordions] = useState<number[]>(form.values.loadings.length === 1 ? [0] : []);
  const [visibleDeleteOptions, setVisibleDeleteOptions] = useState<number[]>([]);

  // TODO - remove feature flag when Units handling is completed
  const showUnitsTable = false;

  const handleExpandedAccordionsChange = (index: number) => {
    if (expandedAccordions.includes(index)) {
      setExpandedAccordions(expandedAccordions.filter(item => item !== index));
    } else {
      setExpandedAccordions([...expandedAccordions, index]);
    }
  };

  const handleShowDeleteChange = (index: number) => {
    if (visibleDeleteOptions.includes(index)) {
      setVisibleDeleteOptions(visibleDeleteOptions.filter(item => item !== index));
    } else {
      setVisibleDeleteOptions([...visibleDeleteOptions, index]);
    }
  };

  const generateUnitRows = () => {
    form.values.loadings.forEach((loading, index) => {
      const quantity = strToNr(loading.qty);
      if (quantity && quantity > 0) {
        const units: FormUnit[] = [];
        for (let i=1; i<=quantity; i++)  {
          units.push({
            loadingSequence: loading.sequence,
            containerNumber: null,
            vehicleNumber: null,
            sealNumber: null,
            cbm: null,
            vgm: false,
          });
        }
        form.setFieldValue(`loadings[${index}].units`, units);
      }
    })
  };

  const renderLoadingFormFields = (formLoading: FormLoading, index: number) => {
    const initialFormLoading = form.initialValues.loadings.find(loading => loading.sequence === formLoading.sequence);

    return(
      <Box key={index} className={formClasses.formBlockContainer}>
        <Box className={formClasses.multipleInputFormRowContainer}>
          <Box className={formClasses.formRowContainer}>
            <NumberTextField formik={form} name={`loadings[${index}].qty`} label="Units" type="integer"/>
            <Box className={formClasses.formFieldStatusContainer}>
              {hasTextFieldChanged(initialFormLoading ? initialFormLoading.qty : null,
                formLoading.qty) && <UpdatedFieldTooltip/>}
            </Box>
          </Box>
          <Box className={formClasses.formRowContainer}>
            <DatePicker formik={form} name={`loadings[${index}].time`} label="Stuffing date" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasDateFieldChanged(initialFormLoading ? initialFormLoading.time : null,
                formLoading.time) && <UpdatedFieldTooltip />}
            </Box>
          </Box>
        </Box>
        {transportTypeVariable &&
          <Box className={formClasses.formRowContainer}>
            <VariableSelect
              formik={form}
              name={`loadings[${index}].transportType`}
              label="Transport type"
              variable={transportTypeVariable}
            />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasTextFieldChanged(initialFormLoading ? initialFormLoading.transportType : null,
                formLoading.transportType) && <UpdatedFieldTooltip/>}
            </Box>
          </Box>
        }
        {portVariable &&
          <Box className={formClasses.multipleInputFormRowContainer}>
            <Box className={formClasses.formRowContainer}>
              <VariableSelect formik={form} name={`loadings[${index}].pol`} label="POL" variable={portVariable} />
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.pol : null,
                  formLoading.pol) && <UpdatedFieldTooltip/>}
              </Box>
            </Box>
            <Box className={formClasses.formRowContainer}>
              <VariableSelect formik={form} name={`loadings[${index}].pod`} label="POD" variable={portVariable} />
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.pod : null,
                  formLoading.pod) && <UpdatedFieldTooltip/>}
              </Box>
            </Box>
          </Box>
        }
        <Box className={formClasses.multipleInputFormRowContainer}>
          <Box className={formClasses.formRowContainer}>
            <DatePicker formik={form} name={`loadings[${index}].eta`} label="ETA" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasDateFieldChanged(initialFormLoading ? initialFormLoading.eta : null, formLoading.eta)
                && <UpdatedFieldTooltip />}
            </Box>
          </Box>
          <Box className={formClasses.formRowContainer}>
            <DatePicker formik={form} name={`loadings[${index}].etaRequested`} label="ETA requested" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasDateFieldChanged(initialFormLoading ? initialFormLoading.etaRequested : null,
                formLoading.etaRequested) && <UpdatedFieldTooltip />}
            </Box>
          </Box>
        </Box>
        <Box className={formClasses.formRowContainer}>
          <DatePicker formik={form} name={`loadings[${index}].ata`} label="ATA" />
          <Box className={formClasses.formFieldStatusContainer}>
            {hasDateFieldChanged(initialFormLoading ? initialFormLoading.ata : null, formLoading.ata)
              && <UpdatedFieldTooltip />}
          </Box>
        </Box>
        {haulageVariable &&
          <Box className={formClasses.multipleInputFormRowContainer}>
            <Box className={formClasses.formRowContainer}>
              <VariableSelect formik={form} name={`loadings[${index}].haulageBy`} label="Haulage by" variable={haulageVariable} />
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.haulageBy : null,
                  formLoading.haulageBy) && <UpdatedFieldTooltip/>}
              </Box>
            </Box>
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <TextField formik={form} name={`loadings[${index}].haulageReference`} label="Haulage reference" className={formClasses.formField} />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.haulageReference : null,
                  formLoading.haulageReference) && <UpdatedFieldTooltip />}
              </Box>
              </Box>
          </Box>
        }
        {forwarderVariable &&
          <Box className={formClasses.formRowContainer}>
            <VariableSelect formik={form} name={`loadings[${index}].forwarder`} label="Forwarder" variable={forwarderVariable} />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasTextFieldChanged(initialFormLoading ? initialFormLoading.forwarder : null,
                formLoading.forwarder) && <UpdatedFieldTooltip/>}
            </Box>
          </Box>
        }
        {contlineVariable &&
          <Box className={formClasses.multipleInputFormRowContainer}>
            <Box className={formClasses.formRowContainer}>
              <VariableSelect formik={form} name={`loadings[${index}].contline`} label="Contline" variable={contlineVariable} />
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.contline : null,
                  formLoading.contline) && <UpdatedFieldTooltip/>}
              </Box>
            </Box>
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <TextField formik={form} name={`loadings[${index}].bookingReference`} label="Booking reference" className={formClasses.formField} />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.bookingReference : null,
                  formLoading.bookingReference) && <UpdatedFieldTooltip />}
              </Box>
            </Box>
            <Box className={formClasses.formRowContainer}>
              <Box className={formClasses.formFieldContainer}>
                <TextField formik={form} name={`loadings[${index}].billOfLading`} label="Bill of lading" className={formClasses.formField} />
              </Box>
              <Box className={formClasses.formFieldStatusContainer}>
                {hasTextFieldChanged(initialFormLoading ? initialFormLoading.billOfLading : null,
                  formLoading.billOfLading) && <UpdatedFieldTooltip />}
              </Box>
            </Box>
          </Box>
        }
        <Box className={formClasses.formRowContainer}>
          <DateAndTimePicker formik={form} name={`loadings[${index}].gateOpen`} label="Gate open" />
          <Box className={formClasses.formFieldStatusContainer}>
            {hasDateTimeFieldChanged(initialFormLoading ? initialFormLoading.gateOpen : null,
              formLoading.gateOpen) && <UpdatedFieldTooltip />}
          </Box>
        </Box>
        <Box className={formClasses.formRowContainer}>
          <DatePicker formik={form} name={`loadings[${index}].polDate`} label="POL date" />
          <Box className={formClasses.formFieldStatusContainer}>
            {hasDateFieldChanged(initialFormLoading ? initialFormLoading.polDate : null, formLoading.polDate)
              && <UpdatedFieldTooltip />}
          </Box>
        </Box>
        <Box className={formClasses.multipleInputFormRowContainer}>
          <Box className={formClasses.formRowContainer}>
            <DateAndTimePicker formik={form} name={`loadings[${index}].cutOffContainersNos`} label="Cut off cont NOS" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasDateTimeFieldChanged(initialFormLoading ? initialFormLoading.cutOffContainersNos : null,
                formLoading.cutOffContainersNos) && <UpdatedFieldTooltip />}
            </Box>
          </Box>
          <Box className={formClasses.formRowContainer}>
            <DateAndTimePicker formik={form} name={`loadings[${index}].cutOffContainers`} label="Cut off containers" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasDateTimeFieldChanged(initialFormLoading ? initialFormLoading.cutOffContainers : null,
                formLoading.cutOffContainers) && <UpdatedFieldTooltip />}
            </Box>
          </Box>
        </Box>
        {currencyVariable &&
            <Box className={formClasses.multipleInputFormRowContainer}>
                <Price formik={form} name={`loadings[${index}].estimatedUnitFrCost`} label="Estimated unit FR cost" currencyVariable={currencyVariable} />
                <Price formik={form} name={`loadings[${index}].unitFrCostPaid`} label="Unit FR cost paid" currencyVariable={currencyVariable} />
            </Box>
        }
        <Box className={formClasses.multipleInputFormRowContainer}>
          <Box className={formClasses.formRowContainer}>
            <NumberTextField formik={form} name={`loadings[${index}].vgm`} label="VGM" type="size" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasTextFieldChanged(initialFormLoading ? initialFormLoading.vgm : null, formLoading.vgm)
                && <UpdatedFieldTooltip/>}
            </Box>
          </Box>
          <Box className={formClasses.formRowContainer}>
            <NumberTextField formik={form} name={`loadings[${index}].cbm`} label="CBM" type="size" />
            <Box className={formClasses.formFieldStatusContainer}>
              {hasTextFieldChanged(initialFormLoading ? initialFormLoading.cbm : null, formLoading.cbm)
                && <UpdatedFieldTooltip/>}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderDelete = (name: string, index: number, remove: <T>(index: number) => T | undefined) => {
    const showDelete = visibleDeleteOptions.includes(index);

    return (
      <Visible permission={Permission.DeleteProjects}>
        <Typography
          variant="body2"
          className={classes.showDelete}
          onClick={() => handleShowDeleteChange(index)}
        >
          {showDelete ? 'Hide' : 'Show'} delete
          {showDelete ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small"/>}
        </Typography>
        {showDelete &&
          <Button
              variant="contained"
              onClick={() => {
                remove(index);
                setVisibleDeleteOptions([]);
              }}
              className={classes.deleteButton}
              startIcon={<DeleteForever />}
          >
              Delete loading {name}
          </Button>
        }
      </Visible>
    );
  };

  const renderLoadingFormAccordion = (formLoading: FormLoading, index: number, remove: <T>(index: number) => T | undefined) => {
    const displayName = getDisplayLoadingName(formLoading.sequence);
    const displayTime = formLoading.time && !isNaN(formLoading.time.getTime())
      ? formLoading.time.toISOString().split('T')[0].split('-').reverse().join('.')
      : null;

    return (
      <Accordion
        key={formLoading.sequence}
        expanded={expandedAccordions.includes(formLoading.sequence)}
        onChange={() => handleExpandedAccordionsChange(formLoading.sequence)}
        style={{margin: '1px', borderRadius: '3px'}}
      >
        <AccordionSummary expandIcon={<KeyboardArrowDown />} className={classes.accordionSummary}>
          <Typography style={{paddingRight: theme.spacing(10)}}>
            Loading {displayName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formLoading.qty && formLoading.qty + (Number(formLoading.qty) > 1 ? ' units' : ' unit')}
            {displayTime && ' at ' + displayTime}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {renderLoadingFormFields(formLoading, index)}
          {form.values.loadings.length > 1 && renderDelete(displayName, index, remove)}
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Box>
      <Box className={formClasses.formRowContainer} style={{paddingBottom: theme.spacing(2)}}>
        <NumberTextField formik={form} name={`qty`} label="Total units" type="integer" />
        <Box className={formClasses.formFieldStatusContainer}>
          {hasTextFieldChanged(form.initialValues.qty, form.values.qty) && <UpdatedFieldTooltip />}
        </Box>
      </Box>
      <Box style={{borderRadius: '5px', borderTop: '1px solid rgba(0,0,0,0.1)'}}>
        <FieldArray name="loadings">
          {({push, remove}: FieldArrayRenderProps) =>
            <>
              {form.values.loadings.map((loading, index) =>
                renderLoadingFormAccordion(loading, index, remove)
              )}
              <Box style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: theme.spacing(2)
              }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => {
                    const newLoading: FormLoading = {
                      ...form.values.loadings[0],
                      sequence: form.values.loadings[form.values.loadings.length - 1].sequence + 1,
                    }
                    push(newLoading);
                    handleExpandedAccordionsChange(form.values.loadings.length + 1);
                  }}
                >
                  Add new loading
                </Button>
              </Box>
            </>
          }
        </FieldArray>
        {showUnitsTable && <UnitsTable generateUnitRows={generateUnitRows}/>}
      </Box>
    </Box>
  );
}
import {FieldArray, FormikProvider} from "formik";
import Grid from "@material-ui/core/Grid";
import {Button, IconButton, makeStyles, Typography} from "@material-ui/core";
import TextField from "../../components/Formik/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import {ContactPerson} from "../../../API/types";
import {Add} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  titleBar: {
    marginTop: '15px',
  },
  createButton: {
    float: 'right'
  },
}));
export default function PersonForm(props: {formik: any}) {
  const classes = useStyles();

  const getInputProps = (id: string | null) => {
    return id ? {readOnly: true} : undefined
  };

  return (
    <Grid item container>
      <FormikProvider value={props.formik}>
        <FieldArray
          name="persons"
          render={({remove, push}) => (

            <Grid container spacing={3} className={classes.titleBar}>
              <Grid item xs={6}>
                <Typography variant="h5">
                  Contact persons
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Button variant="text"
                        color="primary"
                        onClick={() => push({name: '', email: null, phoneNumber: null})}
                        className={classes.createButton}
                        startIcon={<Add/>}>
                  Add person
                </Button>
              </Grid>
              {props.formik.values.persons.map((person: ContactPerson, index: number) => (
                <Grid item xs={12} key={index}>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <TextField InputProps={getInputProps(person?.id)} fullWidth formik={props.formik}
                                 name={`persons[${index}].name`} label="Name"/>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField InputProps={getInputProps(person?.id)} fullWidth formik={props.formik}
                                 name={`persons[${index}].email`} label="Email"/>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField InputProps={getInputProps(person?.id)} fullWidth formik={props.formik}
                                 name={`persons[${index}].phoneNumber`} label="Phone"/>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={() => remove(index)}>
                        <ClearIcon/>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        />
      </FormikProvider>
    </Grid>
  )
}

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link, useHistory} from 'react-router-dom';
import routes from '../../routes';
import Visible from "../../../scenes/components/Visible";
import {Permission} from "../../../API/types";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {drawerStyles} from "../../../styles/drawerStyles";


export default function MainMenu() {
  const classes = drawerStyles();
  const history = useHistory();

  return (
    <div>
      <Visible permission={Permission.ReadProjects}>
        <ListItem button component={Link} to={routes.DASHBOARD} className={
          history.location.pathname === routes.DASHBOARD ? classes.listItemActive : classes.listItemInactive
        }>
          <ListItemIcon className={classes.listIcon}>
            <AspectRatioIcon/>
          </ListItemIcon>
          <ListItemText primary="Dashboard"/>
        </ListItem>
        <ListItem button component={Link} to={routes.PROJECTS.ROOT} className={
          history.location.pathname === routes.PROJECTS.ROOT ? classes.listItemActive : classes.listItemInactive
        }>
          <ListItemIcon className={classes.listIcon}>
            <AssignmentIcon/>
          </ListItemIcon>
          <ListItemText primary="Projects"/>
        </ListItem>
      </Visible>
    </div>
  );
}

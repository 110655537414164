import {useFormikContext} from 'formik'
import {useEffect} from 'react'
import {ProjectDetailsForm} from "../../ProjectDetails/types";
import {useSnackbar} from "notistack";

export default function formSubmitErrorNotification() {
  const { isValid, isValidating, isSubmitting } = useFormikContext<ProjectDetailsForm>()
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      enqueueSnackbar('Form cannot be submitted as some fields have errors', {variant: 'error'});
    }
  }, [isSubmitting, isValid, isValidating])

  return null
}


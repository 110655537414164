import React, {useEffect, useState} from "react";
import {Box, Button, List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import TextField from "./Formik/TextField";
import {compareDatesDesc, formatDateTimeStr} from "../../utils/dateTime";
import {FormikHelpers, useFormik} from "formik";
import {CreateProjectCommentRequest, ProjectComment} from "../../API/types";
import API from "../../API";
import * as yup from "yup";
import {useSnackbar} from "notistack";
import {useFormStyles} from "../../styles/formStyles";
import {Add} from "@material-ui/icons";

interface CommentsProps {
  ownerId: string,
  comments: ProjectComment[],
  onSubmit: () => void;
  onCloseClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  list: {
    paddingTop: theme.spacing(0),
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
  listItem: {
    padding: theme.spacing(0)
  },
  form: {
    paddingBottom: theme.spacing(1),
  }
}))

export default function Comments({ ownerId, comments, onSubmit, onCloseClick }: CommentsProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();

  const LOAD_STEP = 5;
  const loadNumberOfComments = () => {
    return comments.length > LOAD_STEP ? LOAD_STEP : comments.length;
  };

  const [numberOfLoadedComments, setNumberOfLoadedComments] = useState(onCloseClick ? loadNumberOfComments() : comments.length);

  useEffect(() => {
    if (onCloseClick && numberOfLoadedComments < LOAD_STEP) setNumberOfLoadedComments(loadNumberOfComments());
  }, [comments]);

  const handleLoadMoreComments = () => {
    const updatedNumberOfComments = numberOfLoadedComments + LOAD_STEP;
    setNumberOfLoadedComments(updatedNumberOfComments > comments.length ? comments.length : updatedNumberOfComments);
  }

  const validationSchema = yup.object({
    body: yup.string().required('Comment is required'),
  });

  const formik = useFormik<CreateProjectCommentRequest>({
    initialValues: {
      body: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: CreateProjectCommentRequest,
      formikHelpers: FormikHelpers<CreateProjectCommentRequest>
    ) => {
      const createResponse = await API.createProjectComment(ownerId, form);

      if (createResponse.isSuccessful) {
        enqueueSnackbar('Comment added', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to add a new comment', {
          variant: 'error',
        });
      }
      onSubmit();
      formikHelpers.resetForm();
    },
  });

  return <Box>
    <form onReset={formik.handleReset} onSubmit={formik.handleSubmit} className={classes.form}>
      <Box className={formClasses.formFieldContainer}>
        <TextField
          formik={formik}
          name="body"
          label="New comment"
          multiline
          minRows={1}
          maxRows={10}
          className={formClasses.formField}
        />
      </Box>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.buttonGroup}>
          <Button className={classes.button} type="reset" color="primary" disabled={formik.isSubmitting}>Cancel</Button>
          <Button className={classes.button} type="submit" color="primary" disabled={formik.isSubmitting} variant="contained">Add</Button>
        </Box>
      </Box>
    </form>
    <List className={classes.list}>
      { comments
        .sort((a, b) => compareDatesDesc(a.createdAt, b.createdAt))
        .slice(0, numberOfLoadedComments)
        .map((comment, index: number) => {
          return <ListItem key={index} className={classes.listItem}>
            <ListItemText
              className={classes.preWrap}
              primary={ comment.body }
              secondary={ formatDateTimeStr(comment.createdAt) + ' - ' + comment.firstName + ' ' + comment.lastName }
            />
          </ListItem>;
        })
      }
    </List>
    {onCloseClick &&
      <Box style={{display: 'flex', justifyContent: 'center'}}>
        {numberOfLoadedComments >= LOAD_STEP &&
          <Box paddingRight={3}>
            <Button
              variant="text"
              color="primary"
              startIcon={<Add/>}
              disabled={numberOfLoadedComments === comments.length}
              onClick={handleLoadMoreComments}
            >
              Load more
            </Button>
          </Box>
        }
        <Button variant="outlined" color="primary" onClick={onCloseClick}>
          Close
        </Button>
      </Box>
    }
  </Box>;
};
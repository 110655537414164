import React, {createContext, ReactElement, useState} from 'react';


interface DrawerContextType {
  isDrawerOpen: boolean;
  handleDrawerClick: () => void;
  isAdminMenuOpen: boolean;
  handleAdminMenuClick: () => void;
}

interface Props {
  children: ReactElement;
}

export const DrawerContext = createContext<DrawerContextType>({} as DrawerContextType);

export default function DrawerProvider({children}: Props): ReactElement {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);

  const handleDrawerClick = () => setIsDrawerOpen((value) => !value);
  const handleAdminMenuClick = () => setIsAdminMenuOpen((value) => !value);

  return (
    <DrawerContext.Provider value={{isDrawerOpen, handleDrawerClick, isAdminMenuOpen, handleAdminMenuClick}}>
      {children}
    </DrawerContext.Provider>
  );
}

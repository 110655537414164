import React, {Fragment, useContext} from 'react';
import {
    Box,
    Collapse,
    IconButton,
    Link as MuiLink,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@material-ui/core';
import clsx from 'clsx';
import {Cell, Row, useExpanded, useTable} from 'react-table';
import {Permission, ProjectWeekResponse} from '../../../API/types';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {Add, DirectionsBoat, DragIndicator, LocalShipping} from "@material-ui/icons";
import {doesUserHavePermission} from "../../components/Visible";
import routes from "../../../router/routes";
import {UserContext} from "../../../contexts/UserContext";
import Comments from "../../components/Comments";
import {useTableStyles} from "../../../styles/tableStyles";
import {Link} from "react-router-dom";
import {theme} from "../../../theme";
import {getLoadingTimeDisplayStr} from "./ProjectTable";


const useStyles = makeStyles((theme) => ({
    handleCell: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingBottom: theme.spacing(1)
    },
    totalsRow: {
        borderBottom: 0,
    },
    totalRowCell: {
        borderBottom: 0,
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'none',
    },
    linkPadded: {
        cursor: 'pointer',
        padding: theme.spacing(1, 1, 1),
    },
    commentsContainer: {
        outline: '1px dashed',
        borderStyle: 'dashed',
        borderWidth: '0 1px 0 1px',
        outlineColor: theme.palette.divider,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.action.hover,
        padding: theme.spacing(2, '20vw', 2),
        borderRadius: 1
    }
}));

interface VesselBooking {
    sequence: number;
    haulageBy?: string | null;
    haulageReference?: string | null;
    contline?: string | null;
    bookingReference?: string | null;
    billOfLading?: string | null;
}

interface TableBodyProps {
    projects: ProjectWeekResponse[];
    handleProjectUpClick: (project: ProjectWeekResponse, steps: number) => void;
    handleProjectDownClick: (project: ProjectWeekResponse, steps: number) => void;
    getStylesForCell: (cell: Cell<ProjectWeekResponse, any>) => { backgroundColor: string; } | { backgroundColor?: undefined; };
    columns: any;
    handleCellClick: (cell: Cell<ProjectWeekResponse, any>) => (event: React.MouseEvent) => void;
    onCommentSubmit: () => void;
    isCurrentWeek: boolean;
}

const DragHandle = SortableHandle(() =>
  <IconButton size="small" style={{marginLeft: '-8px'}}>
      <DragIndicator />
  </IconButton>
)

const renderAddButton = () => {
    return <IconButton color="inherit" style={{marginLeft: '-4px', padding: 0}}><Add style={{fontSize: '20px'}} /></IconButton>;
}

const renderLoadingCellRow = (loading: string, index: number) => {
    return (
      <Box key={index} style={{
          minWidth: 'fit-content',
          maxWidth: 'fit-content',
          border: '2px solid',
          borderColor: theme.palette.action.focus,
          margin: '2px 2px 4px 0',
          borderRadius: '5px',
          padding: '0px 2px'
      }}>
          <Typography noWrap variant="body2">{loading}</Typography>
      </Box>
    )
}

const renderVesselBookingCellRow = (vesselBooking: VesselBooking, index: number) => {
    const haulageTooltip: string = (vesselBooking.haulageBy ?? '?') + ' / ' +
      (vesselBooking.haulageReference ?? '?')
    const contlineTooltip: string = (vesselBooking.contline ?? '?') + ' / ' +
      (vesselBooking.bookingReference ?? '?') + ' / ' + (vesselBooking.billOfLading ?? '?')

    const getStyle = (filled: boolean) => {
        return {color: filled ? theme.palette.success.main : theme.palette.text.disabled, opacity: 0.9, margin: '2px'};
    };

    return (
      <Box key={index} style={{display: 'flex', minWidth: 'fit-content', alignItems: 'center'}}>
          <Tooltip placement="top" arrow title={haulageTooltip}>
              <LocalShipping  color="inherit" style={getStyle(!!vesselBooking.haulageBy)} />
          </Tooltip>
          <Tooltip placement="top" arrow title={contlineTooltip}>
              <DirectionsBoat color="inherit" style={getStyle(
                !!vesselBooking.contline && !!vesselBooking.bookingReference && !!vesselBooking.billOfLading)} />
          </Tooltip>
      </Box>
    );
};

const renderCell = (row: Row<ProjectWeekResponse>, cell: Cell<ProjectWeekResponse>, handleCommentsClick: (id: string) => void) => {
    const {user} = useContext(UserContext);
    const classes = useStyles();

    if (cell.column.id === 'comments' && doesUserHavePermission(Permission.UpdateProjects, user)) {
        return (
          <MuiLink
            className={classes.linkPadded}
            onClick={() => handleCommentsClick(row.id)}
          >
              {cell.value.length === 0 ? renderAddButton() : cell.render('Cell')}
          </MuiLink>
        );
    } else if (cell.column.id === 'ourRef' && doesUserHavePermission(Permission.UpdateProjects, user)) {
        return (
          <Link
            className={classes.link}
            to={routes.PROJECTS.ROOT + '/' + row.original.id}
          >
              <MuiLink variant="body1" component={'div'}>{cell.render('Cell')}</MuiLink>
          </Link>
        );
    } else if (cell.column.id === 'vessel') {
        const loadingsVesselBookingData: VesselBooking[] = row.original.loadings
          .sort((a, b) => a.sequence > b.sequence ? 1 : -1)
          .map(loading => loading as VesselBooking)
        return loadingsVesselBookingData.map((vesselBooking, index) => renderVesselBookingCellRow(vesselBooking, index));
    } else if (cell.column.id === 'loadings' && row.original.loadings.length > 0) {
        const loadings: string[] = getLoadingTimeDisplayStr(row.original.loadings)
        return loadings.map((loading, index) => renderLoadingCellRow(loading, index));
    } else {
        return cell.render('Cell');
    }
}

// @ts-ignore
const SortableRow = SortableElement(({row, handleCommentsClick, getStylesForCell, handleCellClick, classes}) => {
    const tableClasses = useTableStyles();

    return <Fragment>
        <TableRow {...row.getRowProps()} className={tableClasses.row}>
            <TableCell key='dragAndDropMenu' className={classes.handleCell}>
                <DragHandle/>
            </TableCell>
            {row.cells.map((cell: Cell<ProjectWeekResponse>) => (
              <TableCell
                {...cell.getCellProps()}
                key={cell.column.id}
                className={tableClasses.cell}
                style={getStylesForCell(cell)}
                onClick={cell.column.id !== 'ourRef' && cell.column.id !== 'comments' ? handleCellClick(cell) : () => {}}
              >
                  {renderCell(row, cell, handleCommentsClick)}
              </TableCell>
            ))}
        </TableRow>
    </Fragment>
});

// @ts-ignore
const SortableTableBody = SortableContainer(({rows, columnsLength, tableBodyProps, renderTotalQty, renderTotalM3, handleCommentsClick, toggleRowExpanded, onCommentSubmit, getStylesForCell, handleCellClick, prepareRow}) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();

    rows.forEach((row: any) => prepareRow(row));
    return (
      <TableBody {...tableBodyProps}>
          {rows.map((row: Row<ProjectWeekResponse>, index: number) => (
            <Fragment key={index+row.id}>
                <SortableRow
                  key={`item-${index}${row.id}`}
                  index={index}
                  row={row}
                  handleCommentsClick={handleCommentsClick}
                  getStylesForCell={getStylesForCell}
                  handleCellClick={handleCellClick}
                  classes={classes}
                />
                <TableRow key={row.original.id + '-comments'}>
                    <TableCell colSpan={columnsLength + 2}>
                        <Collapse in={row.isExpanded} unmountOnExit>
                            <Box className={classes.commentsContainer}>
                                <Comments
                                  ownerId={row.original.id}
                                  comments={row.original.comments}
                                  onSubmit={onCommentSubmit}
                                  onCloseClick={() => toggleRowExpanded([row.id])}
                                />
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </Fragment>
          ))}
          {rows.length > 0 && <TableRow key={'totals'} className={classes.totalsRow}>
              <TableCell className={classes.totalRowCell} />
              {rows[0].cells.map((cell: Cell) => (
                <TableCell key={cell.column.id + '-total'} className={clsx(tableClasses.cell, classes.totalRowCell)}>
                    { cell.column.id === 'qty' && renderTotalQty()}
                    { cell.column.id === 'm3' && renderTotalM3()}
                </TableCell>
              ))}
          </TableRow>}
      </TableBody>
    );
});

export default function ProjectTableBody({
    projects, handleProjectUpClick, handleProjectDownClick, getStylesForCell,
    columns, handleCellClick, onCommentSubmit, isCurrentWeek
}: TableBodyProps) {
    const tableClasses = useTableStyles();

    const handleCommentsClick = (id: string) => {
        toggleRowExpanded([id]);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        toggleRowExpanded,
    } = useTable(
        {
            columns,
            data: projects,
            autoResetExpanded: false,
        },
        // useSortBy,
        useExpanded
    );

    const renderTotalQty = () => {
        const totalQTY = projects
            .map(item => item.qty ? Number(item.qty) : 0)
            .reduce((prev, curr) => prev + curr, 0);

        return <div style={{fontWeight: 'bold'}}>{totalQTY}</div>;
    };

    const renderTotalM3 = () => {
        const totalM3 = projects
            .map(item => item.m3 ? Number(item.m3) : 0)
            .reduce((prev, curr) => prev + curr, 0);

        return <div style={{fontWeight: 'bold'}}>{totalM3}</div>;
    };

    const onSortEnd = (project: {oldIndex : number, newIndex: number}) => {
        if (project.oldIndex === project.newIndex) {
            return;
        }
        const movingProject = rows[project.oldIndex].original;
        const steps = Math.abs(project.newIndex - project.oldIndex);

        if (project.newIndex < project.oldIndex) {
            handleProjectUpClick(movingProject, steps);
        } else {
            handleProjectDownClick(movingProject, steps);
        }
    }

    return <>
        {rows.length <= 0 ? <div style={{marginLeft: '30px', marginBottom: '30px'}}>No orders found</div> :
            <Table padding="none" {...getTableProps} style={{opacity: isCurrentWeek ? '1' : '0.9'}}>
                <TableHead>
                    {headerGroups.map((headerGroup, index) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()} key={index+headerGroup.id}>
                            <TableCell />
                            {headerGroup.headers.map((column, index) => (
                                <TableCell key={index+column.id} className={tableClasses.headerCell}>
                                    <Typography variant="body1" className={index === 0 ? tableClasses.headerWithoutDivider : tableClasses.header} noWrap>
                                        {column.render('Header')}
                                    </Typography>
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    ))}
                </TableHead>
                <SortableTableBody
                  useDragHandle
                  onSortEnd={onSortEnd}
                  rows={rows}
                  tableBodyProps={getTableBodyProps}
                  renderTotalQty={renderTotalQty}
                  renderTotalM3={renderTotalM3}
                  handleCommentsClick={handleCommentsClick}
                  columnsLength={columns.length}
                  toggleRowExpanded={toggleRowExpanded}
                  onCommentSubmit={onCommentSubmit}
                  getStylesForCell={getStylesForCell}
                  handleCellClick={handleCellClick}
                  prepareRow={prepareRow}
                />
            </Table>
        }
    </>
}

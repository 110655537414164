import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import TablePaginationActions from "../../Projects/components/TablePaginationActions";
import React, {useEffect, useMemo, useState} from "react";
import {Column, Row, useTable} from "react-table";
import {HistoryResponse, ProjectHistoryRecord} from "../types";
import {RemoveRedEye} from "@material-ui/icons";
import API from "../../../API";
import {dateTime2Str} from "../../../utils/dateTime";

const useStyles = makeStyles((theme) => ({
  showDiff: {
    cursor: 'pointer',
    color: theme.palette.text.secondary
  },
}));

interface HistoryTableProps {
  projectId: string,
  defaultLimit: number,
  limitOptions: number[],
  setCurrentRecord: (record: ProjectHistoryRecord | undefined) => void,
  historyResponse: HistoryResponse,
  updateHistoryResponse: (historyResponse: HistoryResponse) => void,
}

interface PaginationState {
  limit: number,
  offset: number,
  visitedCursors: string[],
}

export default function HistoryTable({projectId, defaultLimit, limitOptions, setCurrentRecord, historyResponse, updateHistoryResponse}: HistoryTableProps) {

  const [paginationState, setPaginationState] = useState<PaginationState>({ limit: defaultLimit, offset: 0, visitedCursors: [] });
  const [refreshData, setRefreshData] = useState<PaginationState | undefined>();

  useEffect(() => {
    if (refreshData) {
      API.getProjectHistory(
        projectId,
        refreshData.limit,
        refreshData.visitedCursors.length > 0 ? refreshData.visitedCursors[refreshData.visitedCursors.length - 1] : null
      )
        .then((response) => {
          if (response.isSuccessful) {
            updateHistoryResponse(response.data);
            setPaginationState(refreshData);
          }
        });
      setRefreshData(undefined);
    }
  }, [refreshData]);

  const classes = useStyles();

  const columns = useMemo(
    (): Column<ProjectHistoryRecord>[] => [
      { Header: 'User', accessor: 'username' },
      { Header: 'Date', accessor: 'createdAt', Cell: ({row}) => dateTime2Str(row.original.createdAt)},
      { Header: 'Entity', accessor: 'entityType' },
      { Header: 'Action', accessor: 'action' },
      { id: 'viewAction', Cell: (tabelInstance: { row: Row<ProjectHistoryRecord> }) =>
          <RemoveRedEye
            className={classes.showDiff}
            onClick={() => setCurrentRecord(tabelInstance.row.original)}
          />
      },
    ],
    []
  );

  const {headers, rows, prepareRow } = useTable<ProjectHistoryRecord>(
    { columns: columns, data: historyResponse!!.records }
  );

  function handleOnRowsPerPageChange(event: any) {
    setRefreshData({ limit: parseInt(event.target.value), offset: 0, visitedCursors: [] });
  }

  function handleOnPageChange(page: any) {
    const pageIndex = parseInt(page);
    const offset = pageIndex * paginationState.limit;
    const visitedCursors = paginationState.visitedCursors.slice();

    if (offset > paginationState.offset) {
      if (historyResponse.cursorAfter) {
        visitedCursors.push(historyResponse.cursorAfter);
      }
    } else {
      visitedCursors.pop();
    }

    setRefreshData({ limit: paginationState.limit, offset: offset, visitedCursors: visitedCursors });
  }

  return <TableContainer>
    <Table>
      <TableHead>
        <TableRow>
          {
            headers.map((header, i) => {
              return <TableCell key={i}>{ header.render('Header') }</TableCell>;
            })
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          rows.map((row, i) => {
            prepareRow(row)
            return <TableRow key={i}>
              {
                row.cells.map((cell, j) => {
                  return <TableCell key={i+j}>{ cell.render('Cell') }</TableCell>;
                })
              }
            </TableRow>;
          })
        }
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={limitOptions}
            count={historyResponse.cursorAfter ? -1 : paginationState.offset + historyResponse.records.length}
            rowsPerPage={paginationState.limit}
            page={Math.floor(paginationState.offset/paginationState.limit)}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleOnPageChange}
            onRowsPerPageChange={handleOnRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  </TableContainer>;
}
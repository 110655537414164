import React from "react";
import API from "../../../API";
import {Attachment, Permission, Variable} from "../../../API/types";
import {useSnackbar} from "notistack";
import {
    Link,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import {HttpResponse} from "../../../utils/HttpClient";
import {DeleteForever} from "@material-ui/icons";
import Visible from "../Visible";
import {theme} from "../../../theme";
import FileUpload from "./FileUpload";
import {formatFileSize} from "./utils";
import {dateTime2Str} from "../../../utils/dateTime";

interface AttachmentsProps {
    ownerId: string,
    ownerType: string,
    attachmentTypeVariable: Variable,
    attachments: Attachment[],
    onAttachmentsModified: () => void,
}

const useStyles = makeStyles((theme) => ({
    link: {
        cursor: 'pointer'
    },
    tableContainer: {
        marginBottom: theme.spacing(2),
    }
}));

export default function Attachments(props: AttachmentsProps) {
    const {
        ownerId,
        ownerType,
        attachmentTypeVariable,
        attachments,
        onAttachmentsModified,
    } = props;

    const classes = useStyles();

    const {enqueueSnackbar} = useSnackbar();

    const deleteAttachment = (id: string) => {
        if (window.confirm('Are you sure you want to delete the attachment?')) {
            API.deleteAttachment(id).then((response) => {
                if (response.isSuccessful) {
                    enqueueSnackbar("Successfully deleted attachment", {variant: 'info'});
                    onAttachmentsModified();
                } else {
                    enqueueSnackbar("Could not delete attachment, please contact your administrator if the problem persists", {variant: 'error'});
                }
            }).catch(() => {
                enqueueSnackbar("Could not delete attachment, please contact your administrator if the problem persists", {variant: 'error'});
            })
        }
    }

    function getFilename(response: HttpResponse<Blob>): string | undefined {
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
            const filenamePart = contentDisposition.split(';').find((value: string) => value.includes('filename'));
            if (filenamePart) {
                const filename = filenamePart.split('=')[1];
                if (filename) {
                    return filename;
                }
            }
        }
    }

    const downloadAttachment = (id: string) => {
        API.downloadAttachment(id).then((response) => {
            if (!response.isSuccessful) {
                enqueueSnackbar("Could not download attachment, please contact your administrator if the problem persists", {variant: 'error'});
            } else {
                const filename = getFilename(response);
                if (filename) {
                    const url = window.URL.createObjectURL(new File([response.data], filename));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename)
                    link.setAttribute('target', '_blank')
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                }
            }
        }).catch(() => {
            enqueueSnackbar("Could not download attachment, please contact your administrator if the problem persists", {variant: 'error'});
        })
    }

    return (
      <Visible permission={Permission.ReadAttachments}>
            {attachments.length > 0 &&
                <TableContainer className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>File name</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell>Size</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {attachments.map(value => {
                                return (
                                    <TableRow key={value.id}>
                                        <TableCell>{value.attachmentType ?? ''}</TableCell>
                                        <TableCell>
                                            <Link onClick={() => downloadAttachment(value.id)} className={classes.link}>
                                                {value.fileName}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{dateTime2Str(value.createdAt)}</TableCell>
                                        <TableCell>{formatFileSize(value.fileSize)}</TableCell>
                                        <Visible permission={Permission.DeleteAttachments}>
                                            <TableCell align="right">
                                                <DeleteForever
                                                    onClick={() => deleteAttachment(value.id)}
                                                    style={{ cursor: 'pointer', color: theme.palette.text.secondary }}
                                                />
                                            </TableCell>
                                        </Visible>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <FileUpload
                ownerId={ownerId}
                ownerType={ownerType}
                onSubmitSuccess={onAttachmentsModified}
                attachmentTypeVariable={attachmentTypeVariable}
            />
        </Visible>
    );
};
import React from "react";
import {Box} from "@material-ui/core";
import {ProjectDetailsForm} from "../types";
import {useFormikContext} from "formik";
import LoadingSpinner from "../../components/LoadingSpinner";
import {useSnackbar} from "notistack";
import {Variable} from "../../../API/types";
import Price from "../../components/Formik/Price";
import PercentTextField from "../../components/Formik/PercentTextField";
import {useFormStyles} from "../../../styles/formStyles";


interface FinanceTabProps {
  variables: Variable[];
}

export default function FinanceTab(props: FinanceTabProps) {
  const classes = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const form = useFormikContext<ProjectDetailsForm>();
  const { variables } = props;

  const currencyVariable = variables.find(variable => variable.name === 'Currency')

  if (currencyVariable === undefined) {
    enqueueSnackbar(
      'There has been a problem loading the Currency variable. Contact your admin if the issue persists.',
      {variant: 'error'}
    );
  }

  return (currencyVariable ?
    <Box className={classes.formBlockContainer}>
      <Price formik={form} name="doxFee" label="DOX fee" currencyVariable={currencyVariable} />
      <Price formik={form} name="unitHaulageCost" label="Unit haulage cost" currencyVariable={currencyVariable} />
      <Price formik={form} name="customsCost" label="Customs cost" currencyVariable={currencyVariable} />
      <PercentTextField formik={form} name="agentFeePercent" label="Agent fee %" />
      <Price formik={form} name="packageCost" label="Package cost" currencyVariable={currencyVariable} />
      <Price formik={form} name="haulageCost" label="Haulage cost" currencyVariable={currencyVariable} />
      <Price formik={form} name="freightCost" label="Freight cost" currencyVariable={currencyVariable} />
      <Price formik={form} name="agentFeeCost" label="Agent fee" currencyVariable={currencyVariable} />
      <Price formik={form} name="ttlRevPlanned" label="TTL rev planned" currencyVariable={currencyVariable} />
      <Price formik={form} name="ttlRevImplemented" label="TTL rev implemented" currencyVariable={currencyVariable} />
      <PercentTextField formik={form} name="revPercent" label="Rev %" />
      <Price formik={form} name="cbmPrice" label="CBM price" currencyVariable={currencyVariable} />
      <Price formik={form} name="unitPrice" label="Unit price" currencyVariable={currencyVariable} />
    </Box>
    :
    <LoadingSpinner />
  );
}
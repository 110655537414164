import LoadingSpinner from "../components/LoadingSpinner";
import React, {useEffect, useState} from "react";
import API from "../../API";
import {ProjectViewResponse} from "../../API/types";
import ProjectsViewTable from "./components/ProjectsViewTable";
import {Filters} from "react-table";
import CreateEditProjectDialog from "./components/CreateEditProjectDialog";
import {useSnackbar} from "notistack";


export default function Projects() {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [totalProjects, setTotalProjects] = useState(0);
  const [projects, setProjects] = useState<ProjectViewResponse[]>([]);
  const [maxExistingRefNumber, setMaxExistingRefNumber] = useState<number>(0);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    reloadProjects();
  }, []);

  const reloadProjects = (params?: {
    globalFilter?: any,
    filters?: Filters<ProjectViewResponse>,
    currentPage?: number,
    pageSize?: number}) =>
  {
    API.getProjectViews(
      params?.filters?.find(f => f.id === 'type')?.value,
      params?.globalFilter,
      params?.currentPage!!*params?.pageSize!!,
      params?.pageSize!!
    ).then((response) => {
      if (response.data.isSuccessful) {
        setProjects(response.data.data);
        setTotalProjects(Number(response.headers['x-total-count']))
        setMaxExistingRefNumber(Number(response.headers['x-max-our-ref']))
        setFormSubmitted(false)
      }
      setIsLoading(false);
    });
  };

  const handleCreateProjectClick = () => setCreateEditProject({
    id: '',
    type: '',
    ourRef: '',
    customerId: null,
    customerName: null,
    customerPersonId: null,
    customerPersonName: null,
    etd: null,
    status: null
  });

  const [createEditProject, setCreateEditProject] = useState<ProjectViewResponse | null>(null);
  const handleEditProjectClick = (project: ProjectViewResponse) => setCreateEditProject(project);
  const handleDeleteProjectClick = async (id: string) => {

    const deleteResponse = await API.deleteProject(id);

    if (deleteResponse.isSuccessful) {
      enqueueSnackbar('Project successfully deleted', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Oops, something went wrong. Please try again later.', {
        variant: 'error',
      });
    }

    handleSubmittedForm()
  };

  const handleSubmittedForm = () => {
    setFormSubmitted(true)
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <ProjectsViewTable
          projects={projects}
          onSearchFieldChange={reloadProjects}
          pageCount={totalProjects}
          onCreateClick={handleCreateProjectClick}
          onEditClick={handleEditProjectClick}
          onDeleteClick={handleDeleteProjectClick}
          formSubmitted={formSubmitted}
        />
        )}
      <CreateEditProjectDialog
        open={Boolean(createEditProject)}
        project={createEditProject}
        onSubmit={handleSubmittedForm}
        onClose={() => setCreateEditProject(null)}
        newOurRefValue={maxExistingRefNumber+1}
      />
    </>
    );
}

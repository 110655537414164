import {makeStyles} from "@material-ui/core";

export const useButtonGroupStyles = makeStyles((theme) => ({
  toggle: {
    margin: theme.spacing(0, 3, 0),
  },
  button: {
    fontSize: '14px',
    lineHeight: '24px',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.hover,
      borderColor: theme.palette.primary.light,
    },
  }
}));

import React from "react";
import {Box} from "@material-ui/core";
import {ProjectDetailsForm} from "../types";
import {useFormikContext} from "formik";
import LoadingSpinner from "../../components/LoadingSpinner";
import {useSnackbar} from "notistack";
import {Variable} from "../../../API/types";
import Price from "../../components/Formik/Price";
import {useFormStyles} from "../../../styles/formStyles";
import DatePicker from "../../components/Formik/DatePicker";
import UpdatedFieldTooltip from "../../components/Formik/UpdatedFieldTooltip";
import TextField from "../../components/Formik/TextField";
import {hasTextFieldChanged, hasNumericTextFieldChanged, hasDateFieldChanged} from "../../../utils/formUtils";
import NumberTextField from "../../components/Formik/NumberTextField";


interface PurchaseTabProps {
  variables: Variable[];
}

export default function PurchaseTab(props: PurchaseTabProps) {
  const classes = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const form = useFormikContext<ProjectDetailsForm>();
  const { variables } = props;

  const currencyVariable = variables.find(variable => variable.name === 'Currency')

  if (currencyVariable === undefined) {
    enqueueSnackbar(
      'There has been a problem loading the Currency variable. Contact your admin if the issue persists.',
      {variant: 'error'}
    );
  }

  return (currencyVariable ?
    <Box className={classes.formBlockContainer}>
      <Box className={classes.formRowContainer}>
        <Box className={classes.formFieldContainer}>
          <DatePicker formik={form} name="supplierPoDate" label="Supplier PO date" />
        </Box>
        <Box className={classes.formFieldStatusContainer}>
          {hasDateFieldChanged(form.initialValues.supplierPoDate, form.values.supplierPoDate) && <UpdatedFieldTooltip />}
        </Box>
      </Box>
      <Price formik={form} name="orderedValue" label="Ordered value" currencyVariable={currencyVariable} />
      <Box className={classes.multipleInputFormRowContainer}>
        <Price formik={form} name="suppliersBillTotal" label="Supplier bill total" currencyVariable={currencyVariable} />
        <Box className={classes.formRowContainer}>
          <Box className={classes.formFieldContainer}>
            <TextField formik={form} name="supplierInvRef" label="Supplier INV REF" className={classes.formField} />
          </Box>
          <Box className={classes.formFieldStatusContainer}>
            {hasTextFieldChanged(form.initialValues.supplierInvRef, form.values.supplierInvRef) && <UpdatedFieldTooltip />}
          </Box>
        </Box>
      </Box>
      <Box className={classes.multipleInputFormRowContainer}>
        <Price formik={form} name="packagePrice" label="Package price" currencyVariable={currencyVariable} />
        <Box className={classes.formRowContainer}>
          <NumberTextField formik={form} name="packagesQuantity" label="Packages quantity" type="integer" />
          <Box className={classes.formFieldStatusContainer}>
            {hasTextFieldChanged(form.initialValues.packagesQuantity, form.values.packagesQuantity) && <UpdatedFieldTooltip />}
          </Box>
        </Box>
      </Box>
      <Price formik={form} name="otherCost" label="Other" currencyVariable={currencyVariable} />
      <Price formik={form} name="faf" label="FAF" currencyVariable={currencyVariable} />
      <Price formik={form} name="ebs" label="EBS" currencyVariable={currencyVariable} />
      <Price formik={form} name="plt" label="PLT" currencyVariable={currencyVariable} />
      <Price formik={form} name="otherVariableCharges" label="Variable charges" currencyVariable={currencyVariable} />
      <Box className={classes.formRowContainer}>
        <Box className={classes.formFieldContainer}>
          <TextField formik={form} name="articleNumber" label="Article" className={classes.formField} />
        </Box>
        <Box className={classes.formFieldStatusContainer}>
          {hasTextFieldChanged(form.initialValues.articleNumber, form.values.articleNumber) && <UpdatedFieldTooltip />}
        </Box>
      </Box>
      <Box className={classes.formRowContainer}>
        <Box className={classes.formFieldContainer}>
          <NumberTextField formik={form} name="packageSize" label="Size of the package" type="size" />
        </Box>
        <Box className={classes.formFieldStatusContainer}>
          {hasNumericTextFieldChanged(form.initialValues.packageSize, form.values.packageSize) && <UpdatedFieldTooltip />}
        </Box>
      </Box>
    </Box>
    :
    <LoadingSpinner />
  );
}
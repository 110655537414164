export interface LoginForm {
  email: string;
  password: string;
}

export interface SignupForm {
  email: string;
  password: string;
}

export enum SignUpErrors {
  // eslint-disable-next-line no-unused-vars
  USER_ALREADY_EXISTS = 'user_already_exists',
  INSUFFICIENT_PASSWORD_COMPLEXITY = 'INSUFFICIENT_PASSWORD_COMPLEXITY',

}

export enum LoginErrors {
  // eslint-disable-next-line no-unused-vars
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
}
import React, {useContext} from 'react';
import * as yup from 'yup';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useSnackbar} from 'notistack';
import {UserContext} from '../../../contexts/UserContext';
import {useFormik} from 'formik';
import {LoginErrors, LoginForm} from '../types';
import API from '../../../API';
import jwtDecode from "jwt-decode";
import {UserPrincipal} from "../../Dashboard/types";
import {User} from "../../../API/types";
import {stringToEnumPermissionMap, stringToEnumRoleMap} from "../../../utils/Authorization";

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const { setUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<LoginForm>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: LoginForm) => {
      const loginResponse = await API.login(values.email, values.password);

      if (loginResponse.isSuccessful) {
        localStorage.setItem('token', loginResponse.data.token);
        const principal = jwtDecode(loginResponse.data.token) as UserPrincipal
        const user = {
          id: principal.id,
          firstName: principal.firstName,
          lastName: principal.lastName,
          email: principal.email,
          roles: principal.roles.map(role => stringToEnumRoleMap[role]),
          permissions: principal.permissions.map(permission => stringToEnumPermissionMap[permission])
        } as User

        setUser(user)
        enqueueSnackbar('Successfully logged in', {
          variant: 'success',
        });
      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';
        if (loginResponse.code === LoginErrors.USER_NOT_FOUND) {
          errorMessage = 'Invalid username.';
        }
        if (loginResponse.code === LoginErrors.INVALID_CREDENTIALS) {
          errorMessage = 'Invalid password.';
        }
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form
          className={classes.form}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={formik.isSubmitting}
            className={classes.submit}
          >
            Sign In
          </Button>
          {/* <Grid container>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                onClick={() => history.push(routes.SIGN_UP)}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
    </Container>
  );
}

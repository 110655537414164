import {ContactsResponse, CreateContactForm} from "../../../API/types";
import {Button, makeStyles, TextField as MuiTextField} from "@material-ui/core";
import {useSnackbar} from "notistack";
import * as yup from "yup";
import {FormikHelpers, useFormik} from "formik";
import API from "../../../API";
import React, {Fragment} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "../../components/Formik/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ContactErrors} from "../types";
import PersonForm from "./PersonForm";
import SelectCountry from "../../components/Formik/SelectCountry";
import {resolvePath} from "../../../utils/formUtils";
import {ArrowBack} from "@material-ui/icons";

export interface CreateFormProps {
  contact?: ContactsResponse;
  actionLabel?: string;
  onSubmitClick: () => void;
  onCloseClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '5px',
  },
  input: {
    minWidth: '240px',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: '5px',
  },
  submit: {
    marginLeft: '20px'
  },
}));

export default function CreateForm(props: CreateFormProps) {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {contact, onSubmitClick, onCloseClick, actionLabel} = props;
  const priorityCountries = ['Japan', 'China']; // to decide which countries will be at the top of the list
  const types = ['Customer']; // should be replaced by object with all possible types of contacts, temp solution

  const validationSchema = yup.object({
    id: yup.number().nullable(),
    name: yup.string().max(255).required('Name is required'),
    type: yup.string().required('Type is required').uppercase().oneOf(['CUSTOMER']), // same
    code: yup.string().max(255).nullable(),
    address: yup.string().max(255).nullable(),
    country: yup.string().max(2).nullable(),
    persons: yup.array(
      yup.object({
        name: yup.string().max(255).required('Name is required'),
        email: yup.string().max(255).nullable(),
        phoneNumber: yup.string().max(255).nullable(),
      })
    ),
  });

  const formik = useFormik<CreateContactForm>({
    initialValues: {
      name: contact?.name ?? '',
      type: contact?.type ?? '',
      code: contact?.code ?? null,
      address: contact?.address ?? null,
      country: contact?.country ?? null,
      persons: contact?.persons ?? []
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: CreateContactForm,
      formikHelpers: FormikHelpers<CreateContactForm>
    ) => {
      let response;

      if (contact && contact.id.length > 0) {
        response = await API.editContact(contact.id, form);
      } else {
        response = await API.createContact(form);
      }

      if (response.isSuccessful) {
        const action = contact && contact.id.length > 0 ? 'updated' : 'created';
        enqueueSnackbar(`Contact successfully ${action}`, {
          variant: 'success',
        });
        formikHelpers.resetForm();
        onSubmitClick();
      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';

        if (response.code === ContactErrors.CONTACT_NOT_FOUND) {
          errorMessage = response.message;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
    },
  });

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
        <Grid container spacing={3}>
          {contact?.id === '' && (
            <Grid item container>
              <Grid item xs={5}>
                <Autocomplete
                  fullWidth
                  autoComplete
                  autoSelect
                  selectOnFocus
                  handleHomeEndKeys
                  disableClearable
                  disablePortal
                  options={types}
                  onChange={(event, value) => {
                    formik.setFieldValue("type", value?.toUpperCase());
                  }}
                  renderInput={(params) =>
                    <MuiTextField
                      error={!!resolvePath(formik.touched, 'type') && !!resolvePath(formik.errors, 'type')}
                      helperText={resolvePath(formik.touched, 'type') && resolvePath(formik.errors, 'type')}
                      name="type"
                      label="Type"
                      {...params}
                    />}
                />
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={3}>
            <Grid item container>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  formik={formik}
                  name="code"
                  label="Code"
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  formik={formik}
                  name="name"
                  label="Name"
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  formik={formik}
                  name="address"
                  label="Address"
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={5}>
                <SelectCountry formik={formik} priorityOptions={priorityCountries}></SelectCountry>
              </Grid>
            </Grid>
            <PersonForm formik={formik}></PersonForm>
          </Grid>
        </Grid>
        <div className={classes.buttonGroup}>
          <Button variant="text" color="primary" onClick={onCloseClick} startIcon={<ArrowBack/>}>
            Cancel
          </Button>
          <Button className={classes.submit} type="submit" variant="contained" color="primary">
            {actionLabel}
          </Button>
        </div>
      </form>
    </Fragment>
  );
}
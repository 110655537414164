import * as yup from "yup";
import {useFormik} from "formik";
import {EditProfileForm, User} from "../../../API/types";
import API from "../../../API";
import {ProfileEditErrors} from "../types";
import TextField from "../../components/Formik/TextField";
import {
  default as MuiTextField
} from '@material-ui/core/TextField/TextField';
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import React, {Fragment} from "react";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '30px',
    marginLeft: '5px'
  },
  form: {
    padding: '5px',
  },
  input: {
    minWidth: '240px',
  },
}));

export default function UpdateForm(props: { user: User, onSubmit: () => void }) {
  const classes = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const validationSchema = yup.object({
    firstName: yup.string().max(255).required('First name is required'),
    lastName: yup.string().max(255).required('Last name is required'),
  });

  const formik = useFormik<EditProfileForm>({
    initialValues: {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
    },
    validationSchema: validationSchema,
    onSubmit: async (
      form: EditProfileForm
    ) => {
      const response = await API.editUser(props.user.id, form);

      if (response.isSuccessful) {
        enqueueSnackbar('Profile successfully updated', {
          variant: 'success',
        });

      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';

        if (response.code === ProfileEditErrors.USER_NOT_FOUND) {
          errorMessage = response.message;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }

      props.onSubmit();
    },
  });

  return (
    <Fragment>
      <Typography variant="h5" className={classes.title}>Basic info</Typography>
      <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={3}>
            <Grid item>
              <MuiTextField
                className={classes.input}
                fullWidth
                name="email"
                label="Email"
                value={props.user.email}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item>
              <TextField
                className={classes.input}
                formik={formik}
                name="firstName"
                label="First name"
              />
            </Grid>
            <Grid item>
              <TextField
                className={classes.input}
                formik={formik}
                name="lastName"
                label="Last name"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Button type="submit" variant="outlined" color="primary">
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

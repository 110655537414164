import {TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField/TextField';
import React from 'react';
import {Box, makeStyles} from "@material-ui/core";
import CurrencySelect from "./CurrencySelect";
import NumberTextField from "./NumberTextField";
import UpdatedFieldTooltip from "./UpdatedFieldTooltip";
import {Variable} from "../../../API/types";
import {useFormStyles} from "../../../styles/formStyles";
import {DEFAULT_CURRENCY, hasNumericTextFieldChanged, resolvePath} from "../../../utils/formUtils";


const useStyles = makeStyles((theme) => ({
  exchangeRateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
    minWidth: '100px',
    maxWidth: '100px',
  },
}));

export type PriceProps = MuiTextFieldProps & {
  formik: any;
  name: string;
  label: string;
  currencyVariable: Variable;
};

export default function Price(props: PriceProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const { formik, name, label, currencyVariable } = props;
  const isBaseCurrency = resolvePath(formik.values, `${name}.currency`) === DEFAULT_CURRENCY;

  const hasPriceChanged = () => {
    return (
      hasNumericTextFieldChanged(resolvePath(formik.initialValues, name + '.amount'), resolvePath(formik.values, name + '.amount'))
      || (resolvePath(formik.initialValues, name + '.currency') || DEFAULT_CURRENCY) !== resolvePath(formik.values, name + '.currency')
      || hasNumericTextFieldChanged(resolvePath(formik.initialValues, name + '.exchangeRate'), resolvePath(formik.values, name + '.exchangeRate'))
    );
  };

  return (
    <Box className={formClasses.multipleInputFormRowContainer}>
      <Box className={formClasses.formRowContainer}>
        <CurrencySelect formik={formik} name={name} currencies={currencyVariable.options} />
        <NumberTextField formik={formik} name={name} label={label} type="priceAmount" extras={isBaseCurrency ? undefined : 'constantShrink'} />
        {!isBaseCurrency &&
          <Box className={classes.exchangeRateContainer}>
            <NumberTextField formik={formik} name={name} label="Exchange rate" type="priceExchangeRate" extras={isBaseCurrency ? undefined : 'constantShrink'} />
          </Box>
        }
        <Box className={formClasses.formFieldStatusContainer}>
          {hasPriceChanged() && <UpdatedFieldTooltip />}
        </Box>
      </Box>
    </Box>
  );
}

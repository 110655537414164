import {Permission, ProjectViewResponse} from "../../../API/types";
import {FilterValue, useFilters, useGlobalFilter, usePagination, useTable, UseTableRowProps} from "react-table";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Link as MuiLink,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import {Add} from "@material-ui/icons";
import Visible from "../../components/Visible";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchFilter from "./SearchFilter";
import dayjs from "dayjs";
import TablePaginationActions from "./TablePaginationActions";
import routes from "../../../router/routes";
import {MenuAnchor} from "../../types";
import {useTableStyles} from "../../../styles/tableStyles";
import {toolbarStyles} from "../../../styles/toolbarStyles";
import {Link} from "react-router-dom";


export interface ProjectsTableProps {
  projects: ProjectViewResponse[];
  onSearchFieldChange: (filters?: any, globalFilter?: any, currentPage?: number, pageSize?: number) => any;
  pageCount: number;
  onCreateClick: () => void;
  onEditClick: (project: ProjectViewResponse) => void;
  onDeleteClick: (id: string) => void;
  formSubmitted: boolean;
}

const columns: {
  Header: string;
  Cell?: ({
            row,
          }: {
    row: UseTableRowProps<ProjectViewResponse>;
  }) => any;
  accessor: keyof ProjectViewResponse;
}[] = [
  {accessor: 'ourRef', Header: 'Our ref'},
  {accessor: 'customerName', Header: 'Customer'},
  {accessor: 'type', Header: 'Type'},
  {accessor: 'etd', Header: 'ETD', Cell: ({row}) => (renderDateCell(row.original.etd))},
  {accessor: 'status', Header: 'Status', Cell: ({row}) => (renderStatus(row.original.status))},
];
const renderDateCell = (date: Date | null) => {
  return date ? dayjs(date).format('DD.MM.YYYY') : '';
}
const renderStatus = (status: string | null) => {
  const classes = useStyles();

  const formattedStatus = status != null ? status?.charAt(0) + status?.substring(1)?.toLowerCase() : ''

  switch (status) {
    case 'NEW':
      return <Chip label={formattedStatus} className={classes.chipNew} />
    case 'CONFIRMED':
      return <Chip label={formattedStatus} color="primary" />
    case 'COMPLETED':
      return <Chip label={formattedStatus} className={classes.chipCompleted} />
    default:
      return <></>
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 0)
  },
  createButton: {
    float: 'right'
  },
  chipNew: {
    backgroundColor: 'orange',
    color: 'white'
  },
  chipCompleted: {
    backgroundColor: 'green',
    color: 'white'
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
}));

export default function ProjectsViewTable(props: ProjectsTableProps) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const toolbarClasses = toolbarStyles();

  const [menuAnchor, setMenuAnchor] = useState<MenuAnchor>({id: null, anchorEl: null});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: {pageSize, filters, globalFilter},
    setPageSize,
    setFilter,
    setGlobalFilter
  } =
    useTable({
        columns,
        data: props.projects,
        manualFilters: true,
        manualGlobalFilter: true,
        manualPagination: true,
        initialState: { hiddenColumns: ['type'], pageSize: 10 },
      },
      useFilters,
      useGlobalFilter,
      usePagination
    );

  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // workaround for initial double request

  useEffect(() => {
    if (isLoading) props.onSearchFieldChange({globalFilter, filters, currentPage, pageSize})
    setIsLoading(true)
  }, [globalFilter, filters, currentPage, pageSize, props.formSubmitted])

  const handleOpenMenu =
    (project: ProjectViewResponse) => (event:  React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor({id: project.id, anchorEl: event.currentTarget});
    };

  const handleCloseMenu = () => {
    setMenuAnchor({id: null, anchorEl: null});
  };

  const handleChangePage = (page: any) => {
    setCurrentPage(page)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setCurrentPage(0)
    setPageSize(Number(event.target.value))
  }

  const handleSetFilter = (columnId: string, updater: ((filterValue: FilterValue) => FilterValue) | FilterValue) => {
    setCurrentPage(0)
    setFilter(columnId, updater)
  }

  const handleSetGlobalFilter = (filterValue?: string) => {
    setCurrentPage(0)
    setGlobalFilter(filterValue)
  }

  const handleEditClick = (project: ProjectViewResponse) => {
    handleCloseMenu();
    props.onEditClick(project);
  };

  const handleDeleteClick = (project: ProjectViewResponse) => {
    handleCloseMenu();
    if (window.confirm('Are you sure you want to delete ' + project.ourRef + '?')) {
      props.onDeleteClick(project.id);
      if (props.pageCount % pageSize === 1) if (currentPage > 0) setCurrentPage(currentPage-1)
    }
  };

  const actionMenuItems = [
    {name: 'Edit', action: handleEditClick, Icon: EditIcon, permission: Permission.UpdateProjects},
    {name: 'Delete', action: handleDeleteClick, Icon: DeleteIcon, permission: Permission.UpdateProjects},
  ];

  return (
    <Paper className={classes.paper}>
      <Box className={toolbarClasses.container}>
        <Box style={{display: 'flex', flexDirection: 'row'}}>
          <Typography variant="h5" className={toolbarClasses.title}>
            Projects
          </Typography>
          <SearchFilter
            globalFilter={globalFilter}
            setGlobalFilter={handleSetGlobalFilter}
            filters={filters}
            setFilter={handleSetFilter}
          />
        </Box>
        <Visible permission={Permission.UpdateProjects}>
          <Grid item xs={4}>
            <Button variant="contained"
                    startIcon={<Add/>}
                    color="primary"
                    onClick={props.onCreateClick}
                    className={classes.createButton}>
              Add project
            </Button>
          </Grid>
        </Visible>
      </Box>
      <TableContainer>
        <Table stickyHeader size="small" padding="none" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index+headerGroup.id}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    className={tableClasses.headerCell}
                    {...column.getHeaderProps()}
                    key={index+column.id}
                  >
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Typography variant="body1" className={index === 0 ? tableClasses.headerWithoutDivider : tableClasses.header}>
                        {column.render('Header')}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
                <TableCell className={tableClasses.headerCell} width={52}/>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.original.id+index}>
                  <TableRow
                    {...row.getRowProps()}
                    className={tableClasses.row}
                  >
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()} key={index+cell.value+row.id+cell.column.id} className={tableClasses.cell}>
                        {cell.column.id === 'ourRef' ?
                          <Link
                            className={classes.link}
                            to={routes.PROJECTS.ROOT + '/' + row.original.id}
                          >
                            <MuiLink variant="body1" component={'div'}>{cell.render('Cell')}</MuiLink>
                          </Link>
                          :
                          <Typography variant="body1" component={'span'}>{cell.render('Cell')}</Typography>
                        }
                      </TableCell>
                    ))}
                    <TableCell key={'menu' + index}>
                      <Visible anyPermissions={[Permission.UpdateProjects, Permission.ReadProjects]}>
                        <IconButton
                          size="small"
                          onClick={handleOpenMenu(row.original)}
                        >
                          <MoreVertIcon/>
                        </IconButton>
                        <Menu
                          anchorEl={
                            menuAnchor.id === row.original.id
                              ? menuAnchor.anchorEl
                              : null
                          }
                          getContentAnchorEl={null}
                          keepMounted={false}
                          open={menuAnchor.id === row.original.id}
                          onClose={handleCloseMenu}
                        >
                          {
                            actionMenuItems.map((item) => {
                              const Icon = item.Icon;

                              return (
                                <Visible key={item.name} permission={item.permission}>
                                  <MenuItem onClick={() => item.action(row.original)}>
                                    <ListItemIcon>
                                      <Icon fontSize="small"/>
                                    </ListItemIcon>
                                    <ListItemText primary={item.name}/>
                                  </MenuItem>
                                </Visible>
                              );
                            })}
                        </Menu>
                      </Visible>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5, 10, 25,
                ]}
                count={props.pageCount}
                rowsPerPage={pageSize}
                page={currentPage}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}
import React, {Fragment} from 'react';
import * as yup from 'yup';
import Grid from '@material-ui/core/Grid';
import {FieldArray, FormikProvider, useFormik} from 'formik';
import {Button, IconButton, InputAdornment, makeStyles, TextField as MuiTextField, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '../../components/Formik/TextField';
import {ContactsResponse, Loading, ProjectWeekResponse} from '../../../API/types';
import {InitialFormLoading, ProjectForm} from '../types';
import {getCurrentPeriod, getWeekString} from "../../../utils/weekUtils";
import Checkbox from '../../components/Formik/Checkbox';
import DatePicker from '../../components/Formik/DatePicker';
import {GridAddIcon} from "@material-ui/data-grid";
import {ArrowBack, Clear} from "@material-ui/icons";
import {strToDate} from "../../../utils/formUtils";
import {validationSchema} from "../../../utils/formValidationSchema";
import NumberTextField from "../../components/Formik/NumberTextField";


const projectValidationSchema = yup.object({
  customerRef: validationSchema.fields.mediumTextField,
  customerName: validationSchema.fields.mediumTextField,
  pod: validationSchema.fields.mediumTextField,
  ourRef: yup.number().required('Reference number is required'),
  ourRefPart: yup.number().typeError('Reference must be a number').max(6).required('Reference number is required'),
  week: yup.string().required().max(3),
  year: yup.string().required().min(4).max(4),
  qty: validationSchema.fields.mediumTextField,
  vessel: validationSchema.fields.mediumTextField,
  gate: validationSchema.fields.mediumTextField,
  product: validationSchema.fields.mediumTextField,
  loadings: yup.array(yup.object({time: validationSchema.fields.dateField, qty: validationSchema.fields.integer})),
  closingDoc: validationSchema.fields.dateField,
  etd: validationSchema.fields.dateField,
  eta: validationSchema.fields.dateField,
});

const useStyles = makeStyles((theme) => ({
  form: {
    padding: '5px',
  },
  buttonGroup: {
    marginTop: theme.spacing(2),
    marginBottom: '5px',
  },
  submit: {
    marginLeft: '20px'
  },
}));

export interface CreateProjectFormProps {
  onSubmit: (form: ProjectForm) => Promise<void>;
  clients: ContactsResponse[];
  actionLabel?: string;
  project?: ProjectWeekResponse;
  onCloseClick: () => void;
  newOurRefValue: number
}

export default function CreateProjectForm(props: CreateProjectFormProps) {
  const classes = useStyles();
  const { project, onCloseClick, newOurRefValue, actionLabel = 'Submit' } = props;

  const handleFormSubmit = (project: ProjectForm) => {
    props.onSubmit(project);
  }

  const getInitialFormLoadings = (loadings: Loading[]): InitialFormLoading[] => {
    const formLoadings: InitialFormLoading[] = [];
    loadings.forEach(loading => {
      formLoadings.push({
        sequence: loading.sequence,
        time: strToDate(loading.time),
        qty: loading.qty ? loading.qty.toString() : null,
      })
    })
    return formLoadings;
  }

  const formik = useFormik<ProjectForm>({
    initialValues: {
      id: project?.id ?? null,
      customerRef: project?.customerRef ?? null,
      customerId: project?.customerId ?? null,
      customerName: project?.customerName ?? null,
      pod: project?.pod ?? null,
      ourRef: newOurRefValue,
      ourRefPart: '00',
      week: project?.week ?? getWeekString(getCurrentPeriod().isoWeek()),
      year: project?.year ?? getCurrentPeriod().year().toString(),
      qty: project?.qty ?? null,
      vessel: project?.vessel ?? null,
      gate: project?.gate ?? null,
      product: project?.product ?? null,
      closingDoc: project?.closingDoc ?? null,
      etd: project?.etd ?? null,
      eta: project?.eta ?? null,
      vgm: project?.vgm ?? false,
      m3: project?.m3 ?? null,
      colors: project?.colors ?? [],
      loadings: project && project.loadings.length > 0 ?
        getInitialFormLoadings(project.loadings) : [{sequence: 1, time: null, qty: null}],
    },
    validationSchema: projectValidationSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <Fragment>
      <form onSubmit={formik.handleSubmit} noValidate className={classes.form}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="customerRef"
              label="Customer ref"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
                clearOnEscape
                disablePortal
                autoComplete
                autoSelect
                handleHomeEndKeys
                options={props.clients.map((client) => client.name)}
                onChange={(event, value: string | null) => {
                  formik.setFieldValue("customerId",
                    value !== null ? props.clients.find(client => client.name === value)?.id : null);
                  formik.setFieldValue("customerName",
                    value !== null ? props.clients.find(client => client.name === value)?.name : null);
                }}
                value={formik.values.customerId ? formik.values.customerName : null}
                renderInput={(params) =>
                    <MuiTextField
                        name="customerName"
                        label="Customer"
                        {...params}
                    />}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} name="pod" label="POD" fullWidth />
          </Grid>
          {!project?.id && (
            <>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  formik={formik}
                  type="number"
                  name="ourRef"
                  label="OUR ref"
                  InputProps={{
                    readOnly: !!project?.id,
                  }}
                />
              </Grid>
              <Grid item xs={2} container>
                <TextField
                  fullWidth
                  formik={formik}
                  label=" "
                  name="ourRefPart"
                  InputProps={{
                    readOnly: !!project?.id,
                    startAdornment:
                      <InputAdornment disableTypography position="start">
                        <Typography variant='h5'>.</Typography>
                      </InputAdornment>,
                  }}
                />
              </Grid>
            </>
          )
          }
          <Grid item xs={4}>
            <TextField name="week" label="Week" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField name="year" label="Year" formik={formik} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} type="number" name="qty" label="QTY" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="vessel"
              label="Vessel/booking"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} name="gate" label="Gate" fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextField
              formik={formik}
              name="product"
              label="Product"
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormikProvider value={formik}>
              <FieldArray
                  name="loadings"
                  render={({ remove, push }) => (
                      <Grid container spacing={2}>
                        {formik.values.loadings.map((loadingTime, index) => (
                            <Grid item xs={10} key={index}>
                              <Grid container spacing={2}>
                              <Grid item xs={formik.values.loadings.length > 1 ? 7 : 12}>
                                <DatePicker formik={formik} name={`loadings[${index}].time`} label="Loading" />
                              </Grid>
                                {formik.values.loadings.length > 1 && (
                                    <>
                                      <Grid item xs={4}>
                                        <NumberTextField formik={formik} name={`loadings[${index}].qty`} label="QTY" type="integer" />
                                      </Grid>
                                      <Grid item xs={1}>
                                        <IconButton onClick={() => remove(index)}>
                                          <Clear />
                                        </IconButton>
                                      </Grid>
                                    </>
                                )}
                              </Grid>
                            </Grid>
                          ))}
                        <Grid item xs={formik.values.loadings.length > 1 ? 12 : 2}>
                          <IconButton onClick={() => push({
                            ...formik.values.loadings[0],
                            sequence: formik.values.loadings[formik.values.loadings.length - 1].sequence + 1
                          })}>
                            <GridAddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                  )}
           />
          </FormikProvider>
          </Grid>
          <Grid item xs={4}>
            <DatePicker formik={formik} name="closingDoc" label="Closing doc" />
          </Grid>
          <Grid item xs={4}>
            <DatePicker formik={formik} name="etd" label="ETD" />
          </Grid>
          <Grid item xs={4}>
            <DatePicker formik={formik} name="eta" label="ETA" />
          </Grid>
          <Grid item xs={4}>
            <Checkbox formik={formik} name="vgm" label="VGM" />
          </Grid>
          <Grid item xs={4}>
            <TextField formik={formik} type="number" name="m3" label="M3" fullWidth />
          </Grid>
        </Grid>
        <div className={classes.buttonGroup}>
          <Button variant="text" color="primary" onClick={onCloseClick} startIcon={<ArrowBack/>}>
            Cancel
          </Button>
          <Button className={classes.submit} type="submit" variant="contained" color="primary">
            {actionLabel}
          </Button>
        </div>
      </form>
    </Fragment>
  );
}

import {
  default as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField/TextField';
import React from 'react';
import {resolvePath} from "../../../utils/formUtils";

export type TextFieldProps = MuiTextFieldProps & {
  formik: any;
  name: string;
};

export default function TextField(props: TextFieldProps) {
  const { formik, name } = props;

  return (
    <MuiTextField
      {...props}
      name={props.name}
      value={resolvePath(formik.values, name) || ''}
      onChange={formik.handleChange}
      onBlur={() => formik.setFieldTouched(name, true)}
      error={!!resolvePath(formik.touched, name) && !!resolvePath(formik.errors, name)}
      helperText={resolvePath(formik.touched, name) && resolvePath(formik.errors, name)}
    />
  );
}

import {default as MuiTextField, TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField/TextField';
import React from 'react';
import {hasNumericTextFieldChanged, resolvePath} from "../../../utils/formUtils";
import {useFormStyles} from "../../../styles/formStyles";
import {Box, InputAdornment, makeStyles} from "@material-ui/core";
import UpdatedFieldTooltip from "./UpdatedFieldTooltip";


const useStyles = makeStyles((theme) => ({
  percentageInputContainer: {
    width: '90px'
  }
}));

export type PercentTextFieldProps = MuiTextFieldProps & {
  formik: any;
  name: string;
};

export default function PercentTextField(props: PercentTextFieldProps) {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const { formik, name } = props;
  const regex = /^(100([,.]0{0,2})?)$|^([1-9]?[0-9]?([,.]\d{0,2})?)$/g;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value === '') formik.setFieldValue(name, null);
    if (regex.test(event.target.value)) formik.setFieldValue(name, event.target.value.replace('.', ','));
  };

  return (
    <Box className={formClasses.formRowContainer}>
      <Box className={formClasses.formFieldContainer}>
        <MuiTextField
          {...props}
          name={props.name}
          type="textfield"
          value={resolvePath(formik.values, name) || ''}
          onChange={(e) => handleChange(e)}
          onBlur={() => formik.setFieldTouched(name, true)}
          error={!!resolvePath(formik.touched, name) && !!resolvePath(formik.errors, name)}
          helperText={resolvePath(formik.touched, name) && resolvePath(formik.errors, name)}
          InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
          InputLabelProps={{shrink: true}}
          className={classes.percentageInputContainer}
        />
      </Box>
      <Box className={formClasses.formFieldStatusContainer}>
        {hasNumericTextFieldChanged(formik.initialValues[name], formik.values[name]) && <UpdatedFieldTooltip />}
      </Box>
    </Box>
  );
}

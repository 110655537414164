import LoadingSpinner from "../components/LoadingSpinner";
import React, {useEffect, useState} from "react";
import API from "../../API";
import {EditProjectDetailsRequest, Loading, ProjectDetailsResponse, Unit, Variable} from "../../API/types";
import {useHistory, useParams} from "react-router-dom";
import {Box, Button, makeStyles, Paper, Tab, Tabs, Typography} from "@material-ui/core";
import dayjs from "dayjs";
import routes from "../../router/routes";
import OrderDetailsTab from "./components/OrderDetailsTab";
import {Formik, FormikHelpers, FormikProps} from "formik";
import {FormLoading, FormUnit, ProjectDetailsForm} from "./types";
import * as yup from "yup";
import {useSnackbar} from "notistack";
import {Check, Clear} from "@material-ui/icons";
import FinanceTab from "./components/FinanceTab";
import {priceValidationSchema, validationSchema} from "../../utils/formValidationSchema";
import {
  getFormDateAndTime,
  getFormPrice,
  getPercent,
  getPrice,
  getProjectDateAndTime,
  nrToStr,
  nullableDateToStr,
  strToDate,
  strToDec,
  strToNr
} from "../../utils/formUtils";
import FormSubmitErrorNotification from "../components/Formik/FormSubmitErrorNotification";
import {isEqual} from 'lodash';
import PurchaseTab from "./components/PurchaseTab";
import HistoryTab from "./components/HistoryTab";
import FormObserver from "./components/FormObserver";
import LogisticsTab from "./components/LogisticsTab";


const getDefaultLoading = (sequence: number = 1): FormLoading => {
  return {
    sequence: sequence,
    time: null,
    qty: null,
    transportType: null,
    pol: null,
    pod: null,
    eta: null,
    etaRequested: null,
    ata: null,
    haulageBy: null,
    haulageReference: null,
    forwarder: null,
    contline: null,
    bookingReference: null,
    billOfLading: null,
    gateOpen: {date: null, time: null},
    polDate: null,
    cutOffContainersNos: {date: null, time: null},
    cutOffContainers: {date: null, time: null},
    estimatedUnitFrCost: getFormPrice(null),
    unitFrCostPaid: getFormPrice(null),
    vgm: null,
    cbm: null,
    units: []
  }
};

const orderDetailsValidationSchema = () => yup.object().shape({
  clientRefId: validationSchema.fields.shortTextField,
  clientPoDate: validationSchema.fields.dateField,
  poValue: priceValidationSchema,
  soldValue: priceValidationSchema,
  invoiceDate: validationSchema.fields.dateField,
  invoiceRef: validationSchema.fields.shortTextField,
  specialRequest: validationSchema.fields.longTextField,

  // finance
  doxFee: priceValidationSchema,
  unitHaulageCost: priceValidationSchema,
  customsCost: priceValidationSchema,
  packageCost: priceValidationSchema,
  haulageCost: priceValidationSchema,
  freightCost: priceValidationSchema,
  agentFeeCost: priceValidationSchema,
  ttlRevPlanned: priceValidationSchema,
  ttlRevImplemented: priceValidationSchema,
  cbmPrice: priceValidationSchema,
  unitPrice: priceValidationSchema,

  // purchase
  supplierPoDate: validationSchema.fields.dateField,
  orderedValue: priceValidationSchema,
  suppliersBillTotal: priceValidationSchema,
  supplierInvRef: validationSchema.fields.shortTextField,
  packagesQuantity: validationSchema.fields.integer,
  packagePrice: priceValidationSchema,
  otherCost: priceValidationSchema,
  faf: priceValidationSchema,
  ebs: priceValidationSchema,
  plt: priceValidationSchema,
  otherVariableCharges: priceValidationSchema,
  articleNumber: validationSchema.fields.number,
  packageSize: validationSchema.fields.number,

  // logistics
  qty: validationSchema.fields.integer,
  loadings: yup.array(yup.object({
    time: validationSchema.fields.dateField,
    qty: validationSchema.fields.integer,
    eta: validationSchema.fields.dateField,
    etaRequested: validationSchema.fields.dateField,
    ata: validationSchema.fields.dateField,
    haulageReference: validationSchema.fields.shortTextField,
    bookingReference: validationSchema.fields.shortTextField,
    billOfLading: validationSchema.fields.shortTextField,
    gateOpen: yup.object({date: validationSchema.fields.dateField}),
    polDate: validationSchema.fields.dateField,
    cutOffContainersNos: yup.object({date: validationSchema.fields.dateField}),
    cutOffContainers: yup.object({date: validationSchema.fields.dateField}),
    estimatedUnitFrCost: priceValidationSchema,
    unitFrCostPaid: priceValidationSchema,
    vgm: validationSchema.fields.number,
    cmb: validationSchema.fields.number,
    units: yup.array(yup.object({
      containerNumber: validationSchema.fields.shortTextField,
      vehicleNumber: validationSchema.fields.shortTextField,
      sealNumber: validationSchema.fields.shortTextField,
      cbm: validationSchema.fields.number,
    }))
  })),
})

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5, 3, 5),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    [theme.breakpoints.up('md')]: {padding: '5vh 3vw'}
  },
  headerBar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: '500px',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  subtext: {
    minWidth: '500px',
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  tab: {
    padding: 0,
    maxWidth: '110px',
    minWidth: '110px',
  },
  wideTab: {
    padding: 0,
    maxWidth: '140px',
    minWidth: '140px'
  },
  tabPanel: {
    paddingTop: theme.spacing(2),
    width: '100%',
    minWidth: '500px',
    overflowX: 'scroll'
  },
  buttonPadding: {
    marginRight: theme.spacing(1)
  }
}));

export default function ProjectDetails() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { projectId } = useParams<{ projectId: string }>();

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState<ProjectDetailsResponse | undefined>(undefined);
  const [tabValue, setTabValue] = React.useState(0);
  const [initialFormValues, setInitialFormValues] = useState<ProjectDetailsForm | undefined>(undefined);
  const [variables, setVariables] = useState<Variable[] | undefined>(undefined);

  useEffect(() => {
    loadProject();
    loadVariables();
  }, []);

  useEffect(() => {
    if (project) {
      loadInitialFormValuesFromProject(project);
    }
  }, [project]);

  const loadProject = () => {
    API.getProjectDetails(projectId).then((response) => {
      if (response.isSuccessful) {
        setProject(response.data);
      } else {
        history.push(routes.DASHBOARD);
        enqueueSnackbar('Project not found.', {variant: 'error'})
      }
    });
  };

  const loadVariables = () => {
    // TODO add ids criteria to request
    API.getVariables().then((response) => {
      if (response.isSuccessful) {
        setVariables(response.data);
        setIsLoading(false);
      }
    });
  };

  const getFormUnits = (loadingSequence: number, units: Unit[] | null = null): FormUnit[] => {
    if (units && units.length > 0) {
      const formUnits: FormUnit[] = [];
      units.forEach(unit =>
        formUnits.push({
          loadingSequence: loadingSequence,
          containerNumber: unit.containerNumber ?? null,
          vehicleNumber: unit.vehicleNumber ?? null,
          sealNumber: unit.sealNumber ?? null,
          vgm: !!unit.vgm,
          cbm: nrToStr(unit.cbm)
        })
      )
      return formUnits;
    } else {
      return [];
    }
  }

  const getProjectUnits = (formUnits: FormUnit[]): Unit[] | null => {
    if (formUnits.length > 0) {
      const units: Unit[] = [];
      formUnits.forEach(unit =>
        units.push({
          containerNumber: unit.containerNumber,
          vehicleNumber: unit.vehicleNumber,
          sealNumber: unit.sealNumber,
          vgm: unit.vgm,
          cbm: strToNr(unit.cbm)
        })
      )
      return units;
    } else {
      return null;
    }
  }

  const getFormLoadings = (loadings: Loading[]): FormLoading[] => {
    if (loadings.length > 0) {
      const formLoadings: FormLoading[] = [];
      loadings.sort((a, b) => a.sequence > b.sequence ? 1 : -1).forEach(loading => {
        formLoadings.push({
          sequence: loading.sequence,
          time: strToDate(loading.time),
          qty: loading.qty ? loading.qty.toString() : null,
          transportType: loading.transportType ?? null,
          pol: loading.pol ?? null,
          pod: loading.pod ?? null,
          eta: strToDate(loading.eta),
          etaRequested: strToDate(loading.etaRequested),
          ata: strToDate(loading.ata),
          haulageBy: loading.haulageBy ?? null,
          haulageReference: loading.haulageReference ?? null,
          forwarder: loading.forwarder ?? null,
          contline: loading.contline ?? null,
          bookingReference: loading.bookingReference ?? null,
          billOfLading: loading.billOfLading ?? null,
          gateOpen: getFormDateAndTime(loading.gateOpen),
          polDate: strToDate(loading.polDate),
          cutOffContainersNos: getFormDateAndTime(loading.cutOffContainersNos),
          cutOffContainers: getFormDateAndTime(loading.cutOffContainers),
          estimatedUnitFrCost: getFormPrice(loading.estimatedUnitFrCost),
          unitFrCostPaid: getFormPrice(loading.unitFrCostPaid),
          vgm: nrToStr(loading.vgm),
          cbm: nrToStr(loading.cbm),
          units: getFormUnits(loading.sequence, loading.units),
        })
      })
      return formLoadings;
    } else {
      return [getDefaultLoading()];
    }
  };

  const getProjectLoadings = (formLoadings: FormLoading[]): Loading[] => {
    const projectLoadings: Loading[] = [];
    formLoadings.forEach(loading => {
      if (loading.time) {
        projectLoadings.push({
          sequence: loading.sequence,
          time: nullableDateToStr(loading.time),
          qty: loading.qty ? Number(loading.qty) : null,
          transportType: loading.transportType,
          pol: loading.pol,
          pod: loading.pod,
          eta: nullableDateToStr(loading.eta),
          etaRequested: nullableDateToStr(loading.etaRequested),
          ata: nullableDateToStr(loading.ata),
          haulageBy: loading.haulageBy,
          haulageReference: loading.haulageReference,
          forwarder: loading.forwarder,
          contline: loading.contline,
          bookingReference: loading.bookingReference,
          billOfLading: loading.billOfLading,
          gateOpen: getProjectDateAndTime(loading.gateOpen),
          polDate: nullableDateToStr(loading.polDate),
          cutOffContainersNos: getProjectDateAndTime(loading.cutOffContainersNos),
          cutOffContainers: getProjectDateAndTime(loading.cutOffContainers),
          estimatedUnitFrCost: getPrice(loading.estimatedUnitFrCost),
          unitFrCostPaid: getPrice(loading.unitFrCostPaid),
          vgm: strToNr(loading.vgm),
          cbm: strToNr(loading.cbm),
          units: getProjectUnits(loading.units)
        })
      }
    })
    return projectLoadings;
  };

  const loadInitialFormValuesFromProject = (projectData: ProjectDetailsResponse) => {
    const newInitialFormValues: ProjectDetailsForm = {
      clientRefId: projectData.customerRef,
      clientPoDate: projectData.clientPoDate,
      poValue: getFormPrice(projectData.poValue),
      soldValue: getFormPrice(projectData.soldValue),
      invoiceDate: projectData.invoiceDate,
      invoiceRef: projectData.invoiceRef,
      specialRequest: projectData.specialRequest,
      suppliersPoSent: !!projectData.suppliersPoSentDate,
      suppliersPoSentDate: projectData.suppliersPoSentDate,
      tpoSent: !!projectData.tpoSentDate,
      tpoSentDate: projectData.tpoSentDate,
      bookingReceived: !!projectData.bookingReceivedDate,
      bookingReceivedDate: projectData.bookingReceivedDate,
      loaded: !!projectData.loadedDate,
      loadedDate: projectData.loadedDate,
      delivered: !!projectData.deliveredDate,
      deliveredDate: projectData.deliveredDate,
      cancelled: !!projectData.cancelledDate,
      cancelledDate: projectData.cancelledDate,
      doxFee: getFormPrice(projectData.doxFee),
      unitHaulageCost: getFormPrice(projectData.unitHaulageCost),
      customsCost: getFormPrice(projectData.customsCost),
      agentFeePercent: getPercent(projectData.agentFeePercent),
      packageCost: getFormPrice(projectData.packageCost),
      haulageCost: getFormPrice(projectData.haulageCost),
      freightCost: getFormPrice(projectData.freightCost),
      agentFeeCost: getFormPrice(projectData.agentFeeCost),
      ttlRevPlanned: getFormPrice(projectData.ttlRevPlanned),
      ttlRevImplemented: getFormPrice(projectData.ttlRevImplemented),
      revPercent: getPercent(projectData.revPercent),
      cbmPrice: getFormPrice(projectData.cbmPrice),
      unitPrice: getFormPrice(projectData.unitPrice),
      supplierPoDate: projectData.supplierPoDate,
      orderedValue: getFormPrice(projectData.orderedValue),
      suppliersBillTotal: getFormPrice(projectData.suppliersBillTotal),
      supplierInvRef: projectData.supplierInvRef,
      packagesQuantity: nrToStr(projectData.packagesQuantity),
      packagePrice: getFormPrice(projectData.packagePrice),
      otherCost: getFormPrice(projectData.otherCost),
      faf: getFormPrice(projectData.faf),
      ebs: getFormPrice(projectData.ebs),
      plt: getFormPrice(projectData.plt),
      otherVariableCharges: getFormPrice(projectData.otherVariableCharges),
      articleNumber: projectData.articleNumber,
      packageSize: nrToStr(projectData.packageSize),
      qty: nrToStr(projectData.qty),
      loadings: getFormLoadings(projectData.loadings)
    };
    if (!isEqual(initialFormValues, newInitialFormValues)) {
      setInitialFormValues(newInitialFormValues);
    }
  }

  const handleFormSubmit = (projectDetailsResponse: ProjectDetailsResponse, values: ProjectDetailsForm, formikHelpers: FormikHelpers<ProjectDetailsForm>) => {
    formikHelpers.setSubmitting(true);
    const request: EditProjectDetailsRequest = {
      customerRef: values.clientRefId,
      clientPoDate: nullableDateToStr(values.clientPoDate),
      poValue: getPrice(values.poValue),
      soldValue: getPrice(values.soldValue),
      invoiceDate: nullableDateToStr(values.invoiceDate),
      invoiceRef: values.invoiceRef,
      specialRequest: values.specialRequest,
      suppliersPoSentDate: values.suppliersPoSent ? nullableDateToStr(values.suppliersPoSentDate) : null,
      tpoSentDate: values.tpoSent ? nullableDateToStr(values.tpoSentDate) : null,
      bookingReceivedDate: values.bookingReceived ? nullableDateToStr(values.bookingReceivedDate) : null,
      loadedDate: values.loaded ? nullableDateToStr(values.loadedDate) : null,
      deliveredDate: values.delivered ? nullableDateToStr(values.deliveredDate) : null,
      cancelledDate: values.cancelled ? nullableDateToStr(values.cancelledDate) : null,
      doxFee: getPrice(values.doxFee),
      unitHaulageCost: getPrice(values.unitHaulageCost),
      customsCost: getPrice(values.customsCost),
      agentFeePercent: values.agentFeePercent ? strToDec(values.agentFeePercent) : null,
      packageCost: getPrice(values.packageCost),
      haulageCost: getPrice(values.haulageCost),
      freightCost: getPrice(values.freightCost),
      agentFeeCost: getPrice(values.agentFeeCost),
      ttlRevPlanned: getPrice(values.ttlRevPlanned),
      ttlRevImplemented: getPrice(values.ttlRevImplemented),
      revPercent: values.revPercent ? strToDec(values.revPercent) : null,
      cbmPrice: getPrice(values.cbmPrice),
      unitPrice: getPrice(values.unitPrice),
      supplierPoDate: nullableDateToStr(values.supplierPoDate),
      orderedValue: getPrice(values.orderedValue),
      suppliersBillTotal: getPrice(values.suppliersBillTotal),
      supplierInvRef: values.supplierInvRef,
      packagesQuantity: strToNr(values.packagesQuantity),
      packagePrice: getPrice(values.packagePrice),
      otherCost: getPrice(values.otherCost),
      faf: getPrice(values.faf),
      ebs: getPrice(values.ebs),
      plt: getPrice(values.plt),
      otherVariableCharges: getPrice(values.otherVariableCharges),
      articleNumber: values.articleNumber,
      packageSize: strToNr(values.packageSize),
      qty: strToNr(values.qty),
      loadings: getProjectLoadings(values.loadings)
    }

    API.editProjectDetails(projectDetailsResponse.id, request).then(response => {
        if (response.isSuccessful) {
          enqueueSnackbar('Order updated', {variant: 'success'});
          formikHelpers.setSubmitting(false);
          loadProject();
        } else {
          enqueueSnackbar('Problem saving changes', {variant: 'error'});
          formikHelpers.setSubmitting(false);
        }
      }
    )
  };

  const handleFormCancel = (formikProps: FormikProps<ProjectDetailsForm>) => {
    formikProps.resetForm();
  }

  function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
      <Box className={classes.tabPanel} role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Typography component="div">{children}</Typography>}
      </Box>
    );
  }

  return (isLoading || initialFormValues === undefined || variables === undefined) ? <LoadingSpinner /> : (project ?
      <Paper className={classes.paper}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={orderDetailsValidationSchema}
          onSubmit={(values, formikHelpers) => handleFormSubmit(project, values, formikHelpers)}
          enableReinitialize
        >
          {(formikProps: FormikProps<ProjectDetailsForm>) =>
            <>
              <FormObserver />
              <Box className={classes.headerBar}>
                <Box>
                  <Typography variant="h5">Order {project.ourRef}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.customerName}{project.customerRef ? ` (${project.customerRef})` : ''}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="outlined"
                          startIcon={<Clear />}
                          color="primary"
                          disabled={formikProps.isSubmitting || isEqual(formikProps.initialValues, formikProps.values)}
                          onClick={() => handleFormCancel(formikProps)}
                          className={classes.buttonPadding}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained"
                          type="submit"
                          startIcon={<Check />}
                          color="primary"
                          disabled={formikProps.isSubmitting || isEqual(formikProps.initialValues, formikProps.values)}
                          onClick={formikProps.submitForm}
                  >
                    Save changes
                  </Button>
                </Box>
              </Box>
              <Typography variant="body2" color="textSecondary" className={classes.subtext}>
                Issued on {dayjs(project.createdAt).format('D.MM.YYYY')} at {dayjs(project.createdAt).format('HH:mm')}, updated on {dayjs(project.updatedAt).format('D.MM.YYYY')} at {dayjs(project.updatedAt).format('HH:mm')}
              </Typography>
              <Tabs textColor="primary" indicatorColor="primary" variant="scrollable" scrollButtons="off"
                    value={tabValue} onChange={(e, value) => setTabValue(value)}
              >
                <Tab className={classes.wideTab} label="Order details" />
                <Tab className={classes.tab} label="Logistics" />
                <Tab className={classes.tab} label="Purchase" />
                <Tab className={classes.tab} label="Finance" />
                <Tab className={classes.tab} label="History" />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <OrderDetailsTab
                  variables={variables}
                  project={project}
                  onCommentAdded={loadProject}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <LogisticsTab variables={variables} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <PurchaseTab variables={variables} />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <FinanceTab variables={variables} />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <HistoryTab projectId={project.id}/>
              </TabPanel>
              <FormSubmitErrorNotification />
            </>
          }
        </Formik>
      </Paper>
      :
      <></>
  );
}

import React from "react";
import {makeStyles, Tooltip} from "@material-ui/core";
import {InfoOutlined} from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: 'orange'
  },
}));

export default function UpdatedFieldTooltip() {
  const classes = useStyles();

  return (
    <Tooltip title={`This field has been changed. Click "Save changes" to finish updating the order.`}>
      <InfoOutlined className={classes.infoIcon} />
    </Tooltip>
  );
}

import {AxiosResponse} from 'axios';
import HttpClient, {HttpResponse} from '../utils/HttpClient';
import {
    AttachFilePayload,
    Attachment,
    ContactsResponse,
    CreateContactForm,
    CreateProjectCommentRequest,
    CreateProjectFormInitial,
    CreateProjectRequest,
    CreateUserForm,
    EditContactForm,
    EditPasswordForm,
    EditProjectDetailsRequest,
    EditProjectFormInitial,
    EditProjectRequest,
    EditUserForm,
    LoginResponse,
    ProjectDetailsResponse,
    SignUpResponse,
    User,
    UsersResponse,
    Variable,
} from './types';
import {HistoryResponse} from "../scenes/ProjectDetails/types";

export default {

    login: async (
        email: string,
        password: string
    ): Promise<HttpResponse<LoginResponse>> => {
        const response = await HttpClient.post('/auth/login', {
            email,
            password,
        });

        return response.data;
    },

    signUp: async (
        email: string,
        password: string
    ): Promise<HttpResponse<SignUpResponse>> => {
        const response = await HttpClient.post('/auth/signup', {
            email,
            password,
        });

        return response.data;
    },
    changePassword: async (payload: EditPasswordForm): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post('/profile/change-password', {
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword
        });

        return response.data;
    },
    getUsers: async (): Promise<HttpResponse<UsersResponse[]>> => {
        const response = await HttpClient.get('/users');

        return response.data;
    },
    getMyUser: async (): Promise<HttpResponse<User>> => {
        const response = await HttpClient.get('/profile');

        return response.data;
    },
    createUser: async (payload: CreateUserForm): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post('/auth/signup', payload);

        return response.data;
    },
    editUser: async (id: string, payload: EditUserForm): Promise<HttpResponse<void>> => {
        const response = await HttpClient.put(`/users/${id}`, payload);

        return response.data;
    },
    deleteUser: async (id: string): Promise<HttpResponse<void>> => {
        const response = await HttpClient.delete(`/users/${id}`);

        return response.data;
    },
    restoreUser: async (
        id: string
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post(`/users/${id}/restore`);

        return response.data;
    },
    getContacts: async (): Promise<HttpResponse<ContactsResponse[]>> => {
        const response = await HttpClient.get('/contacts');

        return response.data;
    },
    getContact: async (id: string): Promise<HttpResponse<ContactsResponse>> => {
        const response = await HttpClient.get(`/contacts/${id}`);

        return response.data;
    },
    createContact: async (payload: CreateContactForm): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post('/contacts', payload);

        return response.data;
    },
    editContact: async  (id: string, payload: EditContactForm): Promise<HttpResponse<void>> => {
        const response = await HttpClient.put(`/contacts/${id}`, payload);

        return response.data;
    },
    getVariables: async (): Promise<HttpResponse<Variable[]>> => {
        const response = await HttpClient.get('/variables');

        return response.data;
    },
    editVariable: async (payload: Variable): Promise<HttpResponse<void>> => {
        const response = await HttpClient.put(`/variables/${payload.id}`, payload);

        return response.data;
    },
    deleteContact: async (id: string): Promise<HttpResponse<void>> => {
        const response = await HttpClient.delete(`/contacts/${id}`);

        return response.data;
    },
    getCustomers: async (): Promise<HttpResponse<ContactsResponse[]>> => {
        const response = await HttpClient.get('/contacts?type=CUSTOMER');

        return response.data;
    },
    getProjects: async (
        year: number,
        weeks: string[]
    ): Promise<AxiosResponse> => {
        return await HttpClient.get(`/projects?year=${year}&week=${weeks.join(',')}`);
    },
    getProjectViews: async (
      type: string | null, search: string | null, page: number, pageSize: number,): Promise<AxiosResponse> => {
        const url = '/projects/view?';

        const params = new URLSearchParams();
        if (type) params.append('type', type)
        if (search) params.append('search', search);
        !isNaN(page) ? params.append('offset', page.toString()) : params.append('offset', '0');
        params.append('limit', pageSize?.toString() || '10');

        return await HttpClient.get(url + params);
    },
    createInitialProject: async (
      payload: CreateProjectFormInitial
    ): Promise<HttpResponse<void>> => {

        const response = await HttpClient.post('/projects/initial', payload);
        return response.data;
    },
    editInitialProject: async (
      id: string, payload: EditProjectFormInitial
    ): Promise<HttpResponse<void>> => {

        const response = await HttpClient.put(`/projects/initial/${id}`, payload);
        return response.data;
    },
    getProjectDetails: async (
        id: string
    ): Promise<HttpResponse<ProjectDetailsResponse>> => {
        const response = await HttpClient.get(`/projects/${id}`);

        return response.data;
    },
    editProjectDetails: async (
      id: string, payload: EditProjectDetailsRequest
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.put(`/projects/details/${id}`, payload);

        return response.data;
    },
    createProject: async (
        payload: CreateProjectRequest
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post('/projects', payload);

        return response.data;
    },
    editProject: async (
        payload: EditProjectRequest
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.put(`/projects/${payload.id}`, payload);

        return response.data;
    },
    deleteProject: async (
        id: string
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.delete(`/projects/${id}`);

        return response.data;
    },
    moveProjectUp: async (
        id: string,
        steps: number
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post(`/projects/${id}/move/up/${steps}`);

        return response.data;
    },
    moveProjectDown: async (
        id: string,
        steps: number
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post(`/projects/${id}/move/down/${steps}`);

        return response.data;
    },
    createProjectComment: async (
        id: string,
        payload: CreateProjectCommentRequest
    ): Promise<HttpResponse<void>> => {
        const response = await HttpClient.post(`/projects/${id}/comments`, payload);

        return response.data;
    },
    getAttachments: async (ownerId: string): Promise<HttpResponse<Attachment[]>> => {
        const response = await HttpClient.get(`/attachments?ownerId=${ownerId}`);
        return response.data
    },
    attachFiles: async (payload: AttachFilePayload): Promise<HttpResponse<void>> => {
        const formData = new FormData();
        formData.append('ownerId', payload.ownerId);
        formData.append('ownerType', payload.ownerType);
        formData.append('attachmentType', payload.attachmentType);
        payload.files.forEach(({filename, file}, index) => {
            // user can edit filename, create new file to handle changed filename
            const newFile: File = new File([file], filename, { type: file.type });
            formData.append('file', newFile);
        })
        formData.append('attachmentType', payload.attachmentType);

        const response = await HttpClient.post(`/attachments`, formData);
        return response.data;
    },
    deleteAttachment: async (id: string,): Promise<HttpResponse<Attachment[]>> => {
        const response = await HttpClient.delete(`/attachments/${id}`);
        return response.data;
    },
    downloadAttachment: async (id: string,): Promise<HttpResponse<Blob>> => {
        const response = await HttpClient.get(
            `/attachments/${id}/download`,
            { responseType: 'blob' }
        );
        return response.data;
    },

    getProjectHistory: async (projectId: string, limit: number, cursor: string | null): Promise<HttpResponse<HistoryResponse>> => {
        const params = new URLSearchParams();
        params.append('limit', limit.toString());
        if (cursor) {
            params.append('cursor', cursor);
        }
        const response = await HttpClient.get(
          `/projects/${projectId}/history?` + params
        );
        return response.data;
    }

};

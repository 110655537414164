import {ProjectComment, Loading, ProjectWeekResponse} from "../../../API/types";
import {getWeekRange, getWeekString} from "../../../utils/weekUtils";
import {UseTableRowProps} from "react-table";
import dayjs from "dayjs";
import {Publish} from "@material-ui/icons";
import {Button, makeStyles} from "@material-ui/core";
import React from "react";

export interface ExportProjectsProps {
  projects: ProjectWeekResponse[];
  period: dayjs.Dayjs;
  renderDateCell: (date: Date | null) => string;
  renderVGMCell: (vgm: boolean) => string;
  renderLoadingTimeCell: (loadings: Loading[]) => string;
  columns: {
    Header: string; Cell?: ({row}: { row: UseTableRowProps<ProjectWeekResponse>; })
      => string | number | null; accessor: keyof ProjectWeekResponse;
  }[]
}

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  }
}));

export default function ExportProjects(props: ExportProjectsProps) {
  const classes = useStyles();

  const getTableDataForExport = (data: ProjectWeekResponse[]) => data?.map((record: ProjectWeekResponse) =>
    props.columns.reduce((recordToDownload, column) => (
      {...recordToDownload, [column.Header]: record[column.accessor]}
    ), {}));

  const csvWeekDataRenderer = (rows: any[]) => {
    if (rows.length > 0) {
      const separator: string = ';';
      const keys: string[] = Object.keys(rows[0]);
      return `${keys.join(separator)}\n${
        rows.map((row) => keys.map((k) => {
          const cell = row[k] === null || row[k] === undefined ? '' : row[k];

          if (k === 'Loadings') return props.renderLoadingTimeCell(cell);

          if (k === 'VGM') return props.renderVGMCell(cell);

          if (k === 'Comment') return renderCommentCell(cell);

          if (cell instanceof Date) props.renderDateCell(cell);

          return cell;
        }).join(separator)).join('\n')}`;
    } else {
      return 'No orders found';
    }
  }

  const renderCommentCell = (comments: ProjectComment[]) => {
    comments.sort((first, second) => dayjs(second.createdAt).valueOf() - dayjs(first.createdAt).valueOf());

    if (comments.length > 0) {
      return comments.map(comment => comment.body).join(', ');
    }

    return null;
  }

  const csvWeekTotalViewRenderer = (weekNumber: number, csv: string, totalQTY: number, totalM3: number) => {
    return `Week ${weekNumber}\n${csv}\n;;;;;${totalQTY > 0 ? totalQTY : ''};;;;;;;;;${totalM3 > 0 ? totalM3 : ''}\n`;
  } // a bit hacky, should change if layout of orders changes

  const handleExportCsv = async () => {
    const weekNumbers = getWeekRange(props.period);
    let exportCsv = '';
    weekNumbers.forEach(weekNumber => {
      const weekProjects = props.projects.filter((project) =>
        project.week === getWeekString(weekNumber));
      const totalQTY = weekProjects.map(item => item.qty ? Number(item.qty) : 0)
        .reduce((prev, curr) => prev + curr, 0);
      const totalM3 = weekProjects.map(item => item.m3 ? Number(item.m3) : 0)
        .reduce((prev, curr) => prev + curr, 0);
      const weekDataReadyForExport = csvWeekDataRenderer(getTableDataForExport(weekProjects));
      exportCsv = exportCsv + csvWeekTotalViewRenderer(weekNumber, weekDataReadyForExport, totalQTY, totalM3);
    });

    const blob = new Blob([exportCsv], {type: 'text/csv;charset=utf-8;'});
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `week ${weekNumbers[0]}-${weekNumbers[2]} ${props.period.year()}`);
      link.click();
    }
  };
  return (
    <Button
      className={classes.button}
      variant="text"
      color="primary"
      startIcon={<Publish style={{transform: 'rotate(180deg)'}} />}
      onClick={() => handleExportCsv()}
    >
      Download
    </Button>
  );
}
export interface LoginResponse {
  token: string;
}

export interface SignUpResponse {
  token: string;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: Permission[];
  roles: Role[];
}

export enum Role {
  User = "USER", Admin = "ADMIN"
}

export enum Permission {
  ReadProjects = "read:projects",
  ReadContacts = "read:contacts",
  UpdateContacts = "update:contacts",
  UpdateProjects = "update:projects",
  DeleteProjects = "delete:projects",
  ReadUsers = "read:users",
  UpdateUsers = "update:users",
  DeleteUsers = "delete:users",
  ReadVariables = "read:variables",
  UpdateVariables = "update:variables",
  ReadAttachments = "read:attachments",
  UpdateAttachments = "update:attachments",
  DeleteAttachments = "delete:attachments",
}

export interface CreateUserForm {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export type EditUserForm = Omit<CreateUserForm, 'password' | 'email'>;

export interface EditProfileForm {
  firstName: string;
  lastName: string;
}

export interface EditPasswordForm {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string
}

export interface UsersResponse {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isDeleted: boolean;
}

export interface CreateContactForm {
  name: string;
  type: string;
  code: string | null;
  address: string | null;
  country: string | null;
  persons: ContactPerson[]
}

export type EditContactForm = Omit<CreateContactForm, 'type'>;

export interface ContactsResponse {
  id: string;
  name: string;
  type: string;
  code: string | null;
  address: string | null;
  country: string | null;
  persons: ContactPerson[];
}

export interface Variable {
  id: string;
  name: string;
  options: string[];
}

export interface ContactPerson {
  id: string | null;
  name: string;
  email: string | null;
  phoneNumber: string | null;
}

export interface CreateProjectFormInitial {
  type: string
  ourRef: number;
  ourRefPart: string;
  customerId: string | null;
  customerName: string | null;
  customerRef: string | null;
  customerPersonId: string | null;
  customerPersonName: string | null;
  week: string | null;
  year: string | null;
}

export type EditProjectFormInitial = Omit<CreateProjectFormInitial, 'type' | 'ourRef' | 'ourRefPart'>;

export interface ProjectViewResponse {
  id: string;
  ourRef: string | null;
  customerId: string | null;
  customerName: string | null;
  customerPersonId: string | null;
  customerPersonName: string | null;
  etd: Date | null;
  status: string | null;
  type: string
}

export interface ProjectWeekResponse {
  id: string;
  customerRef: string | null;
  customerId: string | null;
  customerName: string | null;
  pod: string | null;
  ourRef: string;
  origWeek: string | null;
  week: string | null;
  year: string | null;
  qty: number | null;
  vessel: string | null;
  gate: string | null;
  product: string | null;
  closingDoc: Date | null;
  etd: Date | null;
  eta: Date | null;
  vgm: boolean;
  m3: string | null;
  comments: ProjectComment[];
  colors: ProjectCellColors[];
  loadings: Loading[];
}

export interface Loading {
  sequence: number;
  time: string | null;
  qty: number | null;
  transportType?: string | null;
  pol?: string | null;
  pod?: string | null;
  eta?: string | null;
  etaRequested?: string | null;
  ata?: string | null;
  haulageBy?: string | null;
  haulageReference?: string | null;
  forwarder?: string | null;
  contline?: string | null;
  bookingReference?: string | null;
  billOfLading?: string | null;
  gateOpen?: DateAndTime | null;
  polDate?: string | null;
  cutOffContainersNos?: DateAndTime | null;
  cutOffContainers?: DateAndTime | null;
  estimatedUnitFrCost?: Price | null;
  unitFrCostPaid?: Price | null;
  vgm?: number | null;
  cbm?: number | null;
  units?: Unit[] | null;
}

export interface DateAndTime {
  date: string | null;
  time: string | null;
}

export interface Unit {
  containerNumber?: string | null;
  vehicleNumber?: string | null;
  sealNumber?: string | null;
  vgm?: boolean | null;
  cbm?: number | null;
}

export interface ProjectComment {
  id: string;
  firstName: string;
  lastName: string;
  body: string;
  createdAt: string;
}

export interface ProjectCellColors {
  cellName: string;
  hex: string;
}

export interface CreateProjectRequest {
  customerRef: string | null;
  customerName: string | null;
  pod: string | null;
  ourRef: number;
  ourRefPart: string;
  week: string | null;
  qty: number | null;
  vessel: string | null;
  gate: string | null;
  product: string | null;
  closingDoc: string | null;
  etd: string | null;
  eta: string | null;
  vgm: boolean;
  m3: string | null;
  colors: CellColors[];
  loadings: Loading[];
}

export interface CellColors {
  cellName: string;
  hex: string;
}

export interface EditProjectRequest {
  id: string;
  customerRef: string | null;
  customerName: string | null;
  pod: string | null;
  week: string | null;
  qty: number | null;
  vessel: string | null;
  gate: string | null;
  product: string | null;
  closingDoc: string | null;
  etd: string | null;
  eta: string | null;
  vgm: boolean;
  m3: string | null;
  colors: CellColors[];
  loadings: Loading[];
}

export interface EditProjectDetailsRequest {
  customerRef: string | null;
  clientPoDate: string | null;
  poValue: Price | null;
  soldValue: Price | null;
  invoiceDate: string | null;
  invoiceRef: string | null;
  specialRequest: string | null;
  suppliersPoSentDate: string | null;
  tpoSentDate: string | null;
  bookingReceivedDate: string | null;
  loadedDate: string | null;
  deliveredDate: string | null;
  cancelledDate: string | null;
  doxFee: Price | null;
  unitHaulageCost: Price | null;
  customsCost: Price | null;
  agentFeePercent: number | null;
  packageCost: Price | null;
  haulageCost: Price | null;
  freightCost: Price | null;
  agentFeeCost: Price | null;
  ttlRevPlanned: Price | null;
  ttlRevImplemented: Price | null;
  revPercent: number | null;
  cbmPrice: Price | null;
  unitPrice: Price | null;
  supplierPoDate: string | null;
  orderedValue: Price | null;
  suppliersBillTotal: Price | null;
  supplierInvRef: string | null;
  packagesQuantity: number | null;
  packagePrice: Price | null;
  otherCost: Price | null;
  faf: Price | null;
  ebs: Price | null;
  plt: Price | null;
  otherVariableCharges: Price | null;
  articleNumber: string | null;
  packageSize: number | null;
  qty: number | null;
  loadings: Loading[];
}

export interface CreateProjectCommentRequest {
  body: string;
}

export interface ProjectDetailsResponse {
  id: string;
  customerRef: string | null;
  customerName: string | null;
  customerId: string | null;
  pod: string | null;
  clientPoDate: Date | null;
  poValue: Price | null;
  soldValue: Price | null;
  invoiceDate: Date | null;
  invoiceRef: string | null;
  specialRequest: string | null;
  suppliersPoSentDate: Date | null;
  tpoSentDate: Date | null;
  bookingReceivedDate: Date | null;
  loadedDate: Date | null;
  deliveredDate: Date | null;
  cancelledDate: Date | null;
  doxFee: Price | null;
  unitHaulageCost: Price | null;
  customsCost: Price | null;
  agentFeePercent: number | null;
  packageCost: Price | null;
  haulageCost: Price | null;
  freightCost: Price | null;
  agentFeeCost: Price | null;
  ttlRevPlanned: Price | null;
  ttlRevImplemented: Price | null;
  revPercent: number | null;
  cbmPrice: Price | null;
  unitPrice: Price | null;
  supplierPoDate: Date | null;
  orderedValue: Price | null;
  suppliersBillTotal: Price | null;
  supplierInvRef: string | null;
  packagesQuantity: number | null;
  packagePrice: Price | null;
  otherCost: Price | null;
  faf: Price | null;
  ebs: Price | null;
  plt: Price | null;
  otherVariableCharges: Price | null;
  articleNumber: string | null;
  packageSize: number | null;
  ourRef: string | null;
  createdAt: Date;
  updatedAt: Date;
  origWeek: string | null;
  week: string | null;
  year: string | null;
  qty: number | null;
  vessel: string | null;
  gate: string | null;
  product: string | null;
  loading: string | null;
  closingDoc: Date | null;
  etd: Date | null;
  eta: Date | null;
  vgm: boolean;
  m3: string | null;
  comments: ProjectComment[];
  colors: [];
  loadings: Loading[];
}

export interface Price {
  amount: number;
  currency: string;
  exchangeRate: number | null;
  amountInBaseCurrency: number;
}

export interface Attachment {
  id: string;
  attachmentType?: string;
  fileName: string;
  fileSize: number;
  createdAt: Date;
}

export interface AttachFilePayload {
  ownerId: string;
  ownerType: string;
  attachmentType: string;
  files: {
    filename: string;
    file: File;
  }[]
}
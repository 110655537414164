import React, {useContext, useEffect, useState} from 'react';
import UsersTable from './components/UsersTable';
import {UsersResponse} from '../../API/types';
import API from '../../API';
import LoadingSpinner from '../components/LoadingSpinner';
import CreateEditUserDialog from './components/CreateEditUserDialog';
import {useSnackbar} from 'notistack';
import {UserContext} from '../../contexts/UserContext';


export default function Users() {
  const { user: authenticatedUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<UsersResponse[]>([]);

  useEffect(() => {
    reloadUsers();
  }, []);

  const reloadUsers = () => {
    API.getUsers().then((response) => {
      if (response.isSuccessful) {
        setUsers(response.data);
      }

      setIsLoading(false);
    });
  };

  const handleCreateUserClick = () => setCreateEditUser({
    id: '', email: "", firstName: "", lastName: "", isDeleted: false
  });
  const handleEditUserClick = (user: UsersResponse) => setCreateEditUser(user);
  const handleDeleteUserClick = async (id: string) => {
    if (authenticatedUser.id === id) {
      enqueueSnackbar('Cannot delete yourself', {
        variant: 'error',
      });
    }

    const deleteResponse = await API.deleteUser(id);

    if (deleteResponse.isSuccessful) {
      enqueueSnackbar('User successfully deleted', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Oops, something went  wrong. Please try again later.', {
        variant: 'error',
      });
    }

    reloadUsers();
  };
  const handleRestoreUserClick = async (user: UsersResponse) => {
    const restoreResponse = await API.restoreUser(user.id);

    if (restoreResponse.isSuccessful) {
      enqueueSnackbar('User successfully restored', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Oops, something went  wrong. Please try again later.', {
        variant: 'error',
      });
    }

    reloadUsers();
  };
  const [createEditUser, setCreateEditUser] = useState<UsersResponse | null>(null);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <UsersTable
          onEditClick={handleEditUserClick}
          onDeleteClick={handleDeleteUserClick}
          onRestoreClick={handleRestoreUserClick}
          onCreateClick={handleCreateUserClick}
          users={users}
        />
      )}
      <CreateEditUserDialog
        open={Boolean(createEditUser)}
        user={createEditUser}
        onUpdate={reloadUsers}
        onClose={() => setCreateEditUser(null)}
      />
    </>
  );
}

import {makeStyles} from "@material-ui/core";

export const useTableStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(1.2, 1, 1.2),
  },
  headerCell: {
    padding: theme.spacing(2, 0, 2),
    backgroundColor: 'white'
  },
  headerCellWithoutDivider: {
    padding: theme.spacing(2, 0, 2),
    backgroundColor: 'white'
  },
  header: {
    fontWeight: 'bolder',
    borderLeft: '2px solid',
    marginLeft: '-1px',
    padding: theme.spacing(0, 1, 0),
    lineHeight: 0.9,
    borderColor: theme.palette.divider,
  },
  headerWithoutDivider: {
    fontWeight: 'bolder',
    marginLeft: '1px',
    padding: theme.spacing(0, 1, 0),
    lineHeight: 0.9,
    borderColor: theme.palette.divider,
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    }
  },
}));

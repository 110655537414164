import React, {useEffect, useState} from "react";
import {ContactsResponse} from "../../API/types";
import API from "../../API";
import LoadingSpinner from "../components/LoadingSpinner";
import {useParams} from "react-router-dom";
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Paper
} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {ContactErrors} from "../Contacts/types";
import {getName} from "country-list";
import {Person, Flag, LabelImportant, Home, Assignment, People} from "@material-ui/icons";
import PersonsTable from "./components/PersonsTable";

export interface ContactParams {
  id: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  primary: {
    fontSize: "x-large"
  }
}));


export default function Contact() {
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();
  const [contact, setContact] = useState<ContactsResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const {id} = useParams<ContactParams>();

  useEffect(() => {
    reloadContact();
  }, []);

  const reloadContact = () => {
    API.getContact(id).then((response) => {
      if (response.isSuccessful) {
        response.data.type = response.data.type.charAt(0) + response.data.type.substring(1).toLowerCase();
        setContact(response.data);
      } else {
        let errorMessage =
          'Whoops, something went wrong. Please try again later.';

        if (response.code === ContactErrors.CONTACT_NOT_FOUND) {
          errorMessage = response.message;
        }

        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }

      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <Paper className={classes.paper}>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Person/>
              </ListItemAvatar>
              <ListItemText classes={{primary: classes.primary}} primary={contact?.name} secondary="Name"/>
            </ListItem>
            {contact?.code ? (
              <ListItem>
                <ListItemAvatar>
                  <LabelImportant/>
                </ListItemAvatar>
                <ListItemText classes={{primary: classes.primary}} primary={contact?.code}
                              secondary="Code"/>
              </ListItem>
            ) : (<></>)}
            <ListItem>
              <ListItemAvatar>
                <Assignment/>
              </ListItemAvatar>
              <ListItemText classes={{primary: classes.primary}} primary={contact?.type} secondary="Type"/>
            </ListItem>
            {contact?.address ? (
              <ListItem>
                <ListItemAvatar>
                  <Home/>
                </ListItemAvatar>
                <ListItemText classes={{primary: classes.primary}} primary={contact?.address} secondary="Address"/>
              </ListItem>
            ) : (<></>)}
            {contact?.country ? (
              <ListItem>
                <ListItemAvatar>
                  <Flag/>
                </ListItemAvatar>
                <ListItemText classes={{primary: classes.primary}} primary={getName(contact.country)}
                              secondary="Country"/>
              </ListItem>
            ) : (<></>)}
            {contact?.persons && contact.persons.length > 0 ? (
              <ListItem>
                <ListItemAvatar>
                  <People/>
                </ListItemAvatar>
                <ListItemText classes={{primary: classes.primary}} primary='Persons:'/>
              </ListItem>
            ) : (<></>)}
          </List>
          {contact?.persons && contact.persons.length > 0 ? (
            <PersonsTable persons={contact.persons}></PersonsTable>
          ) : (<></>)}
        </Paper>
      )}
    </>
  );
}
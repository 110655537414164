import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {DialogTitle as MuiDialogTitle, makeStyles, Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  padding: {
    marginLeft: '5px'
  },
  closeButton: {
    marginTop: '-8px',
    float: 'right'
  }
}));

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

export default function DialogTitle(props: DialogTitleProps) {
  const classes = useStyles();
  const {children, onClose} = props;

  return (
    <MuiDialogTitle disableTypography className={classes.padding}>
      <Typography variant="h5">
        {children}
        {onClose ? (
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon/>
          </IconButton>
        ) : null}
      </Typography>
    </MuiDialogTitle>
  );
}
import {CircularProgress, Fade} from '@material-ui/core';
import React from 'react';
import {theme} from "../../theme";


export default function LoadingSpinner() {
  return (
    <Fade in={true} style={{transitionDelay: '800ms'}} unmountOnExit>
      <CircularProgress style={{marginLeft: theme.spacing(3)}} />
    </Fade>
  );
}

import {ContactsResponse, Permission} from "../../../API/types";
import {
  useFilters,
  useTable,
  UseTableRowProps,
} from "react-table";
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import React, {useState} from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {Add, Visibility} from "@material-ui/icons";
import SearchFilter from "./SearchFilter";
import {useHistory} from "react-router-dom";
import Visible from "../../components/Visible";
import {MenuAnchor} from "../../types";
import {useTableStyles} from "../../../styles/tableStyles";
import {toolbarStyles} from "../../../styles/toolbarStyles";


export interface ContactsTableProps {
  contacts: ContactsResponse[];
  onDeleteClick: (id: string) => void;
  onEditClick: (contact: ContactsResponse) => void;
  onCreateClick: () => void;
}

const columns: {
  Header: string;
  Cell?: ({
            row,
          }: {
    row: UseTableRowProps<ContactsResponse>;
  }) => string | number | null;
  accessor: keyof ContactsResponse;
}[] = [
  {accessor: 'name', Header: 'Name'},
  {accessor: 'code', Header: 'Code'},
  {accessor: 'type', Header: 'Type'}
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 2, 5)
  },
  container: {
    padding: theme.spacing(2),
  },
  createButton: {
    float: 'right'
  },
}));

export default function ContactsTable(props: ContactsTableProps) {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const toolbarClasses = toolbarStyles();

  const history = useHistory();

  const [menuAnchor, setMenuAnchor] = useState<MenuAnchor>({id: null, anchorEl: null});

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {filters},
    setFilter,
  } =
    useTable({
        columns,
        data: props.contacts,
      },
      useFilters
    );
  const handleOpenMenu =
    (contact: ContactsResponse) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor({id: contact.id, anchorEl: event.currentTarget});
    };

  const handleCloseMenu = () => {
    setMenuAnchor({id: null, anchorEl: null});
  };

  const handleViewClick = (contact: ContactsResponse) => {
    history.push(`/contacts/${contact.id}`)
  };

  const handleEditClick = (contact: ContactsResponse) => {
    handleCloseMenu();
    props.onEditClick(contact);
  };

  const handleDeleteClick = (contact: ContactsResponse) => {
    handleCloseMenu();
    if (window.confirm('Are you sure you want to delete ' + contact.name + '?')) props.onDeleteClick(contact.id);
  };

  const actionMenuItems = [
    {name: 'View', action: handleViewClick, Icon: Visibility, permission: Permission.ReadContacts},
    {name: 'Edit', action: handleEditClick, Icon: EditIcon, permission: Permission.UpdateContacts},
    {name: 'Delete', action: handleDeleteClick, Icon: DeleteIcon, permission: Permission.UpdateContacts},
  ];

  return (
    <Paper className={classes.paper}>
      <Box className={toolbarClasses.container}>
        <Box style={{display: 'flex', flexDirection: 'row'}}>
          <Typography variant="h5" className={toolbarClasses.title}>
            Contacts
          </Typography>
          <SearchFilter
            filters={filters}
            setFilter={setFilter}
          />
        </Box>
        <Visible permission={Permission.UpdateContacts}>
          <Grid item xs={4}>
            <Button variant="contained"
                    startIcon={<Add/>}
                    color="primary"
                    onClick={props.onCreateClick}
                    className={classes.createButton}>
              Add contact
            </Button>
          </Grid>
        </Visible>
      </Box>
      <TableContainer>
        <Table stickyHeader size="small" padding="none" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index+headerGroup.id}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    className={tableClasses.headerCell}
                    {...column.getHeaderProps()}
                    key={index+column.id}
                  >
                    <Typography variant="body1" className={index === 0 ? tableClasses.headerWithoutDivider : tableClasses.header}>
                      {column.render('Header')}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell className={tableClasses.headerCell} width={52}/>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.original.id}>
                  <TableRow
                    {...row.getRowProps()}
                    className={tableClasses.row}
                  >
                    {row.cells.map((cell) => (
                      <TableCell {...cell.getCellProps()} key={index+cell.value} className={tableClasses.cell}>
                        <Typography variant="body1">{cell.render('Cell')}</Typography>
                      </TableCell>
                    ))}
                    <TableCell>
                      <Visible anyPermissions={[Permission.UpdateContacts, Permission.ReadContacts]}>
                        <IconButton
                          size="small"
                          onClick={handleOpenMenu(row.original)}
                        >
                          <MoreVertIcon/>
                        </IconButton>
                        <Menu
                          anchorEl={
                            menuAnchor.id === row.original.id
                              ? menuAnchor.anchorEl
                              : null
                          }
                          getContentAnchorEl={null}
                          keepMounted={false}
                          open={menuAnchor.id === row.original.id}
                          onClose={handleCloseMenu}
                        >
                          {
                            actionMenuItems.map((item) => {
                              const Icon = item.Icon;

                              return (
                                <Visible key={item.name} permission={item.permission}>
                                  <MenuItem
                                    onClick={() => item.action(row.original)}
                                  >
                                    <ListItemIcon>
                                      <Icon fontSize="small"/>
                                    </ListItemIcon>
                                    <ListItemText primary={item.name}/>
                                  </MenuItem>
                                </Visible>
                              );
                            })}
                        </Menu>
                      </Visible>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
import {makeStyles} from "@material-ui/core";


export const toolbarStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5, 1.5, 3),
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    marginRight: theme.spacing(3)
  },
}));
import React, {useEffect, useState} from "react";
import {ContactsResponse} from "../../API/types";
import API from "../../API";
import LoadingSpinner from "../components/LoadingSpinner";
import ContactsTable from "./components/ContactsTable";
import {useSnackbar} from "notistack";
import CreateEditContactDialog from "./components/CreateEditContactDialog";


export default function Contacts() {
  const { enqueueSnackbar } = useSnackbar();

  const [contacts, setContacts] = useState<ContactsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    reloadContacts();
  }, []);

  const reloadContacts = () => {
    API.getContacts().then((response) => {
      if (response.isSuccessful) {
        response.data.forEach(item => {
          item.type = item.type.charAt(0) + item.type.substring(1).toLowerCase();
        })
        setContacts(response.data);
      }

      setIsLoading(false);
    });
  };

  const handleCreateContactClick = () => setCreateEditContact({
    id: "",
    name: "",
    type: "",
    code: null,
    address: null,
    country: null,
    persons: []
  });

  const handleEditContactClick = (contact: ContactsResponse) => setCreateEditContact(contact);
  const handleDeleteContactClick = async (id: string) => {

    const deleteResponse = await API.deleteContact(id);

    if (deleteResponse.isSuccessful) {
      enqueueSnackbar('Contact successfully deleted', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Oops, something went wrong. Please try again later.', {
        variant: 'error',
      });
    }

    reloadContacts();
  };

  const [createEditContact, setCreateEditContact] = useState<ContactsResponse | null>(null);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner/>
      ) : (
        <ContactsTable
          onCreateClick={handleCreateContactClick}
          onEditClick={handleEditContactClick}
          onDeleteClick={handleDeleteContactClick}
          contacts={contacts}
        />
      )}
      <CreateEditContactDialog
        open={Boolean(createEditContact)}
        contact={createEditContact}
        onSubmit={reloadContacts}
        onClose={() => setCreateEditContact(null)}
      />
    </>
  );
}
import {makeStyles} from "@material-ui/core";


export const drawerStyles = makeStyles((theme) => ({
  listIcon: {
    margin: theme.spacing(0, 0.5, 0),
    minWidth: theme.spacing(6)
  },
  listItemActive: {
    borderLeft: '4px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.selected
  },
  listItemInactive: {
    borderLeft: '4px solid transparent'
  }
}));

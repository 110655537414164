import {Permission, Role} from "../API/types";

export const stringToEnumRoleMap: { [key: string]: Role } = {
    "USER": Role.User,
    "ADMIN": Role.Admin
}

export const stringToEnumPermissionMap: { [key: string]: Permission } = {
    "read:contacts": Permission.ReadContacts,
    "update:contacts": Permission.UpdateContacts,
    "read:projects": Permission.ReadProjects,
    "update:projects": Permission.UpdateProjects,
    "delete:projects": Permission.DeleteProjects,
    "read:users": Permission.ReadUsers,
    "update:users": Permission.UpdateUsers,
    "delete:users": Permission.DeleteUsers,
    "read:variables": Permission.ReadVariables,
    "update:variables": Permission.UpdateVariables,
    "read:attachments": Permission.ReadAttachments,
    "update:attachments": Permission.UpdateAttachments,
    "delete:attachments": Permission.DeleteAttachments,
}
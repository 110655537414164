import {Price, DateAndTime} from "../API/types";
import {FormDateAndTime, FormPrice} from "../scenes/ProjectDetails/types";


export const DEFAULT_CURRENCY = 'EUR';

const nrToDisplayStr = (value: number, decimalCount: number): string => {
  const splitValues = value.toString().split('.');
  return (splitValues.length === 1) ?
    splitValues[0] + ',' + '0'.repeat(decimalCount) : splitValues[0] + ',' + splitValues[1].padEnd(decimalCount, '0');
};

export const getPrice = (price: FormPrice | null): Price | null => {
  return price && price.amount && (price.currency === DEFAULT_CURRENCY || price.exchangeRate) ? {
    amount: strToDec(price.amount),
    currency: price.currency,
    exchangeRate: price.exchangeRate ? strToDec(price.exchangeRate) : null,
    amountInBaseCurrency: price.amountInBaseCurrency ?
      strToDec(price.amountInBaseCurrency) : strToDec(getAmountInBaseCurrency(price.amount, price.exchangeRate))
  } : null;
};

export const getFormPrice = (price?: Price | null): FormPrice => {
  return price ? {
    amount: nrToDisplayStr(price.amount, 2),
    currency: price.currency,
    exchangeRate: price.exchangeRate ? nrToDisplayStr(price.exchangeRate, 4) : null,
    amountInBaseCurrency: nrToDisplayStr(price.amountInBaseCurrency, 2)
  } : {
    amount: null,
    currency: DEFAULT_CURRENCY,
    exchangeRate: null,
    amountInBaseCurrency: null
  };
};

export const getAmountInBaseCurrency = (amount: string, exchangeRate: string | null): string =>
  exchangeRate ? roundToTwoDecimals(strToDec(amount) * strToDec(exchangeRate)) : amount;

export const commaToDot = (stringValue: string): string => stringValue.replace(',', '.');

export const dotToComma = (stringValue: string): string => stringValue.replace('.', ',');

export const strToDec = (stringValue: string): number => Number(commaToDot(stringValue));

export const nrToStr = (stringValue?: number | null): string | null => stringValue ? dotToComma((stringValue).toString()) : null;

export const strToNr = (stringValue: string | null): number | null => stringValue ? Number(commaToDot(stringValue)) : null;

export const getPercent = (percent: number | null): string | null => percent ? nrToDisplayStr(percent, 2) : null;

export const hasTextFieldChanged = (initialValue: string | null = null, inputValue: string | null): boolean => {
  return !(initialValue === inputValue || (initialValue === null && inputValue === ''));
};

export const hasNumericTextFieldChanged = (initialValue: string | null = null, inputValue: string | null): boolean => {
  if (initialValue === null && (inputValue === null || inputValue === '')) return false;
  return !(initialValue !== null && inputValue !== null && strToDec(initialValue) === strToDec(inputValue));
};

export const strToDate = (value?: string | null): Date | null => value ? new Date(value) : null;

export const dateToStr = (date: Date): string => {
  if (date.toString().length > 10) {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  } else {
    return date.toString();
  }
}

export const nullableDateToStr = (date: Date | null): string | null => {
  if (date) {
    return dateToStr(date);
  } else {
    return null;
  }
}

const getHhMmSsString = (hhMmString: string | null): string | null => {
  if (hhMmString && hhMmString !== '') {
    return hhMmString + ':00';
  } else {
    return null;
  }
};

const getHhMmString = (hhMmSsString: string | null): string | null => {
  if (hhMmSsString && hhMmSsString !== '') {
    return hhMmSsString.slice(0,5);
  } else {
    return null;
  }
}

export const getFormDateAndTime = (dateAndTime?: DateAndTime | null): FormDateAndTime => {
  if (dateAndTime) {
    return {
      date: dateAndTime.date ? strToDate(dateAndTime.date) : null,
      time: dateAndTime.time ? getHhMmString(dateAndTime.time) : null,
    }
  } else {
    return {date: null, time: null};
  }
};

export const getProjectDateAndTime = (dateAndTime: FormDateAndTime): DateAndTime | null => {
  if (dateAndTime && (dateAndTime.time || dateAndTime.date)) {
    return {
      date: dateAndTime.date ? dateToStr(dateAndTime.date) : null,
      time: getHhMmSsString(dateAndTime.time)
    }
  } else {
    return null;
  }
};

export const hasDateFieldChanged = (initialValue: Date | null = null, inputValue: Date | null) =>
  nullableDateToStr(initialValue) !== nullableDateToStr(inputValue);

export const hasDateTimeFieldChanged = (initialValue: FormDateAndTime | null = null, inputValue: FormDateAndTime) =>
  hasDateFieldChanged(initialValue?.date, inputValue.date) || hasTextFieldChanged(initialValue?.time, inputValue.time)

export const roundToTwoDecimals = (value: number) => (Math.round(value * 100) / 100).toFixed(2);

export const resolvePath = (object: any, path: string) =>
  path.replace(/\[([^[]]*)]/g, '.$1.')
    .split('.')
    .filter(t => t !== '')
    .reduce((prev, cur) => prev && prev[cur], object)

import React, {useEffect, useMemo, useState} from 'react';
import {HistoryResponse, ProjectHistoryRecord} from "../types";
import {Dialog, DialogContent, ListItemText} from "@material-ui/core";
import {Differ, DiffResult, Viewer} from "json-diff-kit";

import 'json-diff-kit/dist/viewer.css';
import HistoryTable from "./HistoryTable";
import API from "../../../API";
import {formatDateTimeStr} from "../../../utils/dateTime";
import {formatFileSize} from "../../components/Attachments/utils";

interface HistoryTabProps {
  projectId: string;
}

function HistoryTab({ projectId }: HistoryTabProps) {
  const limitOptions = [5, 10, 25];
  const defaultLimit = limitOptions[1];

  const [historyResponse, setHistoryResponse] = useState<HistoryResponse | undefined>();
  const [currentRecord, setCurrentRecord] = useState<ProjectHistoryRecord | undefined>();
  const currentDiff = useMemo(() => getCurrentDiff(), [currentRecord])

  useEffect(() => {
    API.getProjectHistory(projectId, defaultLimit, null )
      .then((response) => {
        if (response.isSuccessful) {
          setHistoryResponse(response.data);
        }
      });
  }, []);

  function parseObjectFromJson(encodedJson: string) {
    const result = JSON.parse(encodedJson);
    delete result.comments
    delete result.updatedAt
    delete result.createdAt
    delete result.deletedAt
    delete result.customerId
    delete result.id
    delete result.userId
    delete result.ownerId
    delete result.ownerType
    return result
  }

  function getCurrentDiff(): readonly [DiffResult[], DiffResult[]] {
    if (currentRecord) {
      const differ = new Differ({
        detectCircular: true,    // default `true`
        maxDepth: Infinity,      // default `Infinity`
        showModifications: true, // default `true`
        arrayDiffMethod: 'lcs',  // default `"normal"`, but `"lcs"` may be more useful
      });

      // you may want to use `useMemo` (for React) or `computed` (for Vue)
      // to avoid redundant computations
      return differ.diff(
        (currentRecord.before ? parseObjectFromJson(currentRecord.before) : null),
        (currentRecord.after ? parseObjectFromJson(currentRecord.after) : null),
      );
    } else {
      return [[], []];
    }
  }

  const renderDialogContent = () => {
    if (currentRecord) {
      const currentRecordJson = (currentRecord.before) ? JSON.parse(currentRecord.before) :
        (currentRecord.after) ? JSON.parse(currentRecord.after) : null;
      switch (currentRecord.entityType) {
        case 'Project':
          return <Viewer
            diff={currentDiff}
            highlightInlineDiff={true}
            inlineDiffOptions={{
              mode: 'word',
            }}
          />;
        case 'Comment':
          return <ListItemText
            style={{padding: '2vh 2vw'}}
            primary={currentRecordJson.body}
            secondary={formatDateTimeStr(currentRecordJson.createdAt)}
          />
        case 'Attachment':
          return <ListItemText
            style={{padding: '2vh 2vw'}}
            primary={currentRecordJson.filename}
            secondary={formatDateTimeStr(currentRecordJson.createdAt) +
              (currentRecordJson.attachmentType ? ' - type: ' + currentRecordJson.attachmentType : '' + ' ') +
              (currentRecordJson.fileSize ? ', size: ' + formatFileSize(currentRecordJson.fileSize) : '')}
          />
      }
    }
  }

  return <>
    { historyResponse &&
      <HistoryTable
        projectId={projectId}
        setCurrentRecord={setCurrentRecord}
        defaultLimit={defaultLimit}
        historyResponse={historyResponse}
        limitOptions={limitOptions}
        updateHistoryResponse={setHistoryResponse}/>
    }
    <Dialog
      open={!!currentRecord}
      onClose={() => setCurrentRecord(undefined)}
      fullWidth
      maxWidth={false}
    >
      <React.Fragment>
        <DialogContent>
          {renderDialogContent()}
        </DialogContent>
      </React.Fragment>
    </Dialog>
  </>;
}

export default HistoryTab;
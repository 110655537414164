import React, {Fragment, useEffect, useState} from 'react';
import API from '../../API';
import {
  ContactsResponse,
  CreateProjectRequest,
  EditProjectRequest,
  ProjectWeekResponse,
  Permission,
  Loading
} from '../../API/types';
// import useMercure, { ProjectUpdateEvent } from '../../utils/useMercure';
import dayjs from 'dayjs';
import ProjectTable from './components/ProjectTable';
import CreateEditProjectDialog from './components/CreateEditProjectDialog';
import {getCurrentPeriod, getWeekRange, getWeekRangeStrings} from "../../utils/weekUtils";
import {EditProjectForm, InitialFormLoading, ProjectForm} from './types';
import {useSnackbar} from 'notistack';
import Visible from "../components/Visible";
import {nullableDateToStr} from "../../utils/formUtils";


export default function Dashboard() {
  const { enqueueSnackbar } = useSnackbar();

  const [period, setPeriod] = useState<dayjs.Dayjs>(getCurrentPeriod);
  const [isLoading, setIsLoading] = useState(true);
  const [clients, setClients] = useState<ContactsResponse[]>([]);
  const [projects, setProjects] = useState<ProjectWeekResponse[]>([]);
  const [createEditProject, setCreateEditProject] = useState<ProjectWeekResponse | null>(null);
  const [maxExistingRefNumber, setMaxExistingRefNumber] = useState<number>(0);

    useEffect(() => {
        API.getCustomers().then((response) => {
            if (response.isSuccessful) {
                setClients(response.data);
            }
        });
    }, []);

    useEffect(() => {
        refreshProjects();
    }, [period]);

    // useMercure<ProjectUpdateEvent>(
    //   'project/update',
    //   (payload) => refreshProject(payload.projectId),
    //   [projects]
    // );

    const refreshProjects = () => {
        const weeks = getWeekRangeStrings(period)

        API.getProjects(period.year(), weeks).then((response) => {
            if (response.data.isSuccessful) {
                setProjects(response.data.data);
                setMaxExistingRefNumber(Number(response.headers['x-max-our-ref']))
            }
        }).finally(() => {
            setIsLoading(false);
        });
    };

    // Used for live-updating comments. Mercure event will pass updated project ID.
    // function refreshProject(id: number) {
    //   const index = projects.findIndex((project) => project.id === id);
    //
    //   if (index !== -1) {
    //     API.getProjectDetails(id).then((response) => {
    //       if (response.isSuccessful) {
    //         const clonedProjects = [...projects];
    //         clonedProjects[index] = response.data;
    //
    //         setProjects(clonedProjects);
    //       }
    //     });
    //   }
    // }

    const getInitialProjectLoadings = (formLoadings: InitialFormLoading[]): Loading[] => {
      const projectLoadingTimes: Loading[] = [];
      formLoadings.forEach(loading => {
        if (loading.time) {
          projectLoadingTimes.push({
            sequence: loading.sequence,
            time: nullableDateToStr(loading.time),
            qty: loading.qty ? Number(loading.qty) : null,
          })
        }
      })
      return projectLoadingTimes;
    }

    const onSubmit = async (form: ProjectForm | EditProjectForm) => {
        let response;

        const payload = {
          ...form,
          closingDoc: nullableDateToStr(form.closingDoc),
          etd: nullableDateToStr(form.etd),
          eta: nullableDateToStr(form.eta),
          loadings: getInitialProjectLoadings(form.loadings),
        }

        if (form.id) {
            response = await API.editProject(payload as EditProjectRequest);
        } else {
            response = await API.createProject(payload as CreateProjectRequest);
        }

        if (response.isSuccessful) {
            enqueueSnackbar(`Order ${form.id ? 'updated' : 'created'}`, {
                variant: 'success',
            });
            handleSubmitClick();
        } else {
            let message = form.id
                ? 'Failed to update the order'
                : 'Failed to create a new order';
            if (response.message) {
                message = response.message
            }
            enqueueSnackbar(message, {
                variant: 'error',
            });
        }
    }

    const handleCreateProject = () => setCreateEditProject({
      id: '',
      customerRef: null,
      closingDoc: null,
      colors: [],
      comments: [],
      customerId: null,
      customerName: null,
      eta: null,
      etd: null,
      gate: null,
      loadings: [],
      m3: null,
      origWeek: null,
      ourRef: '',
      pod: null,
      product: null,
      qty: null,
      vessel: null,
      vgm: false,
      week: null,
      year: null,
    });

    const handleSubmitClick = () => {
        setCreateEditProject(null);
        refreshProjects();
    };

    const handleNextWeekClick = () => {
        let newPeriod = period;
        do {
            newPeriod = newPeriod.add(1, 'week');
        } while (getWeekRange(newPeriod).length < 3)

        setPeriod(newPeriod);
    }
    const handlePrevWeekClick = () => {
        let newPeriod = period;
        do {
            newPeriod = newPeriod.subtract(1, 'week');
        } while (getWeekRange(newPeriod).length < 3)

        setPeriod(newPeriod);
    }
    const handleWeekSelect = (period: dayjs.Dayjs) => setPeriod(period);

    const handleEditProjectClick = (project: ProjectWeekResponse) =>
        setCreateEditProject(project);

    const handleProjectUpClick = (id: string, steps: number) => {
        API.moveProjectUp(id, steps).then(() => refreshProjects());
    }

    const handleProjectDownClick = (id: string, steps: number) => {
        API.moveProjectDown(id, steps).then(() => refreshProjects());
    }

    return (
        <Visible permission={Permission.ReadProjects}>
            <Fragment>
                <Fragment>
                    <ProjectTable
                        clients={clients}
                        period={period}
                        projects={projects}
                        onSubmit={onSubmit}
                        onNextWeekClick={handleNextWeekClick}
                        onPrevWeekClick={handlePrevWeekClick}
                        onWeekSelect={handleWeekSelect}
                        onCreateProjectClick={handleCreateProject}
                        onEditProjectClick={handleEditProjectClick}
                        onProjectUpClick={handleProjectUpClick}
                        onProjectDownClick={handleProjectDownClick}
                        onCommentSubmit={refreshProjects}
                        isLoading={isLoading}
                        setLoading={setIsLoading}
                    />
                    <CreateEditProjectDialog
                        open={Boolean(createEditProject)}
                        onSubmit={onSubmit}
                        clients={clients}
                        project={createEditProject}
                        newOurRefValue={maxExistingRefNumber+1}
                        onClose={() => setCreateEditProject(null)}
                    />
                </Fragment>
            </Fragment>
        </Visible>
    );
}

import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#1565c0'
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      }
    }
  },
});

import React from 'react';
import {TextFieldProps as MuiTextFieldProps} from '@material-ui/core/TextField/TextField';
import {resolvePath} from "../../../utils/formUtils";
import {TextField} from "@material-ui/core";
import {Variable} from "../../../API/types";
import Autocomplete from "@material-ui/lab/Autocomplete";


export type VariableSelectProps = MuiTextFieldProps & {
  formik: any;
  name: string;
  label: string;
  variable: Variable;
};

export default function VariableSelect({ formik, name, label, variable }: VariableSelectProps) {
  return (
    <Autocomplete
      fullWidth
      clearOnEscape
      disablePortal
      autoComplete
      autoSelect
      selectOnFocus
      handleHomeEndKeys
      options={variable.options}
      onChange={(event: any, value: string | null) => formik.setFieldValue(name, value)}
      value={resolvePath(formik.values, name)}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
        />
      )}
    />
  );
}

import React, {useEffect, useState} from "react";
import {Variable} from "../../API/types";
import API from "../../API";
import LoadingSpinner from "../components/LoadingSpinner";
import {Box, makeStyles, Paper, IconButton, Tooltip, TextField, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import {AddCircleOutlineOutlined} from "@material-ui/icons";
import {Prompt} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(4)
  },
  variableAutocomplete: {
    maxWidth: '400px',
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .disabled': {
      color: 'black'
    }
  },
  optionContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    maxWidth: '600px',
    marginTop: '20px',
    height: '50px',
  },
  icon: {
    color: '#0000008a',
  },
  iconButton: {
    padding: '5px',
    marginLeft: theme.spacing(1),
  },
  iconButtonAdd: {
    marginTop: theme.spacing(4),
    width: 'fit-content',
    padding: '10px',
  },
  disabledTextField: {
    '& .Mui-disabled': {
      color: 'rgba(100, 100, 100, 0.5)'
    }
  }
}));


export default function Variables() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);
  const [variables, setVariables] = useState<Variable[]>([]);
  const [selectedVariable, setSelectedVariable] = useState<Variable>();
  const [currentEditableOption, setCurrentEditableOption] = useState<string | undefined>(undefined);
  const [updatedEditableOption, setUpdatedEditableOption] = useState<string | undefined>(undefined);
  const [newAddableOption, setNewAddableOption] = useState<string | undefined>();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);

  useEffect(() => {
    loadVariables();
  }, []);

  useEffect(() => {
    setShouldBlockNavigation(newOptionIsBeingAdded() || (optionIsBeingUpdated() && optionHasBeenChanged()));
  }, [selectedVariable, newAddableOption, updatedEditableOption, currentEditableOption])

  useEffect(() => {
    document.getElementById("edit-option")?.focus();
  }, [currentEditableOption]);

  useEffect(() => {
    document.getElementById("add-option")?.focus();
  }, [newAddableOption]);

  const orderVariables = (variablesForOrdering: Variable[]) => {
    return variablesForOrdering.sort((a, b) => a.name > b.name ? 1 : -1);
  }

  const handleUpdatedVariable = (updatedVariable: Variable) => {
    setVariables(variables => orderVariables([...variables.filter(variable => variable.id !== updatedVariable.id), updatedVariable]));
  };

  const loadVariables = () => {
    API.getVariables().then((response) => {
      if (response.isSuccessful) {
        setVariables(orderVariables(response.data));
      } else {
        const errorMessage = 'There has been a problem loading the variables. Please try again later.';
        enqueueSnackbar(errorMessage, {variant: 'error'});
      }
      setIsLoading(false);
    });
  };

  function newOptionIsBeingAdded() {
    return selectedVariable !== undefined && newAddableOption !== undefined && newAddableOption.length > 0;
  }

  const saveNewVariableOption = () => {
    if (newOptionIsBeingAdded()) {
      if (selectedVariable!!.options.includes(newAddableOption!!)) {
        enqueueSnackbar('Variable already exists', {variant: 'error'});
        return;
      }
      const updatedVariableList = [...selectedVariable!!.options, newAddableOption!!].sort();
      const updatedVariable = {id: selectedVariable!!.id, name: selectedVariable!!.name, options: updatedVariableList};

      API.editVariable(updatedVariable).then((response) => {
        if (response.isSuccessful) {
          handleUpdatedVariable(updatedVariable);
          setSelectedVariable(updatedVariable);
          setNewAddableOption(undefined);
        } else {
          const errorMessage = 'There has been a problem creating the new variable option. Please try again later.';
          enqueueSnackbar(errorMessage, {variant: 'error'});
        }
      });
    } else {
      enqueueSnackbar('Please insert a value', {variant: 'warning'});
    }
  }

  function optionIsBeingUpdated() {
    return updatedEditableOption !== undefined && selectedVariable !== undefined && updatedEditableOption.length > 0;
  }

  function optionHasBeenChanged() {
    return currentEditableOption !== updatedEditableOption;
  }

  const saveEditedVariableOption = () => {
    if (optionIsBeingUpdated()) {
      if (optionHasBeenChanged()) {
        const updatedVariableList = [...selectedVariable!!.options.filter(value => value !== currentEditableOption), updatedEditableOption!!].sort();
        const updatedVariable = {id: selectedVariable!!.id, name: selectedVariable!!.name, options: updatedVariableList};

        API.editVariable(updatedVariable).then((response) => {
          if (response.isSuccessful) {
            handleUpdatedVariable(updatedVariable);
            setSelectedVariable(updatedVariable);
            setCurrentEditableOption(undefined);
            setUpdatedEditableOption(undefined);
          } else {
            const errorMessage = 'There has been a problem updating the variable option. Please try again later.';
            enqueueSnackbar(errorMessage, {variant: 'error'});
          }
        });
      } else {
        setCurrentEditableOption(undefined);
        setUpdatedEditableOption(undefined);
      }
    } else {
      enqueueSnackbar('Please insert a value', {variant: 'warning'});
    }
  }

  const deleteVariableOption = (optionForDeleting: string) => {
    if(selectedVariable !== undefined && window.confirm('Are you sure you want to delete variable option "' + optionForDeleting + '"?')) {
      const updatedVariableList = selectedVariable.options.filter(option => option !== optionForDeleting).sort();
      const updatedVariable = {id: selectedVariable.id, name: selectedVariable.name, options: updatedVariableList};

      API.editVariable(updatedVariable).then((response) => {
        if (response.isSuccessful) {
          handleUpdatedVariable(updatedVariable);
          setSelectedVariable(updatedVariable);
        } else {
          const errorMessage = 'There has been a problem deleting the variable option. Please try again later.';
          enqueueSnackbar(errorMessage, {variant: 'error'});
        }
      });
    }
  }

  function renderAddOptionRow() {
    return (
      <Box className={classes.optionContainer}>
        <IconButton className={classes.iconButton} onClick={() => setNewAddableOption(undefined)}>
          <Tooltip placement="top" arrow title="Cancel adding new variable option">
            <ClearIcon className={classes.icon} />
          </Tooltip>
        </IconButton>
        <IconButton className={classes.iconButton} onClick={() => saveNewVariableOption()}>
          <Tooltip placement="top" arrow title="Save new variable option">
            <SaveIcon className={classes.icon} />
          </Tooltip>
        </IconButton>
        <TextField fullWidth type="text" id="add-option" name="value" placeholder="New option" onChange={
          (event) => setNewAddableOption(event.target.value)
        } />
      </Box>
    )
  }

  function renderEditOptionRow(option: string) {
    return (
        <Box className={classes.optionContainer} key={option}>
          {currentEditableOption !== option ?
            <>
              <IconButton className={classes.iconButton} onClick={() => {
                deleteVariableOption(option);
                setCurrentEditableOption(undefined);
                setUpdatedEditableOption(undefined);
              }}>
                <Tooltip placement="top" arrow title="Delete this variable option">
                  <DeleteIcon className={classes.icon} />
                </Tooltip>
              </IconButton>
              <IconButton className={classes.iconButton} onClick={() => {
                setCurrentEditableOption(option);
                setUpdatedEditableOption(option);
                setNewAddableOption(undefined);
              }}>
                <Tooltip placement="top" arrow title="Edit this variable option">
                  <EditIcon className={classes.icon} />
                </Tooltip>
              </IconButton>
            </>
            :
            <>
              <IconButton className={classes.iconButton} onClick={() => {
                setUpdatedEditableOption(undefined);
                setCurrentEditableOption(undefined);
              }}>
                <Tooltip placement="top" arrow title="Cancel editing this variable option">
                  <ClearIcon className={classes.icon} />
                </Tooltip>
              </IconButton>
              <IconButton className={classes.iconButton} onClick={() => saveEditedVariableOption()}>
                <Tooltip placement="top" arrow title="Save edited variable option">
                  <SaveIcon className={classes.icon} />
                </Tooltip>
              </IconButton>
            </>
          }
          { currentEditableOption !== undefined && currentEditableOption === option ?
            <TextField fullWidth type="text" id="edit-option" name="editedOption" value={updatedEditableOption} onChange={
              (event) => setUpdatedEditableOption(event.target.value)
            } />
            :
            <TextField className={classes.disabledTextField} fullWidth type="text" name="disabledEditedOption" disabled={true} value={option} />
          }
        </Box>
    )
  }

  return isLoading ? <LoadingSpinner /> : (
    <Paper className={classes.paper}>
      <Prompt
        when={shouldBlockNavigation}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <Typography variant="h5" className={classes.title}>Variables</Typography>
      <Autocomplete
        className={classes.variableAutocomplete}
        clearOnEscape
        autoComplete
        autoSelect
        handleHomeEndKeys
        options={variables.map((variable) => variable.name)}
        getOptionSelected={(option, value) => option === value}
        onChange={(event, value: string | null) => {
          const updateVariable = variables.find(variable => variable.name === value);
          setCurrentEditableOption(undefined);
          setUpdatedEditableOption(undefined);
          setNewAddableOption(undefined);
          if (updateVariable) {
            setSelectedVariable(updateVariable);
            return value;
          } else {
            setSelectedVariable(undefined);
            return null;
          }
        }}
        renderInput={(params) => <TextField name="variableType" label="Variable type" {...params} />}
      />
      { selectedVariable &&
        <>
          { selectedVariable.options.map(option => renderEditOptionRow(option)) }
          { newAddableOption === undefined ?
            <IconButton className={classes.iconButtonAdd} onClick={() => {
              setNewAddableOption('');
              setCurrentEditableOption(undefined);
              setUpdatedEditableOption(undefined);
            }}>
              <Tooltip placement="top" arrow title="Add new variable option">
                <AddCircleOutlineOutlined className={classes.icon} />
              </Tooltip>
            </IconButton>
            :
            renderAddOptionRow()
          }
        </>
      }
    </Paper>
  );
}
import {Permission, Role, User} from "../../API/types";
import React, {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";

type VisibleProps = {
    allPermissions: Permission[],
    anyPermissions?: never,
    permission?: never,
    role?: never,
    children: React.ReactNode
} | {
    allPermissions?: never,
    anyPermissions?: never,
    permission: Permission,
    role?: never,
    children: React.ReactNode
} | {
    allPermissions?: never,
    anyPermissions: Permission[],
    permission?: never,
    role?: never,
    children: React.ReactNode
} | {
    allPermissions?: never,
    anyPermissions?: never,
    permission?: never,
    role: Role
    children: React.ReactNode,
}

export const doesUserHavePermission = (permission: Permission | undefined, user: User): boolean | undefined => {
  return permission && user.permissions.includes(permission);
}

// eslint-disable-next-line react/display-name
const Visible = React.forwardRef<any, VisibleProps>(
    // eslint-disable-next-line no-undef
    ({permission, allPermissions, anyPermissions, role, children, ...rest}: VisibleProps, ref): null | JSX.Element => {
        const {user} = useContext(UserContext)

        const hasPermission = doesUserHavePermission(permission, user);
        const hasAllPermissions = allPermissions && allPermissions.every(p => user.permissions.includes(p));
        const hasAnyPermissions = anyPermissions && anyPermissions.some(p => user.permissions.includes(p))
        const hasRole = role && user.roles.includes(role)

        if (hasPermission || hasAllPermissions || hasAnyPermissions || hasRole) {
            return <React.Fragment>
                {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return (
                        <React.Fragment>
                            {React.cloneElement(child as React.ReactElement, {
                                ...rest,
                                ref: ref
                            })}
                        </React.Fragment>
                    );
                } else {
                    return <React.Fragment>{child}</React.Fragment>;
                }
            })}
            </React.Fragment>;
        } else {
            return null
        }
    });

export default Visible
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link, useHistory} from 'react-router-dom';
import routes from '../../routes';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import {AccountCircle} from '@material-ui/icons';
import {drawerStyles} from "../../../styles/drawerStyles";


export default function ProfileMenu(props: { handleLogoutClick: () => void }) {
  const classes = drawerStyles();
  const history = useHistory();

  return (
    <div>
      <Divider />
      <List>
        <ListItem button component={Link} to={routes.PROFILE} className={
          history.location.pathname === routes.PROFILE ? classes.listItemActive : classes.listItemInactive
        }>
          <ListItemIcon className={classes.listIcon}>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary="Profile"/>
        </ListItem>
        <ListItem button color="inherit" onClick={props.handleLogoutClick} className={classes.listItemInactive}>
          <ListItemIcon className={classes.listIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log out"/>
        </ListItem>
      </List>
    </div>
  )
}

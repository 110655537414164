import {ContactPerson} from "../../../API/types";
import {useTable, UseTableRowProps} from "react-table";
import {
  makeStyles,
  Table, TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const columns: {
  Header: string;
  Cell?: ({
            row,
          }: {
    row: UseTableRowProps<ContactPerson>;
  }) => string | number | null;
  accessor: keyof ContactPerson;
}[] = [
  {accessor: 'name', Header: 'Name'},
  {accessor: 'email', Header: 'Email'},
  {accessor: 'phoneNumber', Header: 'Phone number'}
];

const useStyles = makeStyles((theme) => ({
  cell: {
    paddingLeft: '2px',
    paddingRight: '2px',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  headerCell: {
    backgroundColor: 'white',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  striped: {
    backgroundColor: theme.palette.action.hover,
  }
}));
export default function PersonsTable(props: { persons: ContactPerson[] }) {
  const classes = useStyles();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } =
    useTable({
        columns,
        data: props.persons,
      },
    );

  return (
    <div>
      <TableContainer>
        <Table stickyHeader size="small" padding="none" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                <TableCell className={classes.headerCell} width={70}/>
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    className={clsx(classes.cell, classes.headerCell)}
                    {...column.getHeaderProps()}
                  >
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <Typography variant="body1">
                        {column.render('Header')}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.original.id}>
                  <TableRow
                    {...row.getRowProps()}
                    className={index % 2 === 0 ? classes.striped : undefined}
                  >
                    <TableCell></TableCell>
                    {row.cells.map((cell) => (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell
                        {...cell.getCellProps()}
                        className={classes.cell}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
import dayjs from 'dayjs';

export function getCurrentPeriod() {
    return dayjs().day(1);
}

export function getWeekString(weekNumber: number) {
    return `W${String(weekNumber).padStart(2, '0')}`
}

export function getWeekRange(period: dayjs.Dayjs) {
    const weeks = [];

    for (let i = -1; i <= 1; i++) {
        let referencePeriod = period;
        referencePeriod = referencePeriod.add(i, 'week');

        if (referencePeriod.year() === period.year()) {
            weeks.push(referencePeriod.isoWeek());
        }
    }

    return weeks;
}

export function getWeekRangeStrings(period: dayjs.Dayjs) {
    return getWeekRange(period).map(weekNumber => getWeekString(weekNumber))
}

export function getSelectableWeeks(currentPeriod: dayjs.Dayjs) {
    const options = [];
    let time = getCurrentPeriod().add(7 * 52, 'days');
    if (currentPeriod > time) time = currentPeriod;
    let minTime = dayjs().year(2019).isoWeek(4);
    if (currentPeriod < minTime) minTime = currentPeriod;

    while (time > minTime) {
        if (getWeekRange(time).length === 3) {
            options.push({
                'label': renderSelectedPeriod(time),
                'value': time.format('YYYY-MM-DD')
            })
        }
        time = time.subtract(7, 'day');
    }
    return options;
}

export function renderSelectedPeriod(selectedPeriod: dayjs.Dayjs) {
    const weeks = getWeekRange(selectedPeriod);
    const fromWeek = weeks.shift();
    const toWeek = weeks.pop();

    let period = `Week ${fromWeek?.toString().padStart(2, '0')}`;
    // @ts-ignore
    if (fromWeek !== toWeek && toWeek) {
        period += ` - ${toWeek?.toString().padStart(2, '0')}`;
    }

    period += ` ${selectedPeriod.year()}`;

    return period;
}

export function isWeekNumber(weekNumber: number, selectedPeriod: dayjs.Dayjs) {
    return weekNumber === selectedPeriod.isoWeek()
}
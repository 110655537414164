import {makeStyles} from "@material-ui/core";

export const useFormStyles = makeStyles((theme) => ({
  formBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: theme.spacing(3),
  },
  multipleInputFormRowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  formRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: '340px',
    maxWidth: '340px'
  },
  formRowWideContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(1),
  },
  formFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  formField: {
    width: '100%',
  },
  formFieldStatusContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: theme.spacing(1),
    paddingBottom: '5px',
    minWidth: '30px',
    maxWidth: '30px',
    height: '48px'
  },
  labelOverflow: {
    whiteSpace: 'nowrap',
  }
}));
